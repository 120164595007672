import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import PackageDisplay from "../../../components/Package";
import three_four1 from "../../..//img/JK/dallake-winter-view.jpg";
import three_four2 from "../../../img/JK/glmarg-winter-view.jpg";
import four_five from "../../../img/JK/pahalgam-view.jpg";
import four_five_over from "../../../img/JK/gulmarg-gondla.jpg";
import five_six from "../../../img/JK/kashmir-honeymoon1.jpg";
import six_seven from "../../../img/JK/tulip-sr.jpg";
import seven_eight from "../../../img/JK/sonamarg-img.jpg";
import eight_nine from "../../../img/JK/vaishnodevi-img.jpg";
import nine_ten from "../../../img/JK/kargil-img1.jpg";
import three_fourAmarth from "../../../img/JK/amarnathyatra.jpg";
import four_five_Amarth from "../../../img/JK/amarnathji-cave.jpg";
import three_four_Amarth_trek from "../../../img/JK/amarnathyatra-trek.jpg";
import Container from "react-bootstrap/esm/Container";
import "./index.css";
import NewCarousel from "../../../components/Carousel";
import TailourForm from "../../../components/TailourMadeForm";

export default class KashmirPackages extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="JK_Title">Jammu & Kashmir Holiday Packages</div>
        </Container>
        <Container className="mt-4 text-center">
          <Row>
            <PackageDisplay
              link="/jk/Tour_1"
              title="Srinagar Trip Tour (Day Trip)"
              image={three_four1}
              nights={3}
              day={4}
              destination={["Srinagar", "Sonamarg", "Gulmarg"]}
            />
            <PackageDisplay
              link="/jk/Tour_2"
              title="Gulmarg Winter Tour (Day Trip)"
              image={three_four2}
              nights={3}
              day={4}
              destination={["Srinagar", "Gulmarg", "Srinagar"]}
            />
            <PackageDisplay
              link="/jk/Tour_3"
              title="Kashmir Vacation Tour (Day Trip)"
              image={four_five}
              nights={4}
              day={5}
              destination={[
                "Srinagar",
                "Sonamarg",
                "Gulmarg",
                "Pahalgam",
                "Srinagar",
              ]}
            />

            <PackageDisplay
              link="/jk/Tour_4"
              title="Gulmarg Pahalgam Tour (Overnight)"
              image={four_five_over}
              nights={4}
              day={5}
              destination={["Srinagar", "Gulmarg", "Pahalgam", "Srinagar"]}
            />
            <PackageDisplay
              link="/jk/Tour_5"
              title="Kashmir Honeymoon Package (Overnight)"
              image={five_six}
              nights={5}
              day={6}
              destination={[
                "Srinagar",
                "Sonamarg",
                "Gulmarg",
                "Pahalgam",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_6"
              title="Kashmir Holiday Package (Overnight)"
              image={six_seven}
              nights={6}
              day={7}
              destination={[
                "Srinagar",
                "Doodhpathri",
                "Pahalgam",
                "Gulmarg",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_7"
              title="Discover Kashmir Himalaya Tour (Overnight)"
              image={seven_eight}
              nights={7}
              day={8}
              destination={[
                "Srinagar",
                "Sonamarg",
                "Yousmarg",
                "Doodhpathri",
                "Pahalgam",
                "Gulmarg",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_8"
              title="Mata Vaishno Devi with Kashmir Tours(Overnight)"
              image={eight_nine}
              nights={8}
              day={9}
              destination={[
                "Jammu",
                "Katra",
                "Pahalgam",
                "Srinagar",
                "Gulmarg",
                "Sonamarg",
                "Yousmarg",
                "Doodhpathri",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_9"
              title="Kashmir Ladakh Trip(Overnight)"
              image={nine_ten}
              nights={9}
              day={10}
              destination={[
                "Srinagar",
                "Gulmarg",
                "Srinagar",
                "Kargil",
                "Leh",
                "Khardungla",
                "Nubra",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Leh",
                "Kargil",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_10"
              title="Amarnath Yatra by Helicopter Via Neelgrath"
              image={three_fourAmarth}
              nights={3}
              day={4}
              destination={[
                "Srinagar",
                "Sonamarg",
                "Neelgrath",
                "Panchtarni Helipad",
                "Amarnath Cave - Back",
                "Sonamarg",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_11"
              title="Amarnath Yatra with Kashmir Tour"
              image={four_five_Amarth}
              nights={4}
              day={5}
              destination={[
                "Srinagar",
                "Sonamarg",
                "Neelgrath",
                "Panchtarni Helipad",
                "Amarnath Cave - Back",
                "Sonamarg",
                "Srinagar",
                "Gulmarg",
                "Srinagar",
              ]}
            />
            <PackageDisplay
              link="/jk/Tour_12"
              title="Amarnath Yatra by Trek"
              image={three_four_Amarth_trek}
              nights={3}
              day={4}
              destination={[
                "Srinagar",
                "Sonamarg",
                " Baltal to Amarnath Cave Trek",
                "Amarnath Darshan - Back ",
                "Sonamarg",
                "Srinagar",
              ]}
            />
          </Row>
          <Container className="mt-5">
            <TailourForm />
          </Container>
        </Container>
      </>
    );
  }
}
