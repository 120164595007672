import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import img3 from "../../../../img/gulmarg-gondla.jpg";
import img2 from "../../../../img/amarnathji-cave.jpg";
import img1 from "../../../../img/amarnath-yatra-trek4.jpg";

export default class Tour_11 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">
              Amarnath Yatra with Kashmir Tour
            </p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 4 Nights / 5 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Sonamarg
              - Neelgrath Helipad - Panchtarni Helipad - Amarnath Ji Darshan -
              Srinagar - Gulmarg - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 1 : Srinagar - Sonamarg (3- 4 Hrs)
                  </p>
                  <p className="PackageDescrip">
                    On arrival at Srinagar airport, our representative will meet
                    you and transfer you to Sonamarg 2690 Mtrs (Meadow of Gold).
                    <br />
                    On arrival check in Hotel Sonamarg. and overnight stay.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 2 : Sonamarg - Neelgrath Baltal (Maximum Time 20 Mint by
                    car) -Amarnath Yatra Darshan by Helicopter - Sonamarg -
                    Srinagar
                  </p>
                  <p className="PackageDescrip">
                    After breakfast drive to Baltal to begin your Amarnath Yatra
                    by helicopter from the Neelgrath Baltal huts which are just
                    10 minutes walk away from the Helipad. The helicopter
                    operates between 6:00 a.m. And 5:00 p.m. Helicopter Services
                    is subject to weather conditions. Helicopter operates till
                    Panchtarni then 6 kms.
                    <br />
                    Trek from Panchtarni to Amarnath cave. You can also take
                    Palki or Pony Or Natural walk. After Darshan Evening return
                    in time to Helipad to take back Helicopter to Neelgrath
                    Baltal. Pickup from helipad and drive to Srinagar, Evening
                    check in Hotel . Overnight stay in Hotel / Houseboat,
                    Srinagar.
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 03: Srinagar Local Sightseeing
                  </p>
                  <p className="PackageDescrip">
                    After breakfast full day Srinagar Local Sightseeing , Mughal
                    Garden - Shalimar Garden, Pari Mahal, Chasme Shahi & Shankra
                    Chariya temple. evening shikara ride on Dal Lake. After
                    sightseeing back to the Hotel and Overnight stay in Hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid className="text-center" />
                </Col>
              </Row>

              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 04 : Srinagar - Gulmarg - Srinagar (8700 Feet, 52 kms,
                    1.5 hrs One way)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast, drive to Gulmarg called as “Meadow of
                    Flowers” . Gulmarg is 52 Kms from Srinagar at an altitude of
                    8700-ft. Gulmarg also referred as Meadow of Flowers is a
                    famous Ski Resort & the world’s highest 18 hole Golf Course.
                    The place was prominent during the British time. You can
                    enjoy a Pony ride (Optional At your own cost).
                    <br />
                    Experience Gondola ride (up to first level only). Gulmarg
                    has one of the best Ski slope
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image
                    src={img3}
                    alt=""
                    fluid
                    style={{ marginTop: "15px" }}
                  />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 3----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 05 : Srinagar Airport for Onward Journey
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out from hotel and transferred to
                    Srinagar bus station Or Airport for your onward journey.
                  </p>
                </Col>
              </Row>

              <hr></hr>
              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage"> NOTE :-</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Accommodation & Heli ticket are subject to availability at
                    the time of reservation.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Rates subject to change without prior notice.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Cancellation & other policies as per the Hotel &
                    Helicopter's policy.
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - The above rates are net & non commissionable.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Gst 9.0% will be additional in Invoice.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Above package includes:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Srinagar Airport Pickup and Drop
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - 1 Night accommodation in Deluxe Hotel in Sonamarg with
                    breakfast & dinner on twin sharing basis.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - 2 Nights accommodation in Srinagar Hotel/ Houseboats with
                    breakfast & dinner on twin sharing basis.
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Neelgrath (near Baltal) Panchtarni to Neelgrath by
                    Helicopter.(Both way)
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Airport Transfer by car with English speaking driver.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - All present applicable taxes.{" "}
                  </p>
                </Col>
              </Row>
              {/* --------------------------------------------------Exclusion--------------- */}
              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Exclusions:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Any meals other than those specified.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Expenses of personal nature such as tips, telephone calls,
                    laundry, liquor etc.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Any other item not specified.
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Entrance fees during sightseeing
                  </p>
                </Col>
              </Row>
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
