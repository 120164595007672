import React, { Component } from "react";
import TourPackages from "../Homepage/components/TourPackages";
import CarousalContactUs from "./components/Carousal";
import MidSectionContact from "./components/MidSection";

export default class ContactPage extends Component {
  render() {
    return (
      <>
        <CarousalContactUs />
        <MidSectionContact />
        <TourPackages />
      </>
    );
  }
}
