import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionKerala from "../InclusionExclusionKerala";

import img1 from "../../../../img/South/munnar1.jpg";
import img2 from "../../../../img/South/kodaikanal.jpg";
import img3 from "../../../../img/South/munnar.jpg";
import CarouselKerala from "../KeralaCarousal";

export default class SouthIndia_Tour_5 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <PackageTitleDetails
            title="South India Hills Station Tour"
            nights={6}
            day={7}
            destination={[
              "Coimbatore",
              "Ooty Hill Sttion",
              "Kodaikanal Hill Station",
              "Munnar Hill Station",
              "Cochin",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 1: Coimbatore -  Ooty (100km-3hrs)"
                  text={
                    <>
                      On arrival at Coimbatore airport/railway station in the
                      morning. Meet our representative and proceed to Ooty. On
                      arrival, we'll check-in at the already booked hotel. Later
                      visit the tea museum along with the Ooty Lake. You may
                      enjoy boating on the Ooty Lake and later and revel in
                      dinner followed by relaxing stay.
                    </>
                  }
                  img={img1}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  title="Day 2: Ooty Sightseeing"
                  text={
                    <>
                      At the morning after breakfast, proceed for sightseeing of
                      Coonoor. To reach Coonoor, you can either opt for a toy
                      train or a personal car depending on your choice. On
                      reaching, we'll be visiting the Sim's playground, lamb's
                      stone and dolphin's nose. Later visit Botanical Garden,
                      Doddabetta Peak and Charing Cross. Later, return to the
                      hotel for a comfortable stay.
                    </>
                  }
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 3: Ooty - Kodaikanal (270km-7hrs)"
                  text={
                    <>
                      At the morning after breakfast, we'll check out from the
                      hotel we'll drive away to Kodaikanal, which is our final
                      place to go for the day. Enroute visit Silver cascade
                      waterfall. On reaching, check-in at the pre-booked hotel
                      and have dinner and overnight stay in the hotel in
                      Kodaikanal.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 4: Kodaikanal Sightseeing"
                  text={
                    <>
                      Today after breakfast in the hotel, we'll leave for a
                      local visit to Kodaikanal. Within our daily tour, we'll be
                      visiting a number of the significant attractions of
                      Kodaikanal. We'll begin our tour by visiting the famous
                      Pillar Rock along with the Coakers Walk. Afterwards, we'll
                      visit the Berijam Lake, Pine Forest, Bryant Park & Kodai
                      Lake and enjoy boat riding. Following that, we'll return
                      to the hotel for an overnight stay at Kodaikanal.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 5: Kodaikanal to Munnar (180Kms-5hrs)"
                  text={
                    <>
                      After good morning breakfast, Proceed to Munnar. This tiny
                      town in the Western Ghats is well-known for its photobook
                      towns, mist-clad rolling mountains, extensive tea gardens,
                      sanctuaries and above all the climate is among the most
                      generally desired traveller destination in India today. On
                      arrival at Munnar, Checkin to the hotel. Freshen up and
                      unwind with the comforts of your room. Walk around the
                      rest of the day with nature. Today your stay would be in
                      Munnar.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 6: Munnar sightseeing"
                  text={
                    <>
                      Wake up to the cool breeze of Munnar. Relish your morning
                      breakfast, visit the Eravikulam National Park (Closed from
                      20 Jan to 30 Mar) where you can see the well-famed Nilgiri
                      Thar. Later proceed to Mattupetty & Kundala Dam. Here you
                      can enjoy boating through the reservoir flanked with tea
                      garden on one side and never-ending Grassland on the
                      other. The Echo Point, renowned for its echo phenomenon is
                      a popular stopping point for the tourists. Also, visit
                      Shooting Point and Tea Museum. Today your stay would be in
                      Munnar.
                    </>
                  }
                  img={img3}
                />
              </Row>
              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 7: Munnar - Cochin Departure (40km-1hrs)"
                  text={
                    <>
                      Enjoy breakfast. Today your South India Honeymoon Holidays
                      ends. Later pack your baggage with a promise to revisit
                      once more to this part of the world. Proceed on time to
                      Cochin Airport to board the flight home. Bon Voyage.
                      Service ends.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionKerala />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
