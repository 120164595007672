import React, { Component } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import AboutUS from "../src/screens/Aboutus";
import HomePage from "./screens/Homepage";
import AdventureTour from "../src/screens/AdventureTours";
import logo from "./img/logo.png";
import { Route, BrowserRouter as Router, Routes, Link } from "react-router-dom";
import { withRouter } from "react-router";
import "./App.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import awardImage from "./img/travel-logo.jpg";
import Image from "react-bootstrap/Image";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import CarRentals from "./screens/CarRentals";
import HouseBoat from "./screens/Houseboats";
import ContactPage from "./screens/Contact";
// ------------------------------------------------------------------Srinagar----------------------------------------------------
import KashmirPackages from "./screens/Packages/Jammu&Kashmir";
import Tour_1 from "./screens/Packages/Jammu&Kashmir/SrinagarTripTour";
import Tour_2 from "./screens/Packages/Jammu&Kashmir/GulmargWinterTour";
import Tour_3 from "./screens/Packages/Jammu&Kashmir/KashmirVacationTour";
import Tour_4 from "./screens/Packages/Jammu&Kashmir/GulmargPahalgamTourOvernight";
import Tour_5 from "./screens/Packages/Jammu&Kashmir/HoneymoonPackage";
import Tour_6 from "./screens/Packages/Jammu&Kashmir/KashmirHolidaysPackage(OverNight)";
import Tour_7 from "./screens/Packages/Jammu&Kashmir/DiscoverKashmirHimalayaTour";
import Tour_8 from "./screens/Packages/Jammu&Kashmir/MataVaishnoDeviwithKashmirTour";
import Tour_9 from "./screens/Packages/Jammu&Kashmir/KashmirLadakhTrip(Overnight)";
import Tour_10 from "./screens/Packages/Jammu&Kashmir/AmarnathYatrabyHelicopterViaNeelgrath";
import Tour_11 from "./screens/Packages/Jammu&Kashmir/AmarnathYatraWithKashmirTour";
import Tour_12 from "./screens/Packages/Jammu&Kashmir/AmarnathYatrabyTrek";

// --------------------------------------------------------Ladakh -------------------------------------------------------
import LadakhHolidays from "./screens/Packages/Ladakh";
import Ladakh_Tour_1 from "./screens/Packages/Ladakh/Ladakh ShyamValleyWithPangongLake";
import Ladakh_Tour_2 from "./screens/Packages/Ladakh/NubraValleyWithPangongLakeTour";
import Ladakh_Tour_3 from "./screens/Packages/Ladakh/LamayuruWithNubra&PangongTour";
import Ladakh_Tour_4 from "./screens/Packages/Ladakh/NubraTurtukWithPangongLakeTour";
import Ladakh_Tour_5 from "./screens/Packages/Ladakh/LehKargilWithNubraTurtuk&PangongLake";
import Ladakh_Tour_6 from "./screens/Packages/Ladakh/EnchantingLadakhHimalayanTour";
import Ladakh_Tour_7 from "./screens/Packages/Ladakh/LadakhTransHimalayanTour";
import Ladakh_Tour_8 from "./screens/Packages/Ladakh/DiscoverLadakhHimalayanTour&ManaliDrop";
import Ladakh_Tour_9 from "./screens/Packages/Ladakh/KashmirLadakhTrip(Overnight)";

// --------------------------------------------------------------------------------------------
import HimachalHolidays from "./screens/Packages/Himachal";
import Himachal_Tour_1 from "./screens/Packages/Himachal/DelhiManaliByLuxuryBusTour";
import Himachal_Tour_2 from "./screens/Packages/Himachal/ShimlaManaliByCarTour";
import Himachal_Tour_3 from "./screens/Packages/Himachal/ShimlaManaliDharamshalaWithGoldenTempleTour";
import Himachal_Tour_4 from "./screens/Packages/Himachal/ManalitoLadakhBikeTrip";
import Himachal_Tour_5 from "./screens/Packages/Himachal/Discover Ladakh HimalayanTour&ManaliDrop";
import Himachal_Tour_6 from "./screens/Packages/Himachal/KashmirLadakhManaliOverlandTour";
// ----------------------------------------------------------------------------------------------
import RajasthanHolidays from "./screens/Packages/Rajasthan";
import Rajasthan_Tour_1 from "./screens/Packages/Rajasthan/GoldenTriangleTour";
import Rajasthan_Tour_2 from "./screens/Packages/Rajasthan/GoldenTriangleWithPushkarTour";
import Rajasthan_Tour_3 from "./screens/Packages/Rajasthan/GoldenTriangleWithRanthambore&PushkarTour";
import Rajasthan_Tour_4 from "./screens/Packages/Rajasthan/GoldenTriangleWithKashmirTour";
import Rajasthan_Tour_5 from "./screens/Packages/Rajasthan/RajasthanHeritageTour";
import Rajasthan_Tour_6 from "./screens/Packages/Rajasthan/RoyalRajputanaRajasthanTour";

// -----------------------------------------------------------------------------------------------------------

import SouthIndiaHolidays from "./screens/Packages/Kerala";
import SouthIndia_Tour_1 from "./screens/Packages/Kerala/MunnarWithAlleppeyHouseboat";
import SouthIndia_Tour_2 from "./screens/Packages/Kerala/BestOfKeralaHolidayTour";
import SouthIndia_Tour_3 from "./screens/Packages/Kerala/AndamanHoneymoonPackage";
import SouthIndia_Tour_4 from "./screens/Packages/Kerala/KeralaHoneymoonPackage";
import SouthIndia_Tour_5 from "./screens/Packages/Kerala/SouthIndiaHillsStationTour";
import SouthIndia_Tour_6 from "./screens/Packages/Kerala/SouthIndiaTempleTour";
import HajUmrahTicketBooking from "./screens/HajUmrahBooking";
class App extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Router>
        <div className="App">
          <>
            <div className="d-flex container-wrapper">
              <div className="Contacts ml-auto">
                <span className="call-us-text">Call us :</span>
                <a className="call1" href="tel:+91 6005146451">
                  +91 6005146451
                </a>
                <span className="divider">|</span>
                <a className="call2" href="tel: +6597610109">
                  +6597610109
                </a>
                <span className="divider">|</span>
                <a className="call1" href="tel: +91 1943575947">
                  +91 1943575947
                </a>
              </div>
            </div>
            <Navbar
              bg="dark"
              variant="dark"
              expand="lg"
              className="menu-wrapper"
            >
              <Container>
                <Navbar.Brand as={Link} to="/">
                  <img className="logo" src={logo} alt="logo"></img>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link as={Link} to="/" className="nav-items">
                      Home
                    </Nav.Link>

                    <Nav.Link as={Link} to="/about" className="nav-items">
                      About Us
                    </Nav.Link>

                    <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/jk">
                        Jammu & Kashmir
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/leh_ladakh">
                        Ladakh
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/himachal">
                        Himachal
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/rajasthan">
                        Rajasthan
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/southIndia">
                        Kerala South India
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link as={Link} to="/adventure" className="nav-items">
                      Adventure Tours
                    </Nav.Link>

                    <Nav.Link as={Link} to="/houseboat" className="nav-items">
                      Houseboats
                    </Nav.Link>

                    <Nav.Link as={Link} to="/carRentals" className="nav-items">
                      Car Rentals
                    </Nav.Link>

                    <Nav.Link as={Link} to="/contact" className="nav-items">
                      Contact
                    </Nav.Link>

                    <Nav.Link as={Link} to="/tourEnquiry" className="nav-items">
                      Tour Enquiry
                    </Nav.Link>
                    <Nav.Link as={Link} to="/haj_umrah" className="nav-items">
                      Haj Umrah Booking
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>

            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/about" element={<AboutUS />} />
              <Route exact path="/adventure" element={<AdventureTour />} />
              <Route exact path="/carRentals" element={<CarRentals />} />
              <Route exact path="/contact" element={<ContactPage />} />
              <Route exact path="/houseboat" element={<HouseBoat />} />
              <Route exact path="/tourEnquiry" element={<ContactPage />} />
              <Route exact path="/jk" element={<KashmirPackages />} />
              <Route exact path="/leh_ladakh" element={<LadakhHolidays />} />
              <Route exact path="/himachal" element={<HimachalHolidays />} />
              <Route exact path="/rajasthan" element={<RajasthanHolidays />} />
              <Route
                exact
                path="/haj_umrah"
                element={<HajUmrahTicketBooking />}
              />
              <Route
                exact
                path="/southIndia"
                element={<SouthIndiaHolidays />}
              />

              {/* ----------------------------------------------Tour Packages Kashmir----------------------- */}
              <Route exact path="/jk/Tour_1" element={<Tour_1 />} />
              <Route exact path="/jk/Tour_2" element={<Tour_2 />} />
              <Route exact path="/jk/Tour_3" element={<Tour_3 />} />
              <Route exact path="/jk/Tour_4" element={<Tour_4 />} />
              <Route exact path="/jk/Tour_5" element={<Tour_5 />} />
              <Route exact path="/jk/Tour_6" element={<Tour_6 />} />
              <Route exact path="/jk/Tour_7" element={<Tour_7 />} />
              <Route exact path="/jk/Tour_8" element={<Tour_8 />} />
              <Route exact path="/jk/Tour_9" element={<Tour_9 />} />
              <Route exact path="/jk/Tour_10" element={<Tour_10 />} />
              <Route exact path="/jk/Tour_11" element={<Tour_11 />} />
              <Route exact path="/jk/Tour_12" element={<Tour_12 />} />
              {/* ----------------------------------------------Laddakh Packages----------------------- */}
              <Route
                exact
                path="/leh_ladakh/Tour_1"
                element={<Ladakh_Tour_1 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_2"
                element={<Ladakh_Tour_2 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_3"
                element={<Ladakh_Tour_3 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_4"
                element={<Ladakh_Tour_4 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_5"
                element={<Ladakh_Tour_5 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_6"
                element={<Ladakh_Tour_6 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_7"
                element={<Ladakh_Tour_7 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_8"
                element={<Ladakh_Tour_8 />}
              />
              <Route
                exact
                path="/leh_ladakh/Tour_9"
                element={<Ladakh_Tour_9 />}
              />
              {/* ----------------------------------------------Himachal Package----------------------- */}
              <Route
                exact
                path="/himachal/Tour_1"
                element={<Himachal_Tour_1 />}
              />

              <Route
                exact
                path="/himachal/Tour_2"
                element={<Himachal_Tour_2 />}
              />
              <Route
                exact
                path="/himachal/Tour_3"
                element={<Himachal_Tour_3 />}
              />
              <Route
                exact
                path="/himachal/Tour_4"
                element={<Himachal_Tour_4 />}
              />
              <Route
                exact
                path="/himachal/Tour_5"
                element={<Himachal_Tour_5 />}
              />
              <Route
                exact
                path="/himachal/Tour_6"
                element={<Himachal_Tour_6 />}
              />
              {/* --- --------------------------------------------Rajasthan----------------------*/}
              <Route
                exact
                path="/rajasthan/Tour_1"
                element={<Rajasthan_Tour_1 />}
              />
              <Route
                exact
                path="/rajasthan/Tour_2"
                element={<Rajasthan_Tour_2 />}
              />
              <Route
                exact
                path="/rajasthan/Tour_3"
                element={<Rajasthan_Tour_3 />}
              />
              <Route
                exact
                path="/rajasthan/Tour_4"
                element={<Rajasthan_Tour_4 />}
              />
              <Route
                exact
                path="/rajasthan/Tour_5"
                element={<Rajasthan_Tour_5 />}
              />
              <Route
                exact
                path="/rajasthan/Tour_6"
                element={<Rajasthan_Tour_6 />}
              />
              {/* ----------------------------------South India----------------------------------------------- */}
              <Route
                exact
                path="/southIndia/Tour_1"
                element={<SouthIndia_Tour_1 />}
              />
              <Route
                exact
                path="/southIndia/Tour_2"
                element={<SouthIndia_Tour_2 />}
              />
              <Route
                exact
                path="/southIndia/Tour_3"
                element={<SouthIndia_Tour_3 />}
              />
              <Route
                exact
                path="/southIndia/Tour_4"
                element={<SouthIndia_Tour_4 />}
              />
              <Route
                exact
                path="/southIndia/Tour_5"
                element={<SouthIndia_Tour_5 />}
              />
              <Route
                exact
                path="/southIndia/Tour_6"
                element={<SouthIndia_Tour_6 />}
              />
            </Routes>
            <div className="contact-us-wrapper text-center">
              <Row>
                <Col lg={4} md={12}>
                  <div style={{ padding: "20px" }}>
                    <span className="heading-left">Love</span>
                    <span className="heading-right">Kashmir Holidays</span>
                    <p className="footer-text">
                      "Love Kashmir Holidays”, the best travel agents in
                      Kashmir, is taking care of your travel needs under one
                      roof making the tour of our guests more secure,
                      attractive, affordable and fascinating, Travel with Love
                      Kashmir Holidays is Local Travel Agents in Srinagar,
                      Kashmir.
                    </p>
                    <FontAwesomeIcon
                      onClick={() =>
                        (window.location.href =
                          "https://www.facebook.com/profile.php?id=100063819612811")
                      }
                      className="icons1"
                      icon={faFacebook}
                      style={{ height: "35px", margin: "5px" }}
                    />
                    <FontAwesomeIcon
                      onClick={() =>
                        (window.location.href =
                          "https://instagram.com/lovekashmirtourandtravels?igshid=YmMyMTA2M2Y=")
                      }
                      className="icons2"
                      icon={faInstagram}
                      style={{ height: "35px", margin: "5px" }}
                    />
                    <FontAwesomeIcon
                      onClick={() =>
                        (window.location.href = "https://wa.me/+916005146451")
                      }
                      className="icons3"
                      icon={faWhatsapp}
                      style={{ height: "35px", margin: "5px" }}
                    />
                  </div>
                </Col>
                <Col lg={4} md={12} className="footer-address">
                  <div className="head-office">HEAD OFFICE ADDRESS</div>
                  <p className="office-Address">
                    Friends Colony, Tailbal, Shalimar, Srinagar,Kashmir, 190006
                    , J & K
                  </p>
                  <p className="contact">
                    <span className="color-contact">Tel:</span>
                    <a className="call1" href="tel:+91 6005146451">
                      +91 6005146451
                    </a>
                    <span className="divider">|</span>
                    <a className="call2" href="tel: +6597610109">
                      +6597610109
                    </a>
                    <span className="divider">|</span>
                    <a className="call1" href="tel: +91 1943575947">
                      +91 1943575947
                    </a>
                  </p>
                  <p className="contact">
                    <label className="color-contact">Email: </label>{" "}
                    <a href="mailto:javidbhat.kashmir@gmail.com">
                      javidbhat.kashmir@gmail.com
                    </a>{" "}
                    ,
                    <a href="mailto:javidbhat@lovekashmirholidays.com">
                      {" "}
                      javidbhat@lovekashmirholidays.com
                    </a>
                  </p>
                  <p className="contact">
                    <span className="color-contact">Working Hours:</span>{" "}
                    9am-9pm
                  </p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="last-section">
                    BEST TRAVEL AGENTS IN KASHMIR
                  </div>
                  <Image src={awardImage} alt="" fluid />
                </Col>
              </Row>
            </div>
          </>
        </div>
      </Router>
    );
  }
}
export default App;
