import React from "react";
import "./index.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function PackageDisplay(props) {
  const navigate = useNavigate();
  return (
    <>
      <Col lg={4} md={6} className="mt-4">
        <Card className="card-style">
          <Card.Img variant="top" src={props.image} />
          <Card.Body>
            <Card.Title className="package-title">{props.title}</Card.Title>
            <Card.Text className="package-description">
              Duration : {props.nights} Nights / {props.day} Days Trip
            </Card.Text>
            <Card.Text className="package-description">
              Destination : {props.destination.map((el) => " - " + el)}
            </Card.Text>
            <Button
              variant="danger"
              onClick={() => navigate(`${props.link}`)}
              className="mx-2"
            >
              Book Now
            </Button>{" "}
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => navigate(`${props.link}`)}
            >
              View Details
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
