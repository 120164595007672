import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionHimachal";

import img1 from "../../../../img/himachal/shimla3.jpg";
import img2 from "../../../../img/himachal/solangvalley.jpg";
import img3 from "../../../../img/himachal/rohtang-pass-manali.jpg";
import img4 from "../../../../img/himachal/golden-temple-tour.jpg";
import CarousalHimachal from "../CarousalHimachal";

export default class Himachal_Tour_3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalHimachal />
        <Container>
          <PackageTitleDetails
            title="Shimla Manali Dharamshala With Golden Temple Tour"
            nights={7}
            day={8}
            destination={[
              "Delhi",
              "Shimla",
              "Manali",
              "Dharamsala",
              "Amritsar",
              "Delhi",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Delhi - Shimla (365 KM)"
                  text={
                    <>
                      Arrive Delhi airport train station and drive to Shimla.
                      One of the most popular hill Resorts of the North. The
                      town is situated at an altitude of 6810 ft, initially
                      chosen as a sanatorium for invalid European officers, but
                      soon developed into a big hill town and became the summer
                      capital of the country. Upon arrival check in to the hotel
                      for Overnight stay.
                    </>
                  }
                  img={img1}
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 02: Shimla"
                  text={
                    <>
                      As you finish your breakfast you go ahead and take a tour
                      to Shimla exploring the eminent tourist spots. The popular
                      ones to touch upon are Mall Road, Jakhoo Hill, Chadwick
                      Falls, Himachal State Museum, Summer Hill and Prospect
                      Hill. In the afternoon you can make your way to Kufri
                      Valley for an exciting excursion. Return to your hotel for
                      dinner and night stay.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03: Shimla - Manali (270 KM)"
                  text={
                    <>
                      After having breakfast proceed for Manali. Manali is an
                      important hill station of northern India and is the
                      destination of thousands of tourists every year. Its cool
                      atmosphere provides a perfect haven for the ones afflicted
                      by the hot Indian summers. Besides offering quite a few
                      places for sightseeing, Manali is also famous for
                      adventure sports like skiing, hiking, mountaineering,
                      paragliding, rafting, trekking, kayaking, and mountain
                      biking. En- route visit Hanogi Mata Temple. Overnight stay
                      at hotel in Manali.
                    </>
                  }
                  img={img2}
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 04 : Manali local sightseeing"
                  text={
                    <>
                      Arrival at Manali bus station, meet the driver & transfer
                      to hotel. Check-in at the hotel. Later carry out the local
                      sightseeing tour in Manali to cover a lot of beautiful
                      places like the Manu Temple, Hadimba Devi Temple, the
                      Vashisht Kund in the Vashisht village followed by some
                      shopping and strolling in the evening. Return back to the
                      hotel and wind up the day by an overnight stay.
                    </>
                  }
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05 : Manali to Rohtang Pass or Solang Valley"
                  text={
                    <>
                      Early morning breakfast and get ready to hit the best
                      excursions from Manali on a drive. Post reaching the Snow
                      View Point or Rohtang Pass, you will experience the
                      several adventure sports and also the beautiful natural
                      surroundings followed by the Solang Valley that has the
                      ropeway ride awaiting. Post this, you will return to the
                      hotel and stay overnight.
                    </>
                  }
                  img={img3}
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 06 : Manali to Amritsar (480 Kms, 12/14 Hours)"
                  text={
                    <>
                      Early morning check out from hotel and transfer by surface
                      to Amritsar. On arrival check in at hotel. Overnight at
                      hotel.
                    </>
                  }
                />
              </Row>
              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 07 : Amritsar Wagah Border Tour and local sightseeing"
                  text={
                    <>
                      After Breakfast visit the Golden Temple and later visit
                      the massacre of Jalian Wallan Bagh. Afternoon little free
                      time to rest and evening around 3 PM drive to Wagah Border
                      to see the Closing Ceremony, event starts exactly by 5.30
                      PM everyday. Border closing 'lowering of the flags'
                      ceremony is a daily military practice that the security
                      forces of India and Pakistan.
                    </>
                  }
                  img={img4}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      This ceremony takes place every evening before sunset at
                      the Wagah border, which as part of the Grand Trunk Road
                      was the only road link between these two countries before
                      the opening of the Aman Setu in Kashmir in 1999. The
                      ceremony starts with a blustering parade by the soldiers
                      from both the sides and ends up in the perfectly
                      coordinated lowering of the two nations flags, it is
                      called the beating retreat border ceremony on the
                      international level.
                      <br />
                      <br />
                      One infantryman (Jawan) stands at attention on each side
                      of the gate. As the sun sets, the iron gate at the border
                      is opened and the flags are lowered. The flags are folded
                      and the ceremony ends with a retreat that involves a
                      brusque handshake between soldiers from either side.
                      Return back to Hotel and Overnight.
                    </>
                  }
                />
              </Row>

              <Row>
                <PackageOnlyText
                  title="Day 08 : Amritsar to Delhi (450 Kms, 10 Hours)"
                  text={
                    <>
                      Early morning drive back to Delhi. Evening drop to the New
                      Delhi Railway Station / Airport/ or your Destination.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
