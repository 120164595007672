import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionRajasthan from "../InclsionExclusionRajasthan";

import img1 from "../../../../img/Rajasthan/redfort.jpg";
import img2 from "../../../../img/Rajasthan/tajmahal.jpg";
import img3 from "../../../../img/Rajasthan/jalmahal-japur.jpg";
import img4 from "../../../../img/Rajasthan/dallake-view.jpg";
import img5 from "../../../../img/Srinagar1/gulmarg-gondola.jpg";
import img6 from "../../../../img/pahalgam-view.jpg";
import CarousalRajasthan from "../CarousalRajasthan";

export default class Rajasthan_Tour_4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalRajasthan />
        <Container>
          <PackageTitleDetails
            title="Golden Triangle with Kashmir Tour"
            nights={11}
            day={12}
            destination={[
              "Delhi",
              "Agra",
              "Jaipur",
              "Delhi",
              "Srinagar (by Flight)",
              "Sonamarg",
              "Gulmarg",
              "Pahalgam",
              "Srinagar",
              "Delhi",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 01 : Arrive Delhi"
                  text={
                    <>
                      On arrival at Delhi airport or railway station, our
                      representative will meet you to pick you up and transfer
                      to your hotel. Rest of the day is at your leisure. Stay
                      overnight at the hotel in Delhi
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 02 : Delhi City Tour"
                  text={
                    <>
                      After breakfast first you will visit old Delhi. The Raj
                      Ghat, Red fort & Jama Masjid are important places to
                      visit, you may also visit Chandni Chowk, spice market etc.
                      Later on proceed to visit the New Delhi. Visit Qutub
                      Minar,a UNESCO World Heritage Site. Qutub Minar is an
                      excellent example of Afghan Architecture constructed with
                      marble and red sandstone. And then stop at UNESCO World
                      Heritage-listed Humayuns Tomb, After imposing India Gate
                      memorial built in the year 1931 to commemorate the Indian
                      soldiers. and the Rastrapathi Bhawan, Laxmi Narayan
                      Temple, Lotus Temple and, newly built Akshardham temple
                      are of much significance. By evening return to your hotel
                      for overnight stay.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03 : Delhi to Agra"
                  text={
                    <>
                      This morning after breakfast you will drive to Mughal city
                      Agra and check into your hotel. Enroot you may also visit
                      Mathura-the birth place of Lord Krishana. This afternoon
                      visit the Agra fort, built by the great Emperor Akbar, and
                      the famous Taj Mahal-the symbol of love, built by Emperor
                      Shah Jahan in memory of his wife Mumtaz Mahal. You may
                      also visit Dayal Bagh. By evening return to your hotel for
                      overnight stay.
                    </>
                  }
                  img={img2}
                />
              </Row>
              {/* -------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 04 : Agra to Jaipur"
                  text={
                    <>
                      This morning after breakfast you will drive to the pink
                      city of Jaipur, enroute you will visit the Fatehpur Sikri,
                      the deserted red sandstone city built by the Mughal
                      Emperor Akbar. On arrival check into your hotel and rest
                      of the day is at leisure. Stay overnight at hotel in
                      Jaipur.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05 : Jaipur City Tour"
                  text={
                    <>
                      This morning after breakfast visit the Amber fort, built
                      in 16th century, enjoy an elephant ride at Amber. Drive
                      past the Hawa Mahal-a Palace of winds. In the afternoon
                      you will visit the city palace and museum, walk to
                      adjacent Jantar Mantar an astronomical observatory. Rest
                      of the evening is at leisure. Stay overnight at hotel in
                      Jaipur.
                    </>
                  }
                  img={img3}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  title="Day 06 : Jaipur to Delhi"
                  text={
                    <>
                      After Breakfast check out Hotel and drive back to Delhi
                      Evening check in Hotel and Overnight stay.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 07 : Delhi - Srinagar arrival
                  "
                  text={
                    <>
                      After Brekfast check out hotel and drop to the Delhi
                      Domestic Airport and Take Flight for Srinagar.
                      <br />
                      Our services will start with your arrival at Srinagar
                      airport, you will be met by our representative and
                      transfer to the Houseboats, check in Houseboats, after
                      freshup visit to Mughal Gardens consists of mainly of
                      three gardens -Chashme shahi garden -Nishat Garden
                      -Shalimar Garden .Shankaracharya Temple Takht-e-Suleiman
                      hill, the Shankaracharya Temple is dedicated to Lord
                      Shiva.
                    </>
                  }
                  img={img4}
                />
              </Row>
              <PackageOnlyText
                text={
                  <>
                    It is supposed to be the oldest shrine of the Kashmir
                    valley, Hazratbal Shrine- Situated opposite the Nishat Bagh
                    on the banks of Dal Lake,Parimahal. In the afternoon Shikara
                    ride on The Dal Lake to enjoy the beauty that God has graced
                    this city, after sightseeing check in Houseboats and
                    Overnight stay.
                  </>
                }
              />
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 08 : Srinagar - Sonamarg – Srinagar (Approx 2 hrs 30 mint)"
                  text={
                    <>
                      After breakfast leave for Sonamarg situated at an altitude
                      of 9000 Ft. Sonamarg – 84 Kms is also called as “The
                      meadow of gold” has, as its backdrop, snowy mountains
                      against a cerulean sky. The Sindh River that meanders
                      through the valley abounds with trout and mahaseer. Ponies
                      can be hired for the trip up to Thajiwas glacier, which is
                      a major local attraction during the summer. Evening back
                      to Srinagar and Overnight stay Houseboats.
                    </>
                  }
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 09 : Srinagar- Gulmarg (Approx 2 Hours Drive- One Way)
                  "
                  text={
                    <>
                      After breakfast at the hotel, leave for a day excursion to
                      Gulmarg. The drive past colorful villages and rice fields
                      gives you an insight of the rich cultural past of Kashmir.
                      Gulmarg is one of the most beautiful hill stations in the
                      world and also called Meadows of Flowers.
                    </>
                  }
                  img={img5}
                />
              </Row>
              <PackageOnlyText
                text={
                  <>
                    It has the world’s highest golf course (world’s highest),
                    and Gulmarg is also famous for winter sports like skiing
                    etc. But the most popular thing to include in the tour
                    package in Kashmir is the Gondola Cable Car Ride to
                    Khilanmarg. The 08 minutes of ropeway will take you past
                    colorful shepherd hutments to the lower ridge of the
                    Apharwat peak. From here, you can click lots of photos of
                    the nearby mountain ranges that stand tall like sentinels
                    guarding the rich natural beauty of Kashmir valley. After
                    sightseeing check in Hotel and Overnight stay in Gulmarg.
                  </>
                }
              />
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 10 : Gulmarg - Pahalgam (Approx 5 Hours Drive- One Way)"
                  text={
                    <>
                      After breakfast check out Hotel and drive to Pahalgam.
                      Valley of Shepherds. Enroute you will have an rare
                      opportunity to visit the Saffron fields. Also visit
                      Avantipura Ruins & Anantnag Sulpher Springs enroute,
                      Arrive and proceed to your hotel. Visit to Chandanwadi,
                      enjoy leisurely walks through the pine forest along the
                      River Lidder (at your own cost), The snow Point (14 Km) at
                      your own cost or enjoy pony ride at your own cost . After
                      Sightseeig check in Hotel and Overnight stay in Hotel.
                      <br />
                      Note : In Pahalgam outside vehicles are not allowed for
                      sightseeing. Only local taxis can roam around within
                      Pahalgam city. All outside vehicles will pick up & drop
                      from Hotel
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 11 : Pahalgam - Srinagar (Approx 3 Hours Drive- One Way)"
                  text={
                    <>
                      After Breakfast check out hotel and drive back to
                      Srinagar, Enroute visit Saffron fields, Awantipur ruins
                      (1100 yrs old temple of Lord Vishnu built by King
                      Awantiverma), see the beautiful countryside, lots of rice
                      fields. Arrive at Srinagar and check in Hotel, and half
                      day srinagar Local Market and sightseeing, and evening
                      drop to the hotel overnight stay.
                    </>
                  }
                  img={img6}
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 12 : Srinagar Departure"
                  text={
                    <>
                      After breakfast transfer to Srinagar airport to board
                      flight for onwards destination with sweet memories of the
                      trip.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <hr></hr>

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Above package includes:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Pickup & Drop from Delhi Airport / Railway Station
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - All Stay with 3 Star Hotel.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Double Sharing Rooms with Breakfast & Dinner.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Veg or Non Veg Food.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    -Manali in Sharing Car{" "}
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - All present applicable taxes.{" "}
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - One Hrs Shika Ride on Dal Lake{" "}
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - One Day Deluxe Houseboats.{" "}
                  </p>
                </Col>
              </Row>

              {/* --------------------------------------------------Exclusion--------------- */}

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Exclusions:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Meal Plan (Lunch)
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Horse Ride, Bike Ride, Camel Ride
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Monument Fee
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Personal Expenses like Telephone, Fax, Internet, Laundry
                    etc
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Any Expenses related to portage at hotels, airports, tips,
                    room service, insurance, wine, boating charge, guide,
                    entrance fee, porters, palanquins, or ponies{" "}
                  </p>
                </Col>
              </Row>

              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
