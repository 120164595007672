import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";

import lgoo from "../../../../img/travel-logo.jpg";

import "./index.css";
import FormBooking from "../../../../components/Form";
export default class MidSectionContact extends Component {
  render() {
    return (
      <>
        <Container className="mt-5">
          <Row>
            <Col lg={6}>
              <div className="wrapper-green">
                <p className="heading-contact-us">LOVE KASHMIR HOLIDAYS</p>
                <p className="heading-contact-us-sub">
                  (Recognized by Jammu and Kashmir, Department of Tourism)
                </p>
                <div className="addres-container">
                  <p className="office-address">Head Office Address:</p>
                  <p className="text-contact ">
                    Friends Colony, Taibal, Shalimar, Srinagar, Kashmir -
                    190006, J & K (India)
                  </p>
                  <p className="text-contact ">
                    <span className="office-address">Tel:</span>{" "}
                    <a style={{ color: "red" }} href="tel:6005146451">
                      6005146451
                    </a>{" "}
                    <a style={{ color: "red" }} href="tel:+6597610109">
                      +6597610109
                    </a>{" "}
                    <a style={{ color: "red" }} href="tel:+91 1943575947">
                      +91 1943575947
                    </a>
                  </p>
                  <p className="text-contact ">
                    <span className="office-address">Email:</span>{" "}
                    <a
                      style={{ color: "red" }}
                      href="mailto:javidbhat.kashmir@gmail.com"
                    >
                      javidbhat.kashmir@gmail.com
                    </a>
                    <br />
                    <a
                      style={{ color: "red" }}
                      href="mailto:javidbhat@lovekashmirholidays.com"
                    >
                      javidbhat@lovekashmirholidays.com
                    </a>
                  </p>
                  <p className="text-contact ">
                    <span className="office-address">Website:</span>{" "}
                    <a
                      style={{ color: "red" }}
                      href="https://www.lovekashmirholidays.com"
                    >
                      https://www.lovekashmirholidays.com
                    </a>
                  </p>
                  <p className="text-contact">
                    <span className="office-address">Working Hours:</span>{" "}
                    9am-9pm
                  </p>
                  <Image src={lgoo} alt="reg" fluid />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <FormBooking />
            </Col>
          </Row>
          <Row>
            <Col lg={12} style={{ height: "450px", margin: "30px 0px" }}>
              {" "}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3301.7053529906166!2d74.87153429167492!3d34.15387962793295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5317e671fe717d3d!2sTour%20And%20Travels!5e0!3m2!1sen!2sus!4v1658149491803!5m2!1sen!2sus"
                style={{ width: "100%", height: "100%" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
