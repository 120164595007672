import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";
import emailjs from "emailjs-com";
export default class TailourForm extends Component {
  constructor() {
    super();
    this.state = {
      personName: "",
      phoneEmail: "",
      subject: "",
      message: "",
      successMessage: false,
      errorMessage: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.packageName && this.state.phoneNumber === "") {
      alert("Please Fill in all the Fields");
    } else {
      emailjs
        .send(
          "service_7bfoi5h",
          "template_fabb78g",
          this.state,
          "aeWnO4fNvH5ggGIQT"
        )
        .then(
          () => {
            this.setState({
              successMessage: true,
            });
            setInterval(() => {
              window.location.reload();
            }, 5000);
          },
          () => {
            this.setState({
              errorMessage: true,
            });
          }
        );
    }
  };
  render() {
    const {
      personName,
      phoneEmail,
      subject,
      message,
      successMessage,
      errorMessage,
    } = this.state;
    return (
      <>
        <Container>
          {" "}
          <Row>
            <Col lg={6}>
              {" "}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3301.7053529906166!2d74.87153429167492!3d34.15387962793295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5317e671fe717d3d!2sTour%20And%20Travels!5e0!3m2!1sen!2sus!4v1658149491803!5m2!1sen!2sus"
                style={{ width: "100%", height: "100%" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col lg={6}>
              <form onSubmit={this.handleSubmit}>
                <input
                  className="mb-3 w-100 "
                  type="text"
                  name="personName"
                  value={personName}
                  placeholder="Your Name"
                  onChange={this.handleChange}
                  required
                />
                <input
                  className="mb-3 w-100"
                  type="text"
                  value={phoneEmail}
                  onChange={this.handleChange}
                  name="phoneEmail"
                  placeholder="Phone or Email"
                  required
                />
                <input
                  className="mb-3 w-100 "
                  type="text"
                  name="subject"
                  value={subject}
                  placeholder="Subject"
                  onChange={this.handleChange}
                  required
                />
                <textarea
                  value={message}
                  onChange={this.handleChange}
                  name="message"
                  rows="4"
                  cols="50"
                  className="mb-3 w-100"
                  type="textarea"
                  placeholder="Message"
                />

                <div className="mb-3 error-successMessage">
                  {successMessage
                    ? "Thanks! We Recieved your query we will connect with you soon"
                    : ""}
                  {errorMessage
                    ? "Hey We are having an issue with email currently. Can you reach us by call"
                    : ""}
                </div>
                <Button variant="primary" type="submit">
                  Send
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
