import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionRajasthan from "../InclsionExclusionRajasthan";

import img1 from "../../../../img/Rajasthan/indiagate.jpg";
import img2 from "../../../../img/Rajasthan/tajmahal.jpg";
import img3 from "../../../../img/Rajasthan/jalmahal-japur.jpg";
import img4 from "../../../../img/Rajasthan/pushkar.jpg";
import CarousalRajasthan from "../CarousalRajasthan";

export default class Rajasthan_Tour_2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalRajasthan />
        <Container>
          <PackageTitleDetails
            title="Golden Triangle with Pushkar Tour"
            nights={6}
            day={7}
            destination={["Delhi", "Agra", "Jaipur", "Pushkar", "Delhi"]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 01 : Arrive Delhi"
                  text={
                    <>
                      On arrival at Delhi airport or railway station, our
                      representative will meet you to pick you up and transfer
                      to your hotel. Rest of the day is at your leisure. Stay
                      overnight at the hotel in Delhi
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 02 : Delhi City Tour"
                  text={
                    <>
                      After breakfast first you will visit old Delhi. The Raj
                      Ghat, Red fort & Jama Masjid are important places to
                      visit, you may also visit Chandni Chowk, spice market etc.
                      Later on proceed to visit the New Delhi. Visit Qutub
                      Minar,a UNESCO World Heritage Site. Qutub Minar is an
                      excellent example of Afghan Architecture constructed with
                      marble and red sandstone. And then stop at UNESCO World
                      Heritage-listed Humayuns Tomb, After imposing India Gate
                      memorial built in the year 1931 to commemorate the Indian
                      soldiers. and the Rastrapathi Bhawan, Laxmi Narayan
                      Temple, Lotus Temple and, newly built Akshardham temple
                      are of much significance. By evening return to your hotel
                      for overnight stay.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03 : Delhi to Agra"
                  text={
                    <>
                      This morning after breakfast you will drive to Mughal city
                      Agra and check into your hotel. Enroot you may also visit
                      Mathura-the birth place of Lord Krishana. This afternoon
                      visit the Agra fort, built by the great Emperor Akbar, and
                      the famous Taj Mahal-the symbol of love, built by Emperor
                      Shah Jahan in memory of his wife Mumtaz Mahal. You may
                      also visit Dayal Bagh. By evening return to your hotel for
                      overnight stay.
                    </>
                  }
                  img={img2}
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 04 Agra to Jaipur – En-route Fatehpur Sikri"
                  text={
                    <>
                      <strong>Attractions: </strong>Fatehpur Sikri, City Palace,
                      Museum, Hawa Mahal (Palace of Winds) and Jantar Mantar
                      (Observatory) Morning proceed to Jaipur city( pink city).
                      En route to Jaipur see Fatehpur Sikri (ghost city) made in
                      1581 AD. Proceed to Jaipur, after reaching check in at
                      hotel. In the afternoon proceed to City Palace a building
                      with a brilliant architectural view of both Hindu(rajput)
                      and Muslim (mughal) element.
                      <br />
                      Then moving on to a near by structure known as Jantar
                      Mantar, a beautiful building made by Raja Sawai Jai Singh
                      as an observatory. Moving on to Hawa Mahal or the Palace
                      of Winds, a palace made for the royal ladies of the
                      Rajput’s through which they could watch royal function
                      without been seen by any men. Going back to hotel for
                      night stay in Jaipur.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05 Explore Jaipur"
                  text={
                    <>
                      Attractions: Amber Fort and Jal Mahal (Water Palace)
                      <br />
                      Morning after breakfast moving to Amber Fort, a beautiful
                      attraction made outside the city walls. One of the most
                      unique forts made in the Rajput’s period from where you
                      could have the glance of the city below, as being on a
                      hill makes it a fun experience to have a elephant ride to
                      the top of the fort. Afterward moving back to hotel for
                      rest. Evening experience by clients by there own by
                      roaming free in the unique colorful bazaars of the
                      majestic city. Spending the night at the hotel.
                    </>
                  }
                  img={img3}
                />
              </Row>
              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 06 Jaipur to Pushkar"
                  text={
                    <>
                      Attractions: Lord Brahma Temple, Camel Fair and Local
                      Bazaar
                      <br />
                      Drive to Pushkar in the morning, one of the most visited
                      tourist destinations in Rajisthan. See the beautiful
                      valley’s, mountains and scenic spots along with important
                      pilgrim sites. See the only temple of Lord Brahma, the
                      creator of universe as per Hindu mythology. Enjoy Pushkar
                      during Camel Fair with the sounds of Rajisthani music by
                      the natives. Visit bazar of Pushkar. Later overnight at
                      Pushkar.
                    </>
                  }
                  img={img4}
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 07 Pushkar to Delhi departure"
                  text={
                    <>
                      After having breakfast, drive back to Delhi, then transfer
                      to Delhi airport with sweet memories of Delhi.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionRajasthan />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
