import React, { Component } from "react";
import CarousalRental from "./components/Carousel";
import MidSectionRental from "./components/MidSection";
import TourPackages from "../Homepage/components/TourPackages";
export default class CarRentals extends Component {
  render() {
    return (
      <>
        <CarousalRental />
        <MidSectionRental />
        <TourPackages />
      </>
    );
  }
}
