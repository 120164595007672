import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Gangbal from "../../../../img/gangbal.jpg";
import kashTrek from "../../../../img/kashmirtrek.jpg";
import ladakhTrek from "../../../../img/ladakhtrek.jpg";

export default class CarousalAdventure extends Component {
  render() {
    return (
      <>
        <Carousel className="carousel-wrapper">
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 img" src={Gangbal} alt="Gangbal" />
            <Carousel.Caption>
              <h3>Gangbal Lake Trek</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 img" src={kashTrek} alt="kashTrek" />
            <Carousel.Caption>
              <h3>Kashmir Trek</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img"
              src={ladakhTrek}
              alt="ladakhTrek"
            />
            <Carousel.Caption>
              <h3>Ladakh Trek</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
}
