import React from "react";

import img7 from "../../../../img/7.jpg";
import img8 from "../../../../img/8.jpg";
import img9 from "../../../../img/9.jpg";
import img10 from "../../../../img/10.jpg";
import img11 from "../../../../img/11.jpg";
import img12 from "../../../../img/12.jpg";
import img13 from "../../../../img/13.jpg";
import Carousel from "react-bootstrap/Carousel";

export default function LadakhCarousal() {
  return (
    <>
      <Carousel className="carousel-wrapper">
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img7} alt="Third slide" />
          <Carousel.Caption>
            <h3>Ladakh - Lamayuru Monastery</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img8} alt="Third slide" />
          <Carousel.Caption>
            <h3>Stanka Monastery - Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img9} alt="Third slide" />
          <Carousel.Caption>
            <h3>Hemish Monastery - Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img10} alt="Third slide" />
          <Carousel.Caption>
            <h3>Pangong Tso Lake - Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img11} alt="Third slide" />
          <Carousel.Caption>
            <h3>Diskit Monastery - Nubra Valley Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img12} alt="Third slide" />
          <Carousel.Caption>
            <h3>Nubra - Hunder - Sundune Camel Ride</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img13} alt="Third slide" />
          <Carousel.Caption>
            <h3>Leh to Manali by Road</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
