import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import InclusionExclusion from "../../../../components/ExclusionInclusion";
import img1 from "../../../../img/vaishnodevi.jpg";
import img2 from "../../../../img/aruvallehy.jpg";
import img3 from "../../../../img/mugalgarden34.jpg";
import img4 from "../../../../img/sonamarg-img.jpg";
import img5 from "../../../../img/Srinagar1/gulmarg-gondola.jpg";

export default class Tour_8 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">
              Mata Vaishno Devi with Kashmir Tour
            </p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 8 Nights / 9 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Jammu - Katra -
              Mata Devi Darshan - Katra - Pahalgam - Srinagar - Sonamarg -
              Yusmarg - Gulmarg - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 01: Jammu to Katra (50kms/1.5hrs) or Katra arrival
                  </p>
                  <p className="PackageDescrip">
                    On arrival at Jammu Railway station or Airport / Katra
                    railway station, Meet and greet our friendly driver come
                    representative and later drive to Holy city Katra it home to
                    holy shrine of Mata Vaishno Devi, It is situated in Reasi
                    district of Union territory Jammu and Kashmir, India. Katra
                    act as the base for devotees who visit Mata Vaishno devi. It
                    one of the most visited holy destination of India lakhs of
                    pilgrims visit here every month. On arrival check-in hotel
                    in Katra, Rest of the evening free for shopping or you can
                    enjoy panoramic view of Katra. Night stay in Katra.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 02:- Katra Vaishno Devi darshan
                  </p>
                  <p className="PackageDescrip">
                    Today early morning get ready and it will be most exciting
                    day of your entire you, Star trekking to Mata Vaishno devi
                    main temple which 14kms from Katra at the Trikuta Mountains,
                    Mata Vaishno devi temple or Mata rani temple is one of
                    Holiest temple in India, Vaishno devi is Hindu Temple
                    dedicated to the Goddess Vaishnavi other form of Goddess
                    Durga. Katra to Vaishno Devi trekking normally takes 4-5 hrs
                    each way, you have to get yatra parchi or yatra token in
                    Katra before trekking. Post Mata Vaishno Devi darshan don't
                    forget to visit Bhairo Baba Temple because without that Mata
                    Rani Darshan is incomplete, you can use now cable car to
                    reach Bhairo Baba Temple, Post Darshan return back to hotel
                    in Katra for night stay.
                  </p>
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 03 : Katra - Pahalgam ( 7- 8 hrs)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast check out Hotel and drive to Pahalgam, check
                    in hotel, evening free and sightseeing local like river
                    view, markets. Overnight stay in Hotel
                  </p>
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 04 : Pahalgam - Srinagar (Around 3 hrs On way )
                  </p>
                  <p className="PackageDescrip">
                    Early Morning after breakfast check out Hotel, and Visit to
                    Aru Valley, Betab Valley and Chandanwari Sightseeing, after
                    sightseeing back to Srinagar.
                  </p>
                </Col>
              </Row>

              {/* ------------------------------------------------------------------------------------------ */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">Aru Valley</p>
                  <p className="PackageDescrip">
                    Aru Valley is a small yet self-sufficient scenic village
                    located 12 km from Pahalgam. The drive up to the village is
                    alluring too. The location of the village among the
                    grasslands, make this place a tourist delight. It is
                    reachable by walking along a mountain path. The village also
                    serves as the start point for trekking to Kolahoi glacier.
                    Another long trek for the serious trekker is to Sonmarg,
                    which can be covered in 3 days! Besides, the valley is also
                    known to be a good site for camping. Instead of taking the
                    regular pony ride it is recommended that tourists explore
                    the surrounding valley on foot to get a full sense of its
                    beauty.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Betaab Valley</p>
                  <p className="PackageDescrip">
                    Located on the way to Chandanwari, the valley was named
                    Betaab after the Bollywood movie by the same name! Earlier
                    known as Hajan Valley, after the release of the movie, it
                    was renamed as major part of the movie was shot here. Set
                    amid picture perfect environs complete with snowcapped
                    mountains, gurgling streams, colorful willow trees & the
                    warm sun; making it an ideal for an artist’s canvas! The
                    valley is a walk-able distance from Pahalgam. The crystal
                    clear & chilly water of the stream bubbling down from the
                    snowy hills is a delight; locals here drink this water too.
                    Baisaran and Tulian Lake are few nearby attractions that can
                    be visited.
                  </p>
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Chandanwari</p>
                  <p className="PackageDescrip">
                    Pahalgam to Chandanwari is easily accessible by taxi. From
                    Chandanwari, pilgrims need to continue their Yatra on foot
                    or pony, which will take at least 2 days to reach the cave
                    and back, with an overnight stay in either Shishnag or
                    Panchtarni.
                  </p>
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 5 : Srinagar Local Sightseeing
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast Visit to Srinagar Sightseeing, Mugal Garden
                    - Shalimar Bagh, Nishat Bath, Chasme Shahi - Pari Mahal, and
                    Visit to Shankra Chariya Temple.
                    <br />
                    <br />
                    Nishat Bagh (Garden of Pleasure) The largest Mughal garden
                    situated on the banks of the world-famous Dal-Lake in the
                    backdrop of Zabarwan hills encircles the Dal Lake. Empress
                    Noor Jahan’s brother Asif Khan laid the Garden in 1633 AD.
                    Nishat has seven terraces with a beautiful central water
                    course flowing right in the middle. These terraces rise
                    dramatically off the mountains from the eastern part of the
                    lake.
                    <br />
                    <br />
                    Shalimar Bagh (Garden of Pleasure) – It was planted by
                    Mughal emperor Jehangir for Empress Noor Jahan in 1619 AD
                    and later extended in 1727 A.D. by Shah Jahan. Shaded by
                    magnificent Chinar trees, the Shalimar is a series of stone
                    pavilions and flowing water with paint box bright flower
                    beds. The Garden blooms with innumerable varieties of
                    flowers in spring and autumn. After sightseeing check in
                    Hotel and Overnight stay.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img3} alt="" fluid />
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 06 : Srinagar - Sonamarg - Srinagar ( 76 kms One way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out houseboats and Drive to Sonamarg,
                    and full day excursion of Sonmarg which is one of the most
                    beautiful drive from Srinagar. Sonmarg also called Meadow of
                    Gold is located at a height of 2690 meters. You may take a
                    pony ride (at your own cost) to Thajiwas Glacier where snow
                    remains round the year. Return to the Srinagar, Check in
                    Hotel , and Overnight stay in Hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img4} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 07 : Srinagar - Yusmarg - Srinagar (Around 3 hrs On way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast drive to Yusmarg, Full day excursion to
                    Yusmarg (Meadow of Flowers) enroute visit of Charar sheriff
                    shrine village and town. After noon visit to Doodh Ganga by
                    Pony.
                    <br />
                    Yusmarg, located at the distance of 47 kilometers from the
                    Srinagar, near the Badgam district, Jammu & Kashmir, India,
                    lies in the heart Mountains of south-west of Srinagar. It is
                    a perfect picnic spot and the Nilnag Lake can be visited
                    from here. Taxis can be hired at Srinagar for the trip to
                    yusmarg. The tourists can enjoy pony ride in and around this
                    place. Yousmarg is reputed for having some unique spring
                    flowers. It also has some of the highest peaks in Pir-Panjal
                    range. After Sightseeing return back to Srinagar. and
                    Overnight stay in Hotel.
                  </p>
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 08 : Srinagar - Gulmarg - Srinagar (8700 Feet, 52 kms,
                    1.5 hrs One way)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast chtransfer to Gulmarg called as “Meadow of
                    Flowers” . Gulmarg is 52 Kms from Srinagar at an altitude of
                    8700-ft. Gulmarg also referred as Meadow of Flowers is a
                    famous Ski Resort & the world’s highest 18 hole Golf Course.
                    The place was prominent during the British time. You can
                    enjoy a Pony ride (Optional At your own cost).
                    <br />
                    <br />
                    Enjoy the scenic view of Kongdori (12,293 ft) first stage
                    and Affarwat Peak (13,780 ft) second Stage on a Cable Car
                    Ride (Optional At your own cost) .<br />
                    By evening return to Srinagar. In Evening enjoy a Shikara
                    Ride in Dal Lake and witness the ride on lake, called Venice
                    of India & experience the aquatic life of Srinagar. Also see
                    the scenic extravaganza of the Kashmir and enjoy the
                    profusion of colors in the Paradise on Earth. While
                    returning visit to the local crafts market and see the fine
                    carved hand-made articles from Paper Machine & wood of a
                    walnut. Overnight stay at the Hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img5} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 09 : Srinagar Airport Transfer
                  </p>
                  <p className="PackageDescrip">
                    After Sightseeing check out hotel and transfer to Srinagar
                    Airport / Bus Stand.
                  </p>
                </Col>
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusion />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
