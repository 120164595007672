import React from "react";
import FormBooking from "../../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import InclusionExclusion from "../../../../../components/ExclusionInclusion";
import cabin from "../../../../../img/Srinagar1/glmarg-winter-view1.jpg";
import skate from "../../../../../img/glmarg-winter-view.jpg";

export default function PackageDetail2() {
  return (
    <>
      <Container>
        <div className="PackageDetailswrapper">
          <p className="Heading-houseboat">Gulmarg Winter Tour (Day Trip)</p>
          <p className="DurationOfPackage">
            <span className="darker"> Duration :</span> 3 Nights / 4 Days Trip
          </p>

          <p className="DurationOfPackage">
            {" "}
            <span className="darker"> Destination :</span> Srinagar - Gulmarg -
            Srinagar
          </p>
          <hr />
        </div>
        <Row>
          <Col lg={9} md={6}>
            {/* -----------------------------------------------Day `1`----------------------------------------- */}
            <Row>
              <Col lg={8} md={6}>
                <p className="titlePackage">
                  Day 01 : Srinagar - Gulmarg (Approx 2 hrs)
                </p>
                <p className="PackageDescrip">
                  On arrival at Srinagar, you will be met by our representative
                  and drive to Gulmarg, via srinagar, On Arrival Gulmarg, Check
                  in Hotel and Overnight stay in Hotel. Snowfall in Kashmir has
                  always been a sight to behold. If you’re hoping to catch
                  snowfall, visit Gulmarg in winter. Gulmarg, is a quaint little
                  town settled in the heart of Kashmir. The beautiful meadows of
                  Gulmarg, usually lush green during April and early May are
                  enveloped in a sheet of white snow during the snowfall in
                  Kashmir. The best time to visit Gulmarg for Snowfall is just
                  after peak winter – January and February. Head to Gulmarg with
                  your friends or family to experience this Winter Wonderland.
                </p>
              </Col>
              <Col lg={4} md={6}>
                <Image src={cabin} alt="" fluid />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={6}>
                <p className="PackageDescrip">
                  December to March is the ultimate experience for adventure
                  sports and to experience the snow-laden landscapes of Gulmarg.
                  From skiing to the highest lift, Gulmarg is home for winter
                  adrenaline junkies. The highest golf course in the world, head
                  over the best time to visit Gulmarg for a completely unique
                  experience. The beauty of Gulmarg is heightened in winter, but
                  the temperatures can dip down to -4 degrees which can chill
                  even the most seasoned humans. Build a snow-man, ski down the
                  slopes or indulge in snow fights with your loved ones in
                  Gulmarg in winter. Plenty of skiing opportunities offer you a
                  chance to get up and close with nature as you take a trip down
                  shark fin or ride in the Gulmarg Gondola.
                </p>
              </Col>
            </Row>
            {/* -----------------------------------------------Day 2----------------------------------------- */}
            <Row>
              <Col lg={8} md={6}>
                <p className="titlePackage">Day 02 : Gulmarg</p>
                <p className="PackageDescrip">
                  After Breakfast, Visit o Gondola ride (up to first level
                  only). Witness the beauty of crystal clear frozen lake of
                  Apharwat, which is nestled high up in the Apharwat peaks of
                  the Himalayas. This scenic visual is one of the most stunning
                  attractions in Gulmarg and makes for a great picnic spot,
                  surrounded by craggy mountains adorned by wild flowers.
                  Gulmarg, the hill station never ceases to be beautiful, no
                  matter what the season. This surreal hill station is a coveted
                  tourist destination. Complete with snow-capped mountains and
                  lush greenery, Gulmarg in snowfall is even prettier than
                  Gulmarg in monsoons or Summer. After sightseeing back to Hotel
                  and Overnight stay.
                </p>
              </Col>
              <Col lg={4} md={6}>
                <Image src={skate} alt="" fluid />
              </Col>
            </Row>
            {/* -----------------------------------------------Day 3----------------------------------------- */}
            <Row>
              <Col lg={12} md={6}>
                <p className="titlePackage">Day 03 : Gulmarg - Srinagar Back</p>
                <p className="PackageDescrip">
                  After breakfast check out Hotel and drive back to Srinagar and
                  visit to world famous Mughal Gardens visiting the Nishat Bagh
                  (The garden of pleasure) and Shalimar Bagh (Abode of love). In
                  the evening Shikara ride on The Dal Lake to enjoy the beauty
                  that God has graced this city with which is often compared to
                  Venice.
                  <br />
                  You will get a view of some very interesting places around the
                  Lake. After sightseeing back to Houseboat, Overnight stay in
                  Houseboat.
                </p>
              </Col>
            </Row>
            {/* -----------------------------------------------Day 4----------------------------------------- */}
            <Row>
              <Col lg={12} md={6}>
                <p className="titlePackage">Day 04 : Srinagar Departure</p>
                <p className="PackageDescrip">
                  After Breakfast check out hotel and transfer to Srinagar
                  Airport for Departure with the memories that can’t be
                  forgotten.
                </p>
              </Col>
            </Row>

            {/* --------------------------------------------------inClusion--------------- */}
            <InclusionExclusion />
            {/* --------------------------keep down------------------------------------ */}
          </Col>

          {/* --------------------------------------------------------------------------------------------------------- */}

          {/* Booking Section */}
          <Col lg={3} md={6}>
            <FormBooking />
          </Col>
        </Row>
      </Container>
    </>
  );
}
