import React, { Component } from "react";
import houseBoat1 from "../../../../img/rosemarry1.jpg";
import houseBoat2 from "../../../../img/rosemarry2.jpg";
import houseBoat3 from "../../../../img/rosemarry3.jpg";
import houseBoat4 from "../../../../img/rosemarry4.jpg";
import houseBoat5 from "../../../../img/rosemarry5.jpg";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";

export default class BottomSectionHouseBoat extends Component {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col lg={6} md={12} className="mb-2">
              <Image src={houseBoat1} alt="HouseBoat image " fluid />
            </Col>
            <Col lg={6} md={12} className="mb-2">
              <Image src={houseBoat2} alt="HouseBoat image" fluid />
            </Col>{" "}
            <Col lg={6} md={12} className="mb-2">
              <Image src={houseBoat2} alt="HouseBoat image" fluid />
            </Col>{" "}
            <Col lg={6} md={12} className="mb-2">
              <Image src={houseBoat3} alt="HouseBoat image" fluid />
            </Col>
            <Col lg={6} md={12} className="mb-2">
              <Image src={houseBoat4} alt="HouseBoat image" fluid />
            </Col>
            <Col lg={6} md={12} className="mb-2">
              <Image src={houseBoat5} alt="HouseBoat image" fluid />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
