import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import "./index.css";
import mataVeshno from "../../../../img/vaishnodevi.jpg";
import Helicopter from "../../../../img/amarnathyatra.jpg";
import trek from "../../../../img/amarnathyatra-trek.jpg";
import { Link } from "react-router-dom";

export default class SpecialTour extends Component {
  render() {
    return (
      <>
        <Container className="text-center">
          <div className="title-packages">Special Tour Packages</div>
          <Row>
            <Col lg={4} md={6} className="mt-3">
              <Card>
                <Card.Img variant="top" src={mataVeshno} />
                <Card.Body>
                  <Card.Title>Mata Vaishno Devi Darshan</Card.Title>
                  <Card.Text>
                    Mata Vaishno Devi Cave Temple is situated in the Trikuta
                    Hills in Katra, Jammu & Kashmir. Hindu devotees from all
                    over the world visit this famed religious site where the
                    Mother Goddess or Mata Vaishno Devi is said to fulfill their
                    wishes.
                  </Card.Text>
                  <Button className="button-special" as={Link} to="/jk/Tour_8">
                    READ MORE
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} className="mt-3">
              <Card>
                <Card.Img variant="top" src={Helicopter} />
                <Card.Body>
                  <Card.Title>Amarnath Yatra by Helicopter</Card.Title>
                  <Card.Text>
                    Shri Amarnath Yatra is for a specific period/ duration,
                    commencing on an announced date, usually on, the auspicious
                    day of Skandshasthi as per Hindu Calendar, and shall
                    conclude on Shravan Purnima (Raksha Bandan)
                  </Card.Text>
                  <Button className="button-special" as={Link} to="/jk/Tour_10">
                    READ MORE
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} className="mt-3">
              <Card>
                <Card.Img variant="top" src={trek} />
                <Card.Body>
                  <Card.Title>Amarnath Yatra by Trek</Card.Title>
                  <Card.Text>
                    Amarnath trek is 2 Routes - Pahalgam, Chandanwari, Pissutop
                    - Sheshnag - Mahaganesh top - Panchtarni - Sangam - Cave (45
                    km trek). And Vial Sonamarg : Baltal - Domail - Barari -
                    Sangam - Cave (14 km trek)
                  </Card.Text>
                  <Button className="button-special" as={Link} to="/jk/Tour_12">
                    READ MORE
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
