import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionRajasthan from "../InclsionExclusionRajasthan";

import img1 from "../../../../img/Rajasthan/indiagate.jpg";
import img2 from "../../../../img/Rajasthan/tajmahal.jpg";
import img3 from "../../../../img/Rajasthan/hawamahal.jpg";
import img4 from "../../../../img/Rajasthan/udaipur.jpg";
import img5 from "../../../../img/Rajasthan/jodhupur.jpg";
import img6 from "../../../../img/Rajasthan/jaisalmer.jpg";
import img7 from "../../../../img/Rajasthan/jaislamer-fort.jpg";
import img8 from "../../../../img/Rajasthan/bikaner.jpg";
import CarousalRajasthan from "../CarousalRajasthan";

export default class Rajasthan_Tour_5 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalRajasthan />
        <Container>
          <PackageTitleDetails
            title="Rajasthan Heritage Tour"
            nights={13}
            day={14}
            destination={[
              "Delhi",
              "Agra",
              "Jaipur",
              "Pushkar",
              "Udaipur",
              "Jodhpur",
              "Jaisalmer",
              "Bikaner",
              "Mandawa",
              "Delhi",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 01 : Arrive Delhi"
                  text={
                    <>
                      On arrival at Delhi airport or railway station, our
                      representative will meet you to pick you up and transfer
                      to your hotel. Rest of the day is at your leisure. Stay
                      overnight at the hotel in Delhi
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 02 : Delhi City Tour"
                  text={
                    <>
                      After breakfast proceed for sightseeing tour of Old & New
                      Delhi: <br />
                      <strong> OLD DELHI </strong> - A sight-seeing tour of Old
                      Delhi would entail visiting the Raj Ghat - the memorial
                      site where Mahatma Gandhi was cremated; Jama Masjid - the
                      largest mosque in India.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      <strong> NEW DELHI </strong> - An extensive sight-seeing
                      tour of New Delhi would include a visit to the Humayun's
                      Tomb, the Qutub Minar, a drive along the ceremonial avenue
                      - Rajpath, past the imposing India Gate, Parliament House,
                      the President's Residence and would end with a drive
                      through the Diplomatic Enclave. Night stay in Delhi.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 03 : Delhi - Agra ( By road Approx. 4 Hrs.)"
                  text={
                    <>
                      After breakfast drive to Agra. Arrive and transfer to
                      hotel. Later visit the Agra Fort, containing the Pearl
                      Mosque - the rusty and majestic red- sandstone fort of
                      Agra stands on the banks of the river Yamuna and the
                      construction was started by Emperor Akbar in 1566. At the
                      Diwan-I-Khas (hall of private audience) where marble
                      pavilions with floral inlays lend an ethereal ambience,
                      the Emperor sat on his gem-studded peacock Throne and met
                      foreign ambassadors and rulers of friendly kingdoms. Also
                      visit Itmad-up-Dau's tomb, built by Noorjehan in memory of
                      her father. Night stay in Agra.
                    </>
                  }
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 04 : Agra - Jaipur (By road Approx. 5 Hrs.)
                  "
                  text={
                    <>
                      Early morning at sunrise visit the world famous Taj Mahal,
                      built by the Mughal Emperor Shahjehan in 1630 for his
                      queen Mumtaz Mahal to enshrine her mortal remains. Later
                      after breakfast drive to Jaipur enroute visiting Fatehpur
                      Sikri, built by Emperor Akbar in 1569 to commemorate the
                      birth of his son and later abandoned due to scarcity of
                      water. Visit the remains, it fortifications within the
                      city including Jama Masjid, Tomb of Salim Chisti, Panch
                      Mahal and other palaces. On arrival transfer to hotel.
                      Night stay in Jaipur.
                    </>
                  }
                  img={img2}
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 05 : Jaipur"
                  text={
                    <>
                      Morning excursion to Amber Fort. Elephant ride ascent to
                      the fort.
                      <br />
                      AMBER FORT PALACE - Amber is the classic romantic
                      Rajasthani fort palace. Its construction was started by
                      Man Singh I in 1592, and completed by his descendent Jai
                      Singh I. Its forbidding exterior belies an inner paradise
                      where a beautiful fusion of Mughal and Hindu styles finds
                      it's ultimate expression. Continue sight-seeing.
                      <br />
                      <br />
                      CITY PALACE - A delightful blend of Mughal and traditional
                      Rajasthani architecture,the City Palace sprawls over
                      one-seventh of the area in the walled city. It houses the
                      Chandra Mahal, Shri Govind Dev Temple and the City Palace
                      Museum.
                    </>
                  }
                />
              </Row>
              <Row>
                <PackageWithImage
                  text={
                    <>
                      JANTAR MANTAR - This is the largest and the best preserved
                      of the five observatories built by Jai Singh II in
                      different parts of the country. This observatory
                      consisting of outsized astronomical instruments is still
                      in use.
                      <br />
                      <br />
                      HAWA MAHAL - The ornamental facade of this "Palace of
                      Winds" is a prominent landmark in Jaipur. Their
                      five-storey structures of sandstone plastered pink
                      encrusted with fine trelliswork and elaborate balconies.
                      The palace has 953 niches and windows. Built in 1799 by
                      Pratap Singh, the Mahal was a royal grandstand for the
                      palace women. Night stay in Jaipur.
                    </>
                  }
                  img={img3}
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 06 : Jaipur - Udaipur ( By road Apprx. 8 Hrs.) via Pushkar"
                  text={
                    <>
                      Morning drive to Udaipur- an oasis in the desert state of
                      Rajasthan enroute visiting Pushkar. Pushkar boasts
                      temples, though few are as ancient as you might expect at
                      such an important pilgrimage site, since many were
                      deserted by Aurangzeb and subsequently rebuilt. The most
                      famous is the Brahma Temple, said to be one of the few
                      temples in the world dedicated to this deity. It's marked
                      by red spire, and over the entrance gateway is the hans,
                      or goose symbol, of Brahma, who is said to have personally
                      chosen Pushkar as its site. Arrive Udaipur and transfer to
                      hotel. Night stay in Udaipur.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 07 : Udaipur"
                  text={
                    <>
                      Morning city sight seeing tour of Udaipur. The city is
                      built in 1559 A.D. by Maharaja Udai Singh and has been
                      described as the most romantic spot on the continent of
                      India by Col. James Todd. Visit the City Palace museum,
                      the Jagdish temple, Sahelion Ki Bari (Queen's resort for
                      their friends) Bhartiya Lok Kala Mandir (Folk art museum)
                      and the Pratap Memorial. Rest of the day at leisure. Night
                      stay in Udaipur.
                    </>
                  }
                  img={img4}
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 08 : Udaipur - Jodhpur ( By road Apprx. 7 Hrs.) via Ranakpur"
                  text={
                    <>
                      After breakfast drive to Jodhpur in route visit Ranakpur
                      Jain Temple. Ranakpur is one of the five holy places of
                      the Jain community. These temples were built in the 15th
                      century AD during the reign of Rana Kumbha and are
                      enclosed within a wall. The basement is of 48, 000 sq.
                      feet area that covers the whole complex. There are four
                      subsidiary shrines, twenty-four pillared halls and
                      eligibly domes supported by over four hundred columns.
                      Continue drive to Jodhpur on arrival transfer to hotel.
                      Night stay in Jodhpur.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 09 : Jodhpur"
                  text={
                    <>
                      After a relaxed breakfast proceed for city sight seeing
                      tour of Jodhpur. Jodhpur - the stronghold of the fierce
                      Rathore clan, was founded in 1459 by Prince Jodha. Visit
                      the Mehrangarh Fort, situated on a low sandstone hill.
                      Within the fort, visit Moti Mahal and Phool Mahal. Also
                      visit Jaswant Thada, an imposing marble cenotaph, built in
                      memory of Maharaja Jaswant singh II around 1899 and Umaid
                      Public gardens. Night stay in Jodhpur.
                    </>
                  }
                  img={img5}
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 10 : Jodhpur - Jaisalmer ( By road Approx. 7 Hrs.)"
                  text={
                    <>
                      Morning after breakfast drive to Jaisalmer - induces a
                      dramatic picture of utter magic and brilliance of the
                      desert. The hostile terrain not with standing the warmth
                      and color of people is simply over whelming. One of the
                      main draws is the daunting 12th century Jaisalmer Fort and
                      beautiful havelis which were built by wealthy merchants of
                      Jaisalmer are yet another interesting aspect of the you
                      can let your eyes caress the sloppy sand dunes while you
                      ramble your way in a camel safari. Bhatti Rajput ruler
                      Rawal Jaisal, after whom the city finds its name, founded
                      Jaisalmer in 1156. The drive passes by dry vegetation,
                      with villages of colorfully dressed people. Plenty of
                      camels and camel carts, sheep and goats pass you by. On
                      arrival in Jaisalmer, check in the Hotel. Rest the day is
                      free at leisure. Night stay in Jaisalmer.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 11 : Jaisalmer"
                  text={
                    <>
                      Morning city sightseeing tour of Jaisalmer Visit Jaisalmer
                      Fort, over the Trikuta hill, this fort is the biggest and
                      the most beautiful landmark of the city. The fort is
                      occupied by shops, stalls, and a number of other business
                      concerns. It is also known as Sonar Quila or the Golden
                      fort, rising from the sand, the mega structure merges with
                      the golden hues of the desert ambience. The Jain temple,
                      situated inside the fort, made between 12th and 15th
                      century, bears testimony to Rajasthan unique artistry
                      Visit the havelis; the mansions built by the wealthy
                      merchants, which are still in very good condition. The
                      intricate craftsmanship is beyond parallel.
                    </>
                  }
                  img={img6}
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      The havelis like the Patwon-ki-Haveli and
                      alim-Singh-ki-Haveli are remarkable for their exclusive
                      cutwork, yellow and red stone treatment and the
                      bracket-shaped decoration of peacocks.
                      <br />
                      <br />
                      The rich merchant inspired by the classic style of the
                      royals, constructed huge mansions (havelis) adjacent to
                      each other in the nature of medieval culture and profusely
                      decorated walls and ceilings and intricately carved
                      outdoors and interiors. In the afternoon, visit the Khuri
                      Sand Dunes and the typical Rajasthani Desert Village. Take
                      a camel ride to view the sunset from the dunes, (Same
                      camels to be used for Safari - getting to know the camels)
                      as the sky is set on fire. It can be an unforgettable
                      experience. Overnight in Jaisalmer.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 12 : Jaisalmer - Bikaner ( By road Approx. 6 Hrs.)"
                  text={
                    <>
                      Morning drive to Bikaner. On arrival transfer to the
                      hotel. Afternoon city sight seeing tour. Bikaner is named
                      after its founder Rao Bikaji in 1488. But long before
                      this, the city was trade center on the caravan routes from
                      Africa and West Asia. Bikaner's dominant features are
                      rippling sands and abundant sunshine. The city stands on
                      an elevation, enclosed by a 7 Kms wall, which can be
                      entered from 5 gate. Bikaner City is bolstered by imposing
                      walls and has a 16th century fort housing old palaces,
                      temples and mosque.
                    </>
                  }
                  img={img7}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Founded by Raja Rai Singh the fort is distinguished by its
                      long range of 37 pavilions, a line of balconies and aerial
                      windows of varying designs. Also visit Lallgarh Palace,
                      the museum, Bhanda Sagar Jain, Deshnoke Rat temple and The
                      Camel's Breeding Farm. Night stay in Bikaner.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 13 : Bikaner - Mandawa ( By road Approx. 06 Hrs.)"
                  text={
                    <>
                      Morning drive to Mandawa, on arrival transfer to the
                      hotel. Later visit the painted havelies of the Dundlod
                      town and surrounding areas of the Mandawa, Mandawa founded
                      in 1755 by Thakur Nawal Singh and still the home of his
                      descendants, has beautiful frescoes, havelis and mansions.
                      Mandawa castle one of the finest castles of Rajasthan, is
                      now a hotel and house of collection of armories, costumes
                      of ancestors, canons from 1820 ancient coins, numismatic
                      collection and many other interesting items, preserved in
                      a museum. Night stay in Mandawa.
                    </>
                  }
                  img={img8}
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 14 : Mandawa - Delhi (By road Approx. 06 Hrs.) - Departure"
                  text={
                    <>
                      Morning After breakfast drive to Delhi and transfer to
                      airport for your onward journey.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionRajasthan />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
