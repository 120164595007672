import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./index.css";
import carousal1 from "../../../../img/caoursal_first.jpg";
import inshop from "../../../../img/inshop.jpg";

export default function ContactUSCarousel() {
  return (
    <>
      <Carousel className="carousel-wrapper">
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={carousal1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={inshop} alt="First slide" />
        </Carousel.Item>
      </Carousel>
    </>
  );
}
