import React, { Component } from "react";
import "./index.css";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";
import PackageDisplay from "../../../components/Package";
import lehladakh1 from "../../../img/leh/magnetichill.jpg";
import lehladakh2 from "../../../img/leh/huder-camel-ride.jpg";
import lehladakh3 from "../../../img/leh/lamayuru1.jpg";
import lehladakh4 from "../../../img/leh/turtuk.jpg";
import lehladakh5 from "../../../img/leh/kargil2.jpg";
import lehladakh6 from "../../../img/leh/pangonglake.jpg";
import lehladakh7 from "../../../img/leh/transhimalayan1.jpg";
import lehladakh8 from "../../../img/leh/ladakh-bike-tour.jpg";
import lehladakh9 from "../../../img/leh/kargil-img1.jpg";
import TailourForm from "../../../components/TailourMadeForm";
import LadakhCarousal from "./CarousalLadakh";

export default class LadakhHolidays extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <div className="JK_Title">Leh Ladakh Holiday Packages</div>
        </Container>
        <Container className="mt-4 text-center">
          <Row>
            <PackageDisplay
              link="/leh_ladakh/Tour_1"
              title="Srinagar Trip Tour (Day Trip)"
              image={lehladakh1}
              nights={3}
              day={4}
              destination={["Leh", "Shyam Valley", "Pangong Lake", "Leh"]}
            />
            <PackageDisplay
              link="/leh_ladakh/Tour_2"
              title="Nubra Valley with Pangong Lake Tour"
              image={lehladakh2}
              nights={4}
              day={5}
              destination={[
                "Khardungla Pass",
                "Nubra",
                "Hunder",
                "Pangong",
                "Leh",
              ]}
            />
            <PackageDisplay
              link="/leh_ladakh/Tour_3"
              title="Lamayuru with Nubra & Pangong Tour"
              image={lehladakh3}
              nights={5}
              day={6}
              destination={[
                "Leh",
                "Lamayuru",
                "Leh",
                "Nubra",
                "Hunder",
                "Pangong",
                "Leh",
              ]}
            />

            <PackageDisplay
              link="/leh_ladakh/Tour_4"
              title="Nubra Turtuk with Pangong Lake Tour"
              image={lehladakh4}
              nights={6}
              day={7}
              destination={[
                "Leh",
                "Shyam Valley",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Turtuk Valley",
                "Hunder",
                "Pangong Lake",
                "Leh",
              ]}
            />

            <PackageDisplay
              link="/leh_ladakh/Tour_5"
              title="Leh Kargil with Nubra Turtuk & Pangong Lake"
              image={lehladakh5}
              nights={7}
              day={8}
              destination={[
                "Leh",
                "Magnetic Hill",
                "Sangam",
                "Lamayuru",
                "Kargil",
                "Leh",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Leh",
              ]}
            />
            <PackageDisplay
              link="/leh_ladakh/Tour_6"
              title="Enchanting Ladakh Himalayan Tour"
              image={lehladakh6}
              nights={8}
              day={9}
              destination={[
                "Leh",
                "Monastery",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Tsomorori",
                "Leh",
                "Syam Valley",
              ]}
            />

            <PackageDisplay
              link="/leh_ladakh/Tour_7"
              title="Ladakh Trans Himalayan Tour"
              image={lehladakh7}
              nights={9}
              day={10}
              destination={[
                "Leh",
                "Magnetic Hill",
                "Sangam",
                "Lamayuru",
                "Leh",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Hanle",
                "Tsomorori",
                "Leh",
              ]}
            />

            <PackageDisplay
              link="/leh_ladakh/Tour_8"
              title="Discover Ladakh Himalayan Tour & Manali Drop"
              image={lehladakh8}
              nights={9}
              day={10}
              destination={[
                "Leh",
                "Shyam Valley",
                "Kargil",
                "Leh",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Leh",
                "Tsomorori",
                "Jispa",
                "Manali",
              ]}
            />
            <PackageDisplay
              link="/leh_ladakh/Tour_9"
              title="Kashmir Ladakh Trip(Overnight)"
              image={lehladakh9}
              nights={11}
              day={12}
              destination={[
                "Srinagar",
                "Gulmarg",
                "Srinagar",
                "Kargil",
                "Leh",
                "Khardungla",
                "Nubra",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Leh",
                "Kargil",
                "Srinagar",
              ]}
            />
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <TailourForm />
          </Row>
        </Container>
      </>
    );
  }
}
