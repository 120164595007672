import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/esm/Image";
import "./index.css";
import gulmarg from "../../../../img/gulmarg-stay.jpg";

export default class LuxuryStay extends Component {
  render() {
    return (
      <>
        <Container className="warpper">
          <div className="best_tour_wrapper ">
            <div className="best_tour_title">Luxury Stay</div>
            <p className="line"></p>
          </div>
          <Row>
            <Col className="left-image" lg={6} md={12}>
              <Image src={gulmarg} alt="" fluid />
            </Col>

            <Col lg={6} md={12} className="right-text">
              <h5>THE RESORT SERVICE</h5>
              <p className="text-luxury">
                The resort lies 65 kilometres away from Srinagar Airport. Your
                experience begins with a ninety-minute transfer by car to The
                Resorts or Hotels, through languid meadows and forested slopes.
                A world away from the ordinary, this 85 key resort is framed at
                every vantage point by nature at its most spectacular.
              </p>
              <p className="text-luxury colored-para">
                The world’s highest ski lift, the Gulmarg Gondola – which takes
                you to Kongdoori Mountain, is only a short walk away. Meadows
                and forests that have enchanted travellers for centuries wait to
                be explored by trekking enthusiasts and undying romantics.
              </p>
              <span className="text-luxury" style={{ fontWeight: "bolder" }}>
                The Rooms -{" "}
              </span>{" "}
              <span className="text-luxury">
                Luxurious spaces with floor to ceiling windows that offer
                panoramic vistas of snow capped mountains, beautifully
                landscaped gardens and forests covered with pine trees.
                State-of-the-art climate control makes you feel comfortable even
                on the coldest winter night. Built in walnut timber and
                decorated.
              </span>
              <ul className="text-luxury">
                <li>Luxury Hotels in Gulmarg</li>
                <li>Gondola Ride Service</li>
                <li>Horse Ride in Gulmarg</li>
                <li>Skiing in Gulmarg</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
