import React, { Component } from "react";
import gif from "../../../../img/helpdesk-icon.gif";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import "./index.css";
import imgTrek1 from "../../../../img/thajwas-trek.jpg";
import imgTrek2 from "../../../../img/trekking.jpg";
import imgTrek3 from "../../../../img/vishansar-lake-sonmarg1.jpg";
import imgTrek4 from "../../../../img/kolohglaciertrek.jpg";
import imgTrek5 from "../../../../img/markhavalley.jpg";
import imgTrek6 from "../../../../img/kargil-img1.jpg";
import imgTrek7 from "../../../../img/stokkangri-trek.jpg";
import imgTrek8 from "../../../../img/chadartrek3.jpg";
import imgTrek9 from "../../../../img/chadartrek.jpg";
import imgTrek10 from "../../../../img/carht3ejktr3e.jpg";
import imgTrek11 from "../../../../img/chagr3ek5r.jpg";
import FormBooking from "../../../../components/Form";

export default class MidSectionAdventure extends Component {
  render() {
    return (
      <>
        <Container className="mt-5">
          <Row>
            <Col lg={9} md={6}>
              <p className="Heading-houseboat">
                KASHMIR LADAKH ADVENTURE TOURS
              </p>
              <p className="Heading-adventure">
                Best Trekking Plan for Kashmir
              </p>
              <Row>
                <Col lg={6}>
                  <p style={{ fontSize: "14px" }}>
                    We offer the best of Kashmir trekking experience for you
                    with our specially designed trekking tour packages. Trek to
                    the alpine lakes like Gangabal, Gadsar, Tarsar & Marsar,
                    Great Lakes, and the ancient villages tucked away in the
                    mountains like Naranag with our best trekking tour packages
                    for Kashmir. <br />
                    <br />
                    The best time to trek in Kashmir is considered July to
                    October, which cover two seasons – monsoon and
                    winter/autumn. Trekking in April is also considered an ideal
                    time. <br />
                    <br />
                    The Kashmir Great Lakes trek has 7 trekking days. And
                    coincidentally you are also trekking in 7 different valleys.
                    What’s more, each of these valleys, spread themselves out
                    each day of the trek. Every valley gives a hard competition
                    to the valley before and the valley after.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p style={{ fontSize: "14px" }}>
                    {" "}
                    Among the 7 different valleys you trek, 6 happen to be
                    distinct grasslands or meadows. These high altitude meadows
                    and grasslands are connected to one another via high passes.
                    One would expect the meadows on either side of a pass to be
                    similar. But they aren’t. Each one of them is a world of its
                    own and has a charm of its own. The highest trek in Kashmir
                    is Kolahoi Glacier Trek, where one crosses Basmai Pass at
                    the altitude of 4300 m. There are also short treks to
                    Khilanmarg and Ningel Nallah from Gulmarg; to Thajiwas
                    Glacier from Sonmarg; and to Aru Valley from Pahalgam that
                    are ideal family treks as well.
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    5 Treks to do This Summer in Jammu Kashmir <br />
                    <br />
                    The Great Lakes Tour of Kashmir <br /> The Tarsar Marsar
                    Trek. <br /> The Trek of Narang Mahlish. <br /> Kolahoi
                    Glacier Trek. <br /> Sonmarg Vishansar Trek.
                  </p>
                </Col>
              </Row>
              {/* ------------------------------Great Lake Tour of Kashmir------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    1. The Great Lakes Tour of Kashmir
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The Great Lakes Trek is the most popular trek in Kashmir.
                    One of the key scenic beauties of Kashmir just happens to be
                    a large number of beautiful lakes which are spread across
                    the entire state. Some of them are spread across the
                    lustrous green valleys while others are located atop
                    mountains. Whatever their location, each of them is sure to
                    captivate you with their beauty and majestic setting. One of
                    the most popular options among the top treks in Kashmir, the
                    Great lakes tour takes the tourists on a trekking expedition
                    across the different lakes spread across the Kashmir valley
                    and mountains. It is a popular option for beginners as well,
                    as the exertion is relatively moderate to low and can be
                    handled even by first time trekkers easily. The duration of
                    a typical trekking expedition of the lakes extends to about
                    7 to 10 days.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek2} alt="" fluid />
                </Col>
              </Row>

              {/* ------------------------------ The Tarsar Marsar Trek------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    2. The Tarsar Marsar Trek
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The Kashmir valley with its lush green meadows and
                    enchanting lakes is in full bloom during the spring and
                    summer. It is a beautiful time to embark on a trek across
                    these green meadows and spend nights under the clear night
                    sky. The Tarsar Marsar Trek aims to achieve exactly that,
                    and provides tourists with an opportunity to enjoy the
                    scenic views from the banks of the twin lakes which are
                    nestled among the lush green meadows and a valley that
                    extends to as far as the eye can see. It is also a very
                    comfortable trek option for beginners which makes it one of
                    the top 5 treks to do this summer in Jammu Kashmir.The trek
                    location is about 5 kilometres from Subdarsar, and is
                    therefore easily accessible. The duration of the trek is
                    about 7 days. The beautiful lakes and the green valley makes
                    it one of the must visit spots in the entire state.
                  </p>
                </Col>
              </Row>

              {/* ------------------------------  The Trek of Narang Mahlish------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    3. The Trek of Narang Mahlish
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Moving on from the valleys to the foothills of mountains,
                    the Narang Mahlish trek is easily one of the best Himalayan
                    treks for this summer. The trekking expedition takes you to
                    the foothills of the Harmukh Mountain and allows to fully
                    enjoy the beauty of the twin mountain top lakes of Gangabal
                    and Nundkol. The scenic setting provides an ideal views of
                    the lakes and meadows amongst the backdrop of the mountains.
                    During the course of the trek, the trekkers have to go
                    through dense forests of pine, where you can soak in the
                    greenery and the fresh air as you move towards the
                    mountains.
                    <br />
                    <br /> The trek is a special one as it allows you to explore
                    the different aspects of Kashmir, letting you travel through
                    pine forests, across green meadows and ultimately to the
                    blue waters of mountaintop lakes. It is therefore a must
                    visit for all tourist who are adventure enthusiasts.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek3} alt="" fluid />
                </Col>
              </Row>

              {/* ------------------------------   Kolahoi Glacier Trek------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    4. Kolahoi Glacier Trek
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    As we move to our next entry on the list, the stakes are
                    even higher, as we take you on a journey to the Kolahoi
                    Glacier, the origin source of the major rivers of Kashmir
                    such as the Jhelum and Lidder. The entire trek closely
                    follows a path across different rivers originating from the
                    glacier and takes you on a journey across beautiful
                    riverbanks, filled with pine forests and guzzling mountain
                    water.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek4} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p className="mt-3" style={{ fontSize: "14px" }}>
                    {" "}
                    From the river banks, the journey gradually shifts to the
                    valleys of the Himalayas which are filled with lush green
                    beauty of Kashmir during the summer time. From the valleys
                    the trek continues up to the glacier itself located atop the
                    Himalayan ranges. The trek itself is quite arduous and
                    should only be attempted by trekkers with previous
                    experience and with suitable physical fitness. Beginners can
                    also attempt the trek after going through the necessary
                    training procedure. The duration of the trek extends to
                    about 5 days, and takes you up to an altitude as high as
                    12,000 feet above the sea level.
                  </p>
                </Col>
              </Row>
              {/* ------------------------------ 5. Sonmarg Vishansar Trek------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    5. Sonmarg Vishansar Trek
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The final entry on our list is a truly spectacular journey
                    that takes you across the high altitude passes of the
                    Himalayas to the peaks of Vishansar and Narang. The journey
                    takes you across the mind boggling views of the Nichnai pass
                    nestled amongst the Himalayas. The views of snow covered
                    mountains and valleys as you trek upwards into the higher
                    echelons of mountaintops is a breathtaking one. On the way,
                    one can also enjoy the beauty of the Krishnasar Lake, among
                    others. Taking trekkers’ upto an altitude of 13000 feet, the
                    journey isn’t for the light hearted. But the 8 days
                    experience is sure to be one that you would never forget
                  </p>
                </Col>
              </Row>
              <p className="Heading-adventure">Ladakh Trekking </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                The best time for trekking in Ladakh is from May to October.
                However, some treks are possible only from early July due to
                heavy snowfall on the mountain passes in winters. The onset of
                winter marks the beginning of two treks that are organised from
                January to March every year in Ladakh: the Chadar trek on the
                frozen Zanskar river and the Snow Leopard trek.
              </p>
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Markha valley trek
                    <br />
                    Best time: end of April to beginning of October
                    <br />
                    Duration: 6 to 8 days
                    <br />
                    Difficulty: level 3<br />
                    Max altitude: 5260m
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The Markha valley trek in the Hemis National Park is the
                    most popular trek in Ladakh. The Markha valley runs parallel
                    with the Indus valley, wedged between the snow-clad Stok
                    Kangri massif to the north and the Zanskar range to the
                    south. During this trek, you will see breathtaking mountain
                    scenery, wild animals and small isolated villages surrounded
                    by green barley fields. You will have the opportunity to
                    stay in homestays in these villages and discover the way of
                    life of the local people
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek5} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The trek takes 6 to 8 days to complete depending on the
                    starting point. The Markha valley can be reached from two
                    different routes: one from Spituk and the other from
                    Chilling. The trek from Spituk is the longest option. It
                    crosses 2 passes, Ganda La (4961m) and Kongmaru La (5260m),
                    before reaching Hemis where the trek ends. Those who don’t
                    have enough time and those looking to avoid the Ganda La
                    pass could start the trek from Chilling, an easier and
                    shorter option.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------nurba wallye */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Nubra valley trek from Phyang to Hunder
                    <br />
                    Best time: mid-July to end of September
                    <br />
                    Duration: 5 days
                    <br />
                    Difficulty: level 3<br />
                    Max altitude: 5438m
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The trek from Phyang to Hunder connects the Indus valley to
                    the Nubra valley. It follows an ancient trade route which
                    was part of the old Silk Road network stretching from India
                    to Central Asia. The highest point on this
                    off-the-beaten-path trek is the Lasermo La pass (5438m)
                    which is covered with ice and snow even in summer. From the
                    pass, there is a wonderful panorama of the Karakoram range.
                    This trek is done in 5 days.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek6} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    After the trek, you will visit the main highlights of the
                    Nubra valley. You will have the chance to ride on a
                    double-humped Bactrian camel at Hunder sand dunes and marvel
                    at the sight of the 32-metre tall statue of the Maitreya
                    Buddha at Diskit monastery. Then, you will head back to Leh
                    via the Khardung La pass.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Sham valley trek
                    <br />
                    Best time: all year round
                    <br />
                    Duration: 3 days
                    <br />
                    Difficulty: level 1<br />
                    Max altitude: 3874m
                    <br />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Often called the “baby trek” because of the easy nature of
                    the trek, there is no doubt the Sham valley trek offers some
                    picturesque views to the trekkers. If you are looking for an
                    easy trek suited for your kids, then this is the one for
                    you. It never goes above 4000 metres and it can be done
                    throughout the year.
                    <br />
                    <br />
                    The trek passes through many small villages where you can
                    spend the nights in homestays and enjoy the authentic local
                    cuisine prepared by the families. Some ancient and famous
                    monasteries at Likir, Rizong, Temisgam, Basgo and Alchi
                    could be visited during and after the trek.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Stok Kangri Trek (6153m)
                    <br />
                    Duration: 4 to 6 days
                    <br />
                    Difficulty: level 5<br />
                    Max altitude: 6153m
                    <br />
                    Best time: mid-June to end of September
                    <br />
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The Stok Kangri peak is the perfect objective for a first
                    mountaineering experience in the Himalayas and for those
                    looking to cross the 6000-metre barrier. Even though the
                    peak is not technical, climbers still have to use all the
                    mountaineering gears such as crampons, axe, rope, etc. The
                    expedition guide will provide a short introductory course on
                    how to use the equipment during the day of acclimatization
                    at the base camp.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek7} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The Stok Kangri expedition is hard on your body and mind but
                    you will realise it was worth it when you reach the summit
                    and enjoy the incredible view of the surrounding mountains.
                    The expedition can be started from Stok or from Spituk, or
                    it can be combined with the Markha trek.
                  </p>
                </Col>
              </Row>

              {/* ------------------------------------------------------------------------------------- */}

              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Lamayuru to Alchi trek
                    <br />
                    Best time: mid-June to mid-October
                    <br />
                    Duration: 5 days
                    <br />
                    Difficulty: level 4<br />
                    Max altitude: 5153m
                    <br />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The western part of Ladakh is not only famous for its
                    centuries-old monasteries but also the Lamayuru to Alchi
                    trek. This five-day trek is dotted with two tough passes -
                    Kongskil La (4948m) and Stakspi La (5153m) - and will push
                    your limit to the maximum, but you will be rewarded with
                    dramatic mountain sceneries. Spend nights at homestays in
                    remote villages if you want to experience local culture
                    closely. Also, visit monasteries before or after the trek.
                  </p>
                </Col>
              </Row>

              {/* ------------------------------------------------------------------------------------- */}

              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Zanskar trek from Padum to Lamayuru
                    <br />
                    Best time: mid-June to beginning of October
                    <br />
                    Duration: 10 days
                    <br />
                    Difficulty: level 4<br />
                    Max altitude: 5009ma
                    <br />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Zanskar is one of the most remote areas in Ladakh and that
                    is where its beauty lies. This 10-day trek from Padum to
                    Lamayuru is the best trek in Zanskar that covers ancient
                    monasteries, small villages and the beautiful landscapes of
                    the Zanskar range.
                    <br />
                    Before the start of the trek, you can visit the famous
                    Phuktal monastery, one of the most isolated monasteries in
                    Zanskar that was built in the 15th century. Zanskar offers
                    sceneries of exceptional beauty, mountains in almost every
                    colour and also its unique culture to the trekkers. The trek
                    is peppered with three main passes: Hanuma La (4724m),
                    Singge La (5009m) and Sir Sir La (4832m).
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Chadar frozen river trek
                    <br />
                    Best time: January and February
                    <br />
                    Duration: 9 days
                    <br />
                    Difficulty: level 3<br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    As Zanskar remains cut off from the rest of the country in
                    winter due to heavy snowfall, the Chadar route acts as a
                    lifeline.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek8} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mt-3">
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Over the years, the Chadar trek has become the "next big
                    thing" in Ladakh and it is easy to see why. Walking on the
                    frozen Zanskar river that stretches 105 km in mind-numbing
                    temperatures (-25 to -35 degrees) tests the limit of the
                    trekkers. Also, there are not too many treks that pass
                    through gorges with frozen waterfalls and that follow a
                    trail of ice that turns into a mirror for the sky. The
                    Chadar trek has received coverage in the form of
                    documentaries, especially in the BBC's 2011 series called
                    “Human Planet”.
                  </p>
                </Col>
              </Row>

              {/* Tour Program : Trekking Program 8 nights 9 days
               */}

              <Row className="mt-5">
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Tour Program : Trekking Program 8 nights 9 days
                    <br />
                    Trek Route : Leh - Tilad Do - Chilling - Tilad Do to Shingra
                    Koma - Tibb cave to Naerak camp - Tibb Cave
                  </p>
                </Col>
              </Row>

              {/* Itenarty
               */}

              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Detail Itinerary
                    <br />
                    Day 1 - Fly to Leh : Pickup from Airport and Drop to The
                    Guest house
                    <br />
                    Overnight stay at the hotel/guest house.
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Keep your woollen cap and gloves handy.
                    <br />
                    Flying over miles and miles of heavily snowed mountains will
                    tell you just why it's impossible to reach Ladakh by road in
                    the winters. The moment you land at the Leh airport, one of
                    the highest airports in the world, you will feel the fresh
                    and crisp air, and a drastic drop in the temperature. The
                    town has just begun shedding off the thick winter slumber
                    and is slowly coming to life. Taking a stroll through the
                    Leh market, you will find that very few shops and eateries
                    in the town are open for business; but if required, some
                    last minute-shopping for the trek can be done here. The
                    chill in the air will serve only as a preview of the
                    temperatures during this winter trek. The night is spent in
                    a copy guest house.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 2 - Drive to Tilad Do via Chilling (11,400 to 10,390 ft)
                    <br />
                    Climb to Shanti Stupa and Witness the Sunrise
                    <br />
                    Trek to Tilat Sumdo ( 1-2 Hours approx.)
                    <br />
                    Overnight stay at camps
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    You can start the morning by witnessing the sunrise from the
                    Shanti Stupa , from where you can get a panoramic view of
                    the entire town of Leh and its surroundings. You can also
                    see the peak of Stok Kangri almost beckoning you to climb
                    it.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek9} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mt-3">
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    But today's agenda includes a very picturesque and thrilling
                    long-drive to Chilling. The roller-coaster ride takes you
                    along the Indus to the village of Nimu, where the Zanskar
                    converges into Indus. After stopping at this confluence
                    point to take in the view, the drive continues and the road
                    begins descending right into the river valley. There are
                    quite a few heart-stopping moments as the vehicle negotiates
                    the steep hairpin bends over snow covered road. The road
                    goes past Chilling to Tilad Do. Among these mammoth
                    mountains, the feeling of insignificance of one's being does
                    not escape anyone. To reach the camp-site, you have to cross
                    the frozen Zanskar river and this is when you take your
                    first steps on the Chadar. It takes a while to get a hang of
                    walking on the ice sheet. As the evening comes, the team
                    pitches tents on the banks of the Zanskar, and hands out
                    thick warm sleeping bags. To get some exercise and keep
                    warm, you can climb up the nearby mountains and get a good
                    look at the frozen river. As the sun sets, it drags the
                    temperature down with it, and the sleeping bags in the tents
                    look quite inviting.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 3 - Tilad Do to Shingra Koma (10,390 to 10,550 ft. Trek
                    10 kms)
                    <br />
                    Reach Gyalpo via Shingra Koma , Overnight stay in camps at
                    Gyalpo
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    The biggest challenge of this day, and all the remaining
                    days, is to get out of the warm toasty sleeping bags and go
                    out into the giant freezer outside, but the steaming cups of
                    tea being served provide ample motivation.
                    <br />
                    <br />
                    Today is a long walking session, about 10 km, on the Chadar.
                    The trek leaders will give a briefing about the day, and
                    give tips on how to walk on the Chadar. Depending on the
                    weather, the Chadar can assume various forms. It can be a
                    cold and hard surface of slippery ice, or it can have a
                    dusting of snow over it, providing a good grip. The ice can
                    be thick or thin, and its best to keep closely following the
                    guide. The locals, it appears, have a sixth sense as to
                    where exactly to step on the Chadar. At places, it might be
                    necessary to wade through ankle deep water, or climb on the
                    cliffs at the side of the river. Eventually you get used to
                    walking on the Chadar, which looks like a cross between
                    gliding and skating. Mid way to Gyalpo, the porter-cum-cooks
                    prepare hot bowlfuls of soupy noodles for lunch. It is
                    important to keep up a decent level of fluid intake. Nothing
                    makes you adapt to the conditions faster than nicely
                    circulating blood in your system.
                    <br />
                    <br />
                    After passing Shingra koma, you reach the camp-site for the
                    day at Gyalpo. The Gyalpo campsite is at a bend in the
                    river, surrounded by high peaks and walls of rock-faces
                    which almost look man-made. All that is left to do is to
                    soak in the views, have an early dinner and call it a day.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 4 - Shingra Koma to Tibb Cave (10,550 to 10,760 ft).
                    <br />
                    Trek through Zanskar (15 km approx) Overnight stay in tents
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    This day follows the same early morning routine: bed tea at
                    7, breakfast at 8, and quick-march at 8:30. Have a good
                    breakfast, as today is the longest walk of the day - almost
                    15 km. But that is nothing to worry about, all the walking
                    keeps you incredibly warm.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek10} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mt-3">
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Today's walk through the deep ravines of the Zanskar is
                    stunning. The sublime walls of the mountains on either sides
                    of the river keep the sunlight away from the Chadar for most
                    part of the day. There are numerous caves in these
                    mountains, big and small. Today is the day when you can see
                    frozen waterfalls, some higher than the others. As the fable
                    goes, centuries ago the local villagers faced a dearth of
                    water and went to Tibet to plead for water. They were given
                    a box which they were to open once they reached the village,
                    and not any time sooner. But curiosity got the better out of
                    them, and when they opened the box, two fishes jumped out of
                    it, creating two huge waterfalls. One of those waterfalls,
                    miraculously, is not iced-up even in such freezing cold. The
                    rocks below this waterfall are covered with moss, and this
                    is the only greenery you'll see on this trek. At Tibb, there
                    is a big cave. This cave is the dwelling of all the porters,
                    and they welcome you warm-heartedly in here. The highlight
                    of the day is to share a cup of butter-tea with them!
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 5 - Tibb cave to Naerak camp (10,760 to 11,150 ft. Trek
                    12.5 kms)
                    <br />
                    Overnight stay at camps in Nyerak Village
                    <br />
                    Attraction Frozen waterfall
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Today is the most spectacular of all days on this trek.
                    Walking on the river you cross deep gorges and reach a point
                    where Juniper trees are covered with prayer flags. The
                    porters give you a twig of this tree tied with a piece of
                    prayer flag as a badge of good-luck and good-health. A few
                    steps away stands the mother-of-all frozen waterfalls - a
                    huge instance of suspended animation several feet tall and
                    equally wide. You can see dozens of colours in this enormous
                    ice structure as sunlight plays off its surface. Right next
                    to the waterfall is a bridge across the river which is a
                    part of the summer-time road from Zanskar to Leh. The Naerak
                    village lies several feet above the river, a vigorous
                    hour-long trek can take you to the village if you want to
                    see the life of a Zanskari. The camp is set up close to the
                    river.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 6 - Naerak to Tibb Cave (11,150 to 10,760 ft Trek 12.5
                    kms) Stay in tent
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Today you start the return journey and go back to the Tibb
                    cave. And if you thought that it was just a matter of
                    retracing your steps back to Chilling, you can think again.
                    The Zanskar River reacts to the slightest change of
                    temperature, and constantly keeps repackaging itself. The
                    Chadar would have assumed a completely new form, and will it
                    will almost be impossible to say whether you have been here
                    before.
                  </p>
                </Col>
                <Col lg={6}>
                  <Image src={imgTrek11} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mt-3">
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    On the way, you can meet a lot of locals wearing their
                    traditional woollen Gonchas - some of them monks who are
                    hiking from the Lingshed Monastery to Leh, some of them
                    young students accompanied by their parents returning to
                    their schools in Leh after the winter vacation. Watching the
                    locals negotiate the Chadar is a fascinating sight. They are
                    suitably adept to the climate and seem very much at home in
                    the sub-zero temperature and the biting cold winds. Nothing
                    wipes the smiles off their faces and dampens the warmth of
                    their spirit.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 7 - Tibb Cave to Shingra Koma (10,760 to 10,550 ft. Trek
                    15 kms) Stay in tent
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Starting from Tibb, go up to Gyalpo today. The high walls of
                    mountains rising from the sides of the river almost look
                    like castle walls. You can see several trails of pug-marks
                    all along the trek - footprints belonging to foxes, ibex or
                    snow-leopards. You would be lucky to view a snow-leopard;
                    but you can almost be sure that you are being watched by one
                    all the time.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 8 - Shingra Koma to Tilad Do and drive to Leh (10,550 to
                    10,390 and drive to 11400 ft. Trek 10 kms)
                    <br />
                    Drive 65 kms to Leh
                    <br />
                    Last day of Chadar Trek
                    <br />
                    Check-in into the guesthouse
                    <br />
                    Dinner , Overnight stay in the guesthouse
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    This is the last day of treading on the Chadar - the last
                    chance to immerse in the pristine beauty of the frozen
                    river. Once you reach Tilad Do, it is time to say good-bye
                    to the Zanskar and the Zanskaries. The team and porters will
                    take good care of you and make this trek one of the most
                    memorable experiences for you. Tonight you get to sleep in a
                    warm guest-house again.
                  </p>
                </Col>
              </Row>

              {/* ----------------------------------------------------------------------- */}
              <Row>
                <Col lg={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#444",
                      fontWeight: "700",
                    }}
                  >
                    Day 9 - Leh Departure
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    After Breakfast Check out Guest , Continue with your return
                    journey
                  </p>
                </Col>
              </Row>
              {/* ------------------------keeep above------------------------------ */}
            </Col>
            {/* ---------------------------------------------Boook Now--------------------------------- */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
