import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import img1 from "../../../../img/Srinagar1/dallake-view.jpg";
import img2 from "../../../../img/gulmarg-gondla.jpg";
import img3 from "../../../../img/leh/lamayuru1.jpg";
import img4 from "../../../../img/thiksey-monastery.jpg";
import img5 from "../../../../img/huder-camel-ride.jpg";
import img6 from "../../../../img/hemis-monastery.jpg";
import img7 from "../../../../img/sonamarg-img.jpg";

export default class Tour_9 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">
              Discover Ladakh Himalayan Tour & Manali Drop
            </p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 9 Nights / 10 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Gulmarg
              - Srinagar - Kargil - Leh - Khardungla - Nubra - Turtuk - Hunder -
              Pangong - Leh - Kargil - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 1: Arrival Srinagar Local Sightseeing
                  </p>
                  <p className="PackageDescrip">
                    Pickup from Srinagar Airport and drop to the Hotel / Hotels,
                    after freshup proceed for city tour of Srinagar. The world
                    famous Mughal Gardens visiting the Nishat Bagh (The garden
                    of pleasure) & Shalimar Bagh (Abode of love), & The Chasme
                    Shahi Garden, & Shankaracharya Temple. In the evening return
                    to Houseboat , Overnight at Hotel or Houseboat.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 2 : Srinagar - Gulmarg - Srinagar (8700 Feet, 52 kms,
                    1.5 hrs One way)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast, drive to Gulmarg called as “Meadow of
                    Flowers” . Gulmarg is 52 Kms from Srinagar at an altitude of
                    8700-ft.
                    <br />
                    Gulmarg also referred as Meadow of Flowers is a famous Ski
                    Resort & the world’s highest 18 hole Golf Course. The place
                    was prominent during the British time.
                    <br />
                    You can enjoy a Pony ride (Optional At your own cost).
                    <br />
                    <br />
                    <br />
                    Experience Gondola ride (up to first level only). Gulmarg
                    has one of the best Ski slopes in the world and highest golf
                    course of the world with 18 holes. After sightseeing back to
                    Srinagar, Check in Hotel and Overnight stay in Hotel.
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 3 : Srinagar- Zojila- Drass- Kargil ( 205 kms / 5 - 6
                    hours)
                  </p>
                  <p className="PackageDescrip">
                    Pickup from Sirnagar Around 11 AM - and drive to Kargil via
                    Sonamarg - (Lunch on the Way) driving through Sonamarg and
                    crossing the Zoji-la pass (11,500-ft. / 3,505 mtrs)
                    en-route. On the way, there are halts at Sonamarg a pristine
                    valley with lush views and snow-clad peak; Zo jila Pass (a
                    high altitude pass); Drass Village- the world's 2nd coldest
                    inhabitant place. Here you visit War memorial hall and
                    Museum- also known as Vijaypath.
                    <br />
                    The memorial was constructed by the Indian Army in the
                    memory of the soldiers and officers of the Indian Army who
                    laid their lives in Kargil War. Overnight in the hotel at
                    Kargil.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 4: Kargil - Lamayuru- via Alchi Monastery (Indus Zanskar
                    River Conference ) - Magnetic Hill - Leh ( 235 kms / 6-7
                    hours )
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast check out hotel and drive to Leh, enroute
                    viewing / visiting the dramatically located Lamayuru
                    monastery and the adjoining "Moonland" hills forming its
                    backdrop. after visiting the 11th century Alchi oldest
                    surviving monastic heritage of Ladakh - a UNESCO listed
                    heritage site. Driving further on along the Indus, visit
                    Sanagm (confluence of Indus & Zanskar Rivers), (River
                    RAFTING POINT - you can rafting here 2 or 3 hrs) after drive
                    to Magnetic Point, Pathar Sahib Gurudwara & Hall of Fame
                    (museum of military heritage).
                    <br />
                    <br />
                    Magnetic hill to marvel at the amazing natural wonder. Also
                    pay respects to Pathar Sahib Gurudwara- constructed in
                    memory of Guru Nanak Dev ji- the founder of Sikh religion
                    and the first guru . Continue your road journey. On arrival
                    in Leh, visit the Hall of Fame Museum a war memorial by the
                    Indian Army that was founded in the memory of Indian
                    soldiers who laid lives in Indo-Pak wars. Complete the
                    check-in formalities at your hotel. Overnight stay in Leh.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img3} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 5 : Leh Full Day - Monastery Sightseeing
                  </p>
                  <p className="PackageDescrip">
                    After morning break fast visiting the most important
                    monasteries of Ladakh - Shey Palace, Thiksey Gompa and Hemis
                    monastery . <br />
                    First we visit the ruined Shey Palace, once the summer
                    palace of the pre-Tibetan kings. The palace monastery houses
                    a large, bronze, blue haired Buddha statue and next stop at
                    superbly situated, 16th century, Thiksey Gompa, which is
                    perched on a hill top and continue to visit Hemis Monastery
                    (5 km from Kharu) is also on the cards. Hemis Monastery is
                    the largest and richest monastery complex in Ladakh. after
                    back to Leh Enroute White Lotus School (3 Idiot School)
                    after sightseeing back to Hotel, Overnight stay at ho
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img4} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 6 : Leh - Khardungla - Nubra - Hunder (Apporx 5 hrs)
                  </p>
                  <p className="PackageDescrip">
                    Early morning proceed to Nubra Valley via the Worlds Highest
                    Motorable road (5602 Mts) Khardungla pass. Khardung la Pass
                    (18,380ft above sea level): <br />
                    It is not very often that one gets to drive on a road at an
                    altitude of 18,380 feet. That is where Khardungla Pass,
                    meaning the Pass of Lower Castle, is located on the way from
                    Leh to the Nubra Valley in Ladakh. <br /> <br />
                    It is the highest motorable road in the world as signs put
                    up by the Border Roads Organization proudly proclaim. Post
                    the Diskit Monastery, proceed for backward journey to Leh
                    Arrive Nubra Valley and proceed for a visit to the White
                    Sand Dunes at Hunder. You can also enjoy a camel ride here
                    (Camel ride on direct payment basis). <br /> <br />
                    Nubra Valley: Popularly called the orchard of Ladakh, Nubra
                    valley offers a graceful view of yellow and pink wild roses
                    in summer and a carpet of wild lavender in the post-monsoon
                    season. You will definitely be overwhelmed to see the
                    Bactrian Camels (Shaggy double hump Camel) around sand
                    dunes. Thereafter, check in at comfortable Camp or Hotel.
                    Evening at leisure. Dinner & overnight stay at the camp or
                    hotel.
                  </p>
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 7 : Hunder - Turtuk - Hunder (Approx 2 - 3 hrs)
                  </p>
                  <p className="PackageDescrip">
                    After early Breakfast, move towards Highest Motorable Road
                    of The World, KhardungLa Pass. Have a cup of Tea there, get
                    yourself clicked with the Khardungla Signboard by BRO &
                    proceed towards Turtuk- the Land of Baltis, which was under
                    control of Pakistan before 1971 War between the two nations.
                    You may like to visit 16 century Mosque and Minaret, Royal
                    house of Yabgo dynasty, Ruin palace of Pun Khar, Historic
                    polo ground. Overnight stay at Homestay /Camp.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img5} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 8 : Hunder - Nubra Valley to Pangong Lake via Shayok
                  </p>
                  <p className="PackageDescrip">
                    After breakfast at hotel we drive to next destination
                    pangong lake via Shyok and onto Tang Tse and Spangmik (
                    Pangong).
                    <br /> <br />
                    It's a short cut and it's a beautiful route. The road
                    follows the Shyok river, and climbs up a mountain before
                    finally descending into Shayok village. It took us less than
                    an hour to reach Shayok, and we were delighted at the sight
                    of the rather big village surrounded by barren mountains,
                    the entire setting being very picturesque. Afternoon we
                    reach at Pangong lake and enjoy your day at pangong lake.
                    Overnight stay at camp.
                  </p>
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 9 : Pangong - Changla Pass - Hemis/Thiksey - Leh (By
                    Road 165Km/approx 5-6hrs)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast, you will leave Pangong/Tangtse and get back
                    on the Leh route across Chang La. The Chang La is a high
                    mountain pass in Ladakh at an elevation of 5,391.3024 m or
                    17,688.000 ft in the Ladakh Ranges between Leh and the Shyok
                    River valley. It is one of the highest motor-able road in
                    the world. Chang La is approached from Leh via Kharu and
                    Sakti village by an asphalt road. <br /> <br />
                    Upon reaching Karu, you will drive across the Indus valley
                    and visit the famous Hemis Monastery, the biggest monastery
                    in Ladakh. Continue, drive to Leh, enroute visiting the
                    picturesque Hemis and Thiksey monasteries. Overnight stay at
                    a hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img6} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 10 : Leh Sangam - Magnetic Hill - Lamayuru - Kargil (7 -
                    8 hrs.)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Hotel and drive to Kargil, on the
                    way sightseeing, Hall of Fame, Pathar Sahib Gurudwara,
                    Magnetic Hill, Sangam (Confluence of Zanskar & Indus
                    River).after drive to Lamayuru, visit the dramatically
                    located Lamayuru monastery and the adjoining 'Moonland'
                    hills. Later drive towards Kargil enroute viewing the
                    9-Mtrs. tall rock sculpture of the Maitreya Buddha at Mulbek
                    (7th - 8th century AD). Overnight in hotel at Kargil.
                  </p>
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 11 : Kargil - Sonamarg - Srinagar (Approx 7 Hrs)
                  </p>
                  <p className="PackageDescrip">
                    Early departure for Srinagar driving through Drass (famous
                    as the second coldest inhabited place in the world) and
                    crossing the Zoji-la Pass (11,500-ft. / 3,505 m) to reach
                    Sonamarg from where it's a smooth 2-hour drive to Srinagar
                    through the beautiful Kashmir countryside.
                    <br />
                    On Arrival Sringar, and half day srinagar sightseeng, Mughal
                    garden, Pari Mahal and Shankra Chariya Temple, after check
                    in Hotel, Overnight at Srinagar.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img7} alt="" fluid />
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Day 12 : Srinagar Departure</p>
                  <p className="PackageDescrip">
                    After breakfast check out hotel and transfer to Srinagar
                    airport for your onward flight back home.
                  </p>
                </Col>
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <hr></hr>

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Above package includes:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Pickup & Drop Srinagar Airport.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - All Stay with 3 Star Hotel.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Deluxe Houseboat in Dal Lake.
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Double Sharing Rooms with Breakfast & Dinner.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Nubra & Pangong Lake Permit{" "}
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - All Sightseeing by Personal Car.{" "}
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - All present applicable taxes.{" "}
                  </p>
                </Col>
              </Row>

              {/* --------------------------------------------------Exclusion--------------- */}

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Exclusions:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Meal Plan (Lunch)
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Horse Ride
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Monument Fee
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Double Sharing Rooms with Breakfast & Dinner.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Personal Expenses like Telephone, Fax, Internet, Laundry
                    etc
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Any Expenses related to portage at hotels, airports, tips,
                    room service, insurance, wine, boating charge, guide,
                    entrance fee, porters, palanquins, or ponies{" "}
                  </p>
                </Col>
              </Row>
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
