import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import houseboat from "../../../../img/houseboatrosemerry.jpg";
import Button from "react-bootstrap/Button";
import "./index.css";
import Image from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";
export default class HouseBoat extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <>
        <div className="houseboat">
          <Row>
            <Col lg={6} md={12} className="right-container">
              <Image src={houseboat} alt="House Boat" fluid />
            </Col>

            <Col lg={6} md={12} className="right-container">
              <div className="houseboat-text">
                <h3>HOUSEBOAT ROSEMERY - DAL LAKE</h3>
                <p className="tagline">
                  A SUPER DELUXE HOUSEBOATS IN DAL LAKE, SRINAGAR, KASHMIR.
                </p>
                <Button className="contact-button" as={Link} to="/houseboat">
                  CONTACT US
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
