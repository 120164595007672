import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionLehLadakh";
import img1 from "../../../../img/leh/leh-city.jpg";
import img2 from "../../../../img/leh/magnetichill.jpg";
import img3 from "../../../../img/pangong-img.jpg";
import LadakhCarousal from "../CarousalLadakh";

export default class Ladakh_Tour_1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <PackageTitleDetails
            title="Ladakh Shyam Valley with Pangong Lake"
            nights={3}
            day={4}
            destination={["Leh", "Shyam Valley", "Pangong Excursion Trip"]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrive Leh - day free to relax and acclimatize"
                  text={
                    <>
                      On your arrival at Leh airport our representative will
                      meet & greet and transfer you to the Hotel.
                      <br />
                      We recommend you completely relax for the rest of the day
                      to enable your body to acclimatize to the rarefied air at
                      the high altitude. Leh is situated at the height of 11563
                      feet. Lunch and dinner is served at the hotel. Overnight
                      stay at the hotel.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 2 : Leh Shyam Valley Sightseeing"
                  text={
                    <>
                      In the morning, after breakfast, drive to Sangam
                      (Confluence). En route visit Magnetic Hill and Gurudwara
                      Patthar Sahib. Visit Confluence of Zanskar and Indus
                      River.
                      <br /> <br />
                      The Sangam (Confluence): Confluence of the Indus and
                      Zanskar Rivers lies in Ladakh at Nimu. The Indus runs
                      through Ladakh all along by your side serving as a
                      constant companion and you feel a sense of respect for
                      this ifeline of the people of Ladakh, especially since
                      Ladakh experiences rainfall of only 2 to 3 inches
                      throughout the year. Both Indus and Zanskar Rivers merge
                      with each other as if they had flown all along just for
                      that purpose. From this point on, beyond this Confluence
                      of the two, the waters are regarded as the Indus River.
                    </>
                  }
                />
              </Row>
              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  text={
                    <>
                      Magnetic Hill: Can anyone ever imagine the movement of a
                      vehicle up a steep mountain with its engines off- Reach
                      Magnetic Hill to experience this phenomenon which sounds
                      unbelievable.
                      <br />
                      <br />
                      Gurudwara Pathhar Sahib: Gurudwara Pathar Sahib is a
                      beautiful Gurudwara constructed in the memory of Guru
                      Nanak, about 25 miles away from Leh, on the Leh-Kargil
                      road, 12000 ft above sea level. The Gurudwara was built in
                      1517 to commemorate the visit to the Ladakh region of Guru
                      Nanak Dev, the founder Guru of the Sikh faith. On your way
                      back, visit Ladakh Hall of fame
                      <br />
                      <br />
                      Hall of Fame: Hall of Fame in Leh is a must visit for
                      every individual of India. This is constructed by the
                      Indian Army. You will find memorabilia, eminent defence
                      personalities biographies, images and weapons used during
                      Kargil war and belongings of enemy soldiers found at war
                      site. After sightseeing evening back to Leh and Overnight
                      stay in Hotel
                    </>
                  }
                  img={img2}
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Leh - Pangong Lake (Approx 6 hrs one way)"
                  text={
                    <>
                      Enjoy an early breakfast at the hotel. After breakfast
                      leave for Pangong Lake.
                      <br />
                      On the way to Pangong Lake we will cross Chang la which is
                      the third highest motor-able road in the world (5486
                      meters). Enjoy the beautiful panoramic view of the
                      villages below the pass.
                      <br />
                      <br />
                      Arrive Pangong and enjoy the landscape and back drop of
                      the Lake. Pangong is famous for mirror calm blue brackish
                      waters. The lake is 5-6 kms wide and over 144 kms in
                      length, with half of it running on the other side of the
                      "INDO CHINA BORDER". One rarely feels so close to nature
                      and environment and the scenery is unforgettable. Lake is
                      also a breeding ground for a variety of birds.
                      <br />
                      Lunch will be served at Pangong.
                      <br />
                      At around 2:30 p.m. in the afternoon we start our drive
                      back to Leh. Dinner and overnight stay at the hotel.
                    </>
                  }
                  img={img3} //Image
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 4 : Departure from Leh"
                  text={
                    <>
                      Breakfast at hotel. Check out and transfer to the airport
                      for your flight to onward destination.
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
