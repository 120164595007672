import React from "react";
import Col from "react-bootstrap/Col";
export default function PackageOnlyText(props) {
  return (
    <>
      <Col lg={12} md={6}>
        <p className="titlePackage">{props.title}</p>
        <p className="PackageDescrip">{props.text}</p>
      </Col>
    </>
  );
}
