import React, { Component } from "react";
import NewCarousal from "../../../components/Carousel";
import PackageDisplay from "../../../components/Package";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import TailourForm from "../../../components/TailourMadeForm";
import Himachal1 from "../../../img/himachal/bus-manali.jpg";
import Himachal2 from "../../../img/himachal/shimla3.jpg";
import Himachal3 from "../../../img/himachal/golden-temple-tour.jpg";
import Himachal4 from "../../../img/himachal/ladakh-bike-tour.jpg";
import Himachal5 from "../../../img/himachal/pangong-img.jpg";
import Himachal6 from "../../../img/himachal/manali-leh-overland-jeepsafari.jpg";
import CarousalHimachal from "./CarousalHimachal";

export default class HimachalHolidays extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalHimachal />
        <Container>
          <div className="JK_Title">Himachal Holiday Packages</div>
        </Container>
        <Container className="mt-4 text-center">
          <Row>
            <PackageDisplay
              link="/himachal/Tour_1"
              title="Delhi Manali by Luxury Bus Tour"
              image={Himachal1}
              nights={5}
              day={6}
              destination={[
                "Delhi",
                "Manali by Bus ",
                "Solang Valley",
                "Rohtang Pass",
                "Delhi",
              ]}
            />
            <PackageDisplay
              link="/himachal/Tour_2"
              title="Shimla Manali by Car Tour"
              image={Himachal2}
              nights={6}
              day={7}
              destination={[
                "Delhi",
                "Shimla",
                "Kullu",
                "Manali",
                "Solang Valley / Rohtang Pass",
                "Manikaran",
                "Chandigarh",
                "Delhi",
              ]}
            />
            <PackageDisplay
              link="/himachal/Tour_3"
              title="Shimla Manali Dharamshala With Golden Temple Tour"
              image={Himachal3}
              nights={7}
              day={8}
              destination={[
                "Delhi",
                "Shimla",
                "Manali",
                "Dharamsala",
                "Amritsar",
                "Delhi",
              ]}
            />
            <PackageDisplay
              link="/himachal/Tour_4"
              title="Manali to Ladakh Bike Trip"
              image={Himachal4}
              nights={10}
              day={11}
              destination={[
                "Manali",
                "Jispa",
                "Leh",
                "Sham Valley",
                "Leh",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Pangong",
                "Leh",
                "Tsomorori",
                "Jispa",
                "Manali",
              ]}
            />
            <PackageDisplay
              link="/himachal/Tour_5"
              title="Discover Ladakh Himalayan Tour & Manali Drop"
              image={Himachal5}
              nights={9}
              day={10}
              destination={[
                "Leh",
                "Shyam Valley",
                "Kargil",
                "Leh",
                "Khardungla",
                "Nubra",
                "Hunder",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Leh",
                "Tsomorori",
                "Jispa",
                "Manali",
              ]}
            />

            <PackageDisplay
              link="/himachal/Tour_6"
              title="Kashmir Ladakh Manali Overland Tour"
              image={Himachal6}
              nights={11}
              day={12}
              destination={[
                "Srinagar",
                "Kargil",
                "Leh",
                "Monastery Leh",
                "Khardungla",
                "Nubra",
                "Turtuk",
                "Hunder",
                "Pangong",
                "Leh",
                "Tsomorori Lake",
                "Jispa",
                "Manali",
              ]}
            />
          </Row>
          <Container className="mt-5">
            <TailourForm />
          </Container>
        </Container>
      </>
    );
  }
}
