import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import InclusionExclusion from "../../../../components/ExclusionInclusion";
import img1 from "../../../../img/dallake-winter-view.jpg";
import img2 from "../../../../img/pahalgam-view.jpg";
import img3 from "../../../../img/gulmarg-gondla.jpg";

export default class Tour_6 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">Kashmir Holiday Package</p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 6 Nights / 7 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Sonamarg
              - Doodhpathri - Pahalgam - Gulmarg - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">Day 01: Srinagar arrival</p>
                  <p className="PackageDescrip">
                    Our services will start with your arrival at Srinagar
                    airport, you will be met by our representative and transfer
                    to the Houseboats, check in Houseboats, after freshup visit
                    to Mughal Gardens consists of mainly of three gardens
                    -Chashme shahi garden -Nishat Garden -Shalimar Garden
                    .Shankaracharya Temple Takht-e-Suleiman hill, the
                    Shankaracharya Temple is dedicated to Lord Shiva. It is
                    supposed to be the oldest shrine of the Kashmir valley,
                    Hazratbal Shrine- Situated opposite the Nishat Bagh on the
                    banks of Dal Lake,Parimahal. In the afternoon Shikara ride
                    on The Dal Lake to enjoy the beauty that God has graced this
                    city, after sightseeing check in Houseboats and Overnight
                    stay
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 02 : Srinagar - Doodhpatri - Srinagar (On way Approx 2
                    hrs)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast drive to Doodhpatri Full day excursion to
                    Doodhpathri is a beautiful meadow with emerald grass that
                    looks like a green carpet. A beautiful river dancing and
                    reverberating with the wind flows in the middle of the
                    meadow, lending it a splendid charm and vibrancy. The place
                    looks very beautiful and pleasant. The green grass under the
                    feet is really wonderful.
                    <br />
                    <br />
                    Doodhpathri itself is actually a tourist attraction that you
                    see while visiting Kashmir. As I mentioned previously, there
                    is nothing here really except for a huge green ground
                    sitting in the middle of mighty peaks all around. There are
                    no big tourist attractions or adventure sports happening
                    here. It is just natural beauty at its best with tranquil
                    enough to soothe your soul. There are however a few places
                    near Doodhpathri that too are worth mentioned and visiting
                    after sightseeing back to Srinagar and Overnight stay in
                    Hotel.
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 03 : Srinagar to Sonmarg Tour (Day Trip) 196 Kms
                  </p>

                  <p className="PackageDescrip">
                    Full day excursion to Sonmarg Tour which is the most
                    beautiful drive from Srinagar. On the way we stop at many
                    beautiful spots. Continue drive to Sonmarg 2690 Mtrs (Meadow
                    of Gold). One can ride on horse to visit Thajiwas Glacier
                    where snow remains round the year and Sonmarg is known as
                    Gateway of Ladakh . In the evening return to Houseboat.
                    Overnight at Hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 3----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 04 : Srinagar to Pahalgam (Approx 3 Hours Drive- One
                    Way)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast check out Houseboats and drive to Pahalgam,
                    Pahalgam (2133 mts ) also known as ‘The valley of Shepherds
                    or Trekker's Paradise. Pahalgam is the most famous place for
                    Indian Film Industry. On the way visit Awantipura ruins,
                    Saffron fields One of the most beautiful places on earth,
                    the town of Pahalgam offers breath-taking views.
                    <br />
                    <br />
                    On Arrival Pahalgam take local Pahalgam Transport and Visit
                    to Aru Valley, Betab Valley & Chandanwari Sightseeing,
                    Pahalgam is also the entry point for the famous Amarnath
                    Cave, a holy site for Hindus. After Sightseeing check in
                    Hotel & Overnight in hotel in Pahalgam.
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 05 : Pahalgam to Gulmarg (Approx 5 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Hotel and drive from Pahalgam to
                    Gulmarg by car . (8700 ft i.e. 2652 mts ), Discovered by the
                    Kashmiri romantic poet in the 16th century who was inspired
                    with its grassy slopes covered with wild flowers. In winter
                    Gulmarg looks more beautiful due to heavy snow fall. And
                    turns into India's premier Ski resort in winter. The Gondola
                    Cable Car (optional) offers rides to the upland meadows of
                    Kongdori and beyond to the top of Apharwat range to a height
                    of 14000 ft for viewing the Himalayan Peaks in summer and
                    for downhill skiing in winter. One can have Gondola ride
                    (cable car directly payable) from Gulmarg to Khalinmarg .
                    Overnight in hotel in Gulmarg.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img3} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 4----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 06 : Gulmarg to Srinagar (Approx 2 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Hotel and drive back to Srinagar ,
                    Later on proceed to famous Nishat Garden known as ‘Garden of
                    pleasure' built in 16th century (1633) by Mughal King Asif
                    Khan. Then visiting Shalimar Garden known as ‘Garden of
                    love' built in 16th century (1619) by Mughal King Jehangir
                    for his beloved wife Noor Jehan or ( Mehr -un- Nissa ) ,
                    Evening Free and shopping, after drop to the Hotel and
                    Overnight stay in Hotel.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Day 07 : Srinagar Departure</p>
                  <p className="PackageDescrip">
                    After breakfast check out hotel and visit to Shankaracharya
                    Temple built on the top of the mountain overlooking Dal Lake
                    and then drive towards Airport for onward destination.
                  </p>
                </Col>
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusion />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
