import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import InclusionExclusion from "../../../../components/ExclusionInclusion";
import img1 from "../../../../img/houseboat-im.jpg";
import img2 from "../../../../img/sonamarg-img.jpg";
import img3 from "../../../../img/Srinagar1/betaab-valley.jpg";
import img4 from "../../../../img/gulmarg-gondla.jpg";

export default class Tour_7 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">Discover Kashmir Himalaya Tour</p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 7 Nights / 8 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Sonamarg
              - Yusmarg - Doodhpathri - Pahalgam - Gulmarg - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">Day 01: Srinagar arrival</p>
                  <p className="PackageDescrip">
                    On arrival at Srinagar, you will be met by our
                    representative and transfer you to Houseboat. In the evening
                    Sikara Ride enjoy the beutiful Dal Lake. You will get a view
                    of some very interesting places around the Lake. After
                    sightseeing back to Houseboat, Overnight stay in Houseboat.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 02 : Srinagar to Sonamarg Tour (Day Trip) 196 Kms
                  </p>
                  <p className="PackageDescrip">
                    Full day excursion to Sonmarg Tour which is the most
                    beautiful drive from Srinagar. On the way we stop at many
                    beautiful spots. Continue drive to Sonmarg 2690 Mtrs (Meadow
                    of Gold). One can ride on horse to visit Thajiwas Glacier
                    where snow remains round the year and Sonmarg is known as
                    Gateway of Ladakh . In the evening return to Houseboat.
                    Overnight at Hotel.
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 03 : Srinagar - Yusmarg - Srinagar (Around 3 hrs On way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast drive to Yusmarg, Full day excursion to
                    Yusmarg (Meadow of Flowers) enroute visit of Charar sheriff
                    shrine village and town. After noon visit to Doodh Ganga by
                    Pony. Yusmarg, located at the distance of 47 kilometers from
                    the Srinagar, near the Badgam district, Jammu & Kashmir,
                    India, lies in the heart Mountains of south-west of
                    Srinagar. It is a perfect picnic spot and the Nilnag Lake
                    can be visited from here. Taxis can be hired at Srinagar for
                    the trip to yusmarg. The tourists can enjoy pony ride in and
                    around this place. Yousmarg is reputed for having some
                    unique spring flowers. It also has some of the highest peaks
                    in Pir-Panjal range. After Sightseeing return back to
                    Srinagar. and Overnight stay in Hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 3----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 04 : Srinagar - Doodhpatri - Srinagar (On way Approx 2
                    hrs)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast drive to Doodhpatri Full day excursion to
                    Doodhpathri is a beautiful meadow with emerald grass that
                    looks like a green carpet. A beautiful river dancing and
                    reverberating with the wind flows in the middle of the
                    meadow, lending it a splendid charm and vibrancy. The place
                    looks very beautiful and pleasant. The green grass under the
                    feet is really wonderful.
                    <br />
                    <br />
                    Doodhpathri itself is actually a tourist attraction that you
                    see while visiting Kashmir. As I mentioned previously, there
                    is nothing here really except for a huge green ground
                    sitting in the middle of mighty peaks all around. There are
                    no big tourist attractions or adventure sports happening
                    here. It is just natural beauty at its best with tranquil
                    enough to soothe your soul. There are however a few places
                    near Doodhpathri that too are worth mentioned and visiting
                    after sightseeing back to Srinagar and Overnight stay in
                    Hotel.
                  </p>
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 05 : Srinagar to Pahalgam (Approx 3 Hours Drive- One
                    Way)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast check out Houseboats and drive to Pahalgam,
                    Pahalgam (2133 mts ) also known as ‘The valley of Shepherds
                    or Trekker's Paradise. Pahalgam is the most famous place for
                    Indian Film Industry. On the way visit Awantipura ruins,
                    Saffron fields One of the most beautiful places on earth,
                    the town of Pahalgam offers breath-taking views.
                    <br />
                    On Arrival Pahalgam take local Pahalgam Transport and Visit
                    to Aru Valley, Betab Valley & Chandanwari Sightseeing,
                    Pahalgam is also the entry point for the famous Amarnath
                    Cave, a holy site for Hindus. After Sightseeing check in
                    Hotel & Overnight in hotel in Pahalgam.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img3} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 06 : Pahalgam to Gulmarg (Approx 5 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Hotel and drive from Pahalgam to
                    Gulmarg by car . (8700 ft i.e. 2652 mts ), Discovered by the
                    Kashmiri romantic poet in the 16th century who was inspired
                    with its grassy slopes covered with wild flowers. In winter
                    Gulmarg looks more beautiful due to heavy snow fall. And
                    turns into India's premier Ski resort in winter. The Gondola
                    Cable Car (optional) offers rides to the upland meadows of
                    Kongdori and beyond to the top of Apharwat range to a height
                    of 14000 ft for viewing the Himalayan Peaks in summer and
                    for downhill skiing in winter. One can have Gondola ride
                    (cable car directly payable) from Gulmarg to Khalinmarg .
                    Overnight in hotel in Gulmarg.
                  </p>
                </Col>
              </Row>

              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 07 : Gulmarg to Srinagar (Approx 2 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Hotel and drive back to Srinagar ,
                    Later on proceed to famous Nishat Garden known as ‘Garden of
                    pleasure' built in 16th century (1633) by Mughal King Asif
                    Khan. Then visiting Shalimar Garden known as ‘Garden of
                    love' built in 16th century (1619) by Mughal King Jehangir
                    for his beloved wife Noor Jehan or ( Mehr -un- Nissa ) ,
                    Evening Free and shopping, after drop to the Hotel and
                    Overnight stay in Hotel.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img4} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Day 08 : Srinagar Departure</p>
                  <p className="PackageDescrip">
                    After breakfast check out hotel and visit to Shankaracharya
                    Temple built on the top of the mountain overlooking Dal Lake
                    and then drive towards Airport for onward destination.
                  </p>
                </Col>
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusion />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
