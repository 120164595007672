import gif from "../../img/helpdesk-icon.gif";
import Button from "react-bootstrap/Button";
import React, { Component } from "react";
import emailjs from "emailjs-com";
import "./index.css";
export default class FormBooking extends Component {
  constructor() {
    super();
    this.state = {
      packageName: "",
      personName: "",
      phoneNumber: "",
      adults: 0,
      children: 0,
      accomodation: "",
      message: "",
      successMessage: false,
      errorMessage: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.packageName && this.state.phoneNumber === "") {
      alert("Please Fill in all the Fields");
    } else {
      emailjs
        .send(
          "service_7bfoi5h",
          "template_7965vhm",
          this.state,
          "aeWnO4fNvH5ggGIQT"
        )
        .then(
          () => {
            this.setState({
              successMessage: true,
            });
            setInterval(() => {
              window.location.reload();
            }, 5000);
          },
          () => {
            this.setState({
              errorMessage: true,
            });
          }
        );
    }
  };
  render() {
    const {
      personName,
      packageName,
      phoneNumber,
      adults,
      children,
      accomodation,
      message,
      successMessage,
      errorMessage,
    } = this.state;
    return (
      <>
        <div className="book-now-heading ">
          <img style={{ width: "80px" }} src={gif} alt="gif" />
          <div className="text-center book-now">BOOK NOW</div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <label className="labels">Package Name</label>
          <input
            className="mb-3 w-100 "
            type="text"
            name="packageName"
            value={packageName}
            placeholder="Package Name"
            onChange={this.handleChange}
            required
          />
          <label className="labels">Your Name</label>
          <input
            className="mb-3 w-100 "
            type="text"
            name="personName"
            value={personName}
            placeholder="E.g Mr Hamzah "
            onChange={this.handleChange}
            required
          />
          <label className="labels">Phone Number</label>
          <input
            className="mb-3 w-100"
            type="Phone"
            value={phoneNumber}
            onChange={this.handleChange}
            name="phoneNumber"
            placeholder="+91 0000000000"
            required
          />
          <label className="labels">Adults</label>
          <input
            className="mb-3 w-100"
            type="Number"
            name="adults"
            value={adults}
            onChange={this.handleChange}
            placeholder="Adults"
          />
          <label className="labels">Children</label>
          <input
            className="mb-3 w-100"
            type="Number"
            value={children}
            name="children"
            onChange={this.handleChange}
            placeholder="Children"
          />
          <label className="labels">Accomodation</label>
          <select
            className="mb-3 w-100"
            name="accomodation"
            value={accomodation}
            onChange={this.handleChange}
          >
            <option value="" disabled selected hidden>
              Choose Accomodation
            </option>
            <option value="luxury">Luxury</option>
            <option value="5-star">5 star</option>
            <option value="4-star">4 star</option>
            <option value="3-star">3 star</option>
            <option value="budget">Budget</option>
          </select>
          <label className="labels">Message</label>
          <textarea
            value={message}
            onChange={this.handleChange}
            name="message"
            rows="4"
            cols="50"
            className="mb-3 w-100"
            type="textarea"
            placeholder="Message"
          />
          <div className="mb-3 error-successMessage">
            {successMessage
              ? "Thanks! We Recieved your query we will connect with you soon"
              : ""}
            {errorMessage
              ? "Hey We are having an issue with email currently. Can you reach us by call"
              : ""}
          </div>

          <Button variant="primary" type="submit">
            Send
          </Button>
        </form>
      </>
    );
  }
}
