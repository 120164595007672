import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import rates from "../../../../img/rates.png";
import Image from "react-bootstrap/Image";
import car_1 from "../../../../img/innova.jpg";
import car_2 from "../../../../img/etoscar.jpg";
import car_3 from "../../../../img/tempo.jpg";
import car_4 from "../../../../img/taveracar.jpg";
import gif from "../../../../img/helpdesk-icon.gif";
import FormBooking from "../../../../components/Form";

export default class MidSectionRental extends Component {
  render() {
    return (
      <>
        <Container className="mt-5">
          <Row style={{ justifyContent: "center" }}>
            <Col lg={9} md={6}>
              <Row>
                <Col lg={6}>
                  <Image className="mb-3" src={car_1} alt="innova" fluid />
                </Col>
                <Col lg={6}>
                  <Image className="mb-3" src={car_2} alt="etoscar" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12}>
                  <Image className="mb-3" src={car_3} alt="tempo" fluid />
                </Col>
                <Col lg={6} md={12}>
                  <Image className="mb-3" src={car_4} alt="taveracar" fluid />
                </Col>
              </Row>
            </Col>

            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
