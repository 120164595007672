import React, { Component } from "react";
import gif from "../../../../img/helpdesk-icon.gif";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import FormBooking from "../../../../components/Form";

export default class MidSectionHouseBoat extends Component {
  render() {
    return (
      <>
        <Container className="mt-4">
          <Row>
            <Col lg={9} md={6}>
              <div className="houseboat-wrapper">
                <p className="Heading-houseboat">
                  ROSEMARY HOUSEBOATS (Dal Lake Srinagar)
                </p>
                <p className="About-rosemary">About Rosemary Houseboats</p>
                <p className="description-rosemary">
                  Houseboat Rosemary is the located on Dal Lake, features
                  accommodations with intricately carved walnut wood furniture
                  and Kashmiri carpets. It provides 24-hour room service and
                  complimentary Wi-Fi access.
                  <br />
                  <br /> The houseboat is 0.6 mi from the TRC Bus Stand. It is
                  6.2 mi from Mughal Gardens and 8.7 mi from Kashmir Airport.
                  iAll Rooms on board the boat offer satellite TV, a wardrobe
                  and a desk. The attached bathroom comse with hot/cold shower
                  facilities and a bathtub. Guests have access to a shared
                  dining and sitting area.
                  <br />
                  <br /> A 24-hour front desk is available, where Rosemarry
                  staff can assist with travel arrangements, as well as laundry
                  services.
                </p>
                <p className="About-rosemary">About Houseboats</p>
                <p className="description-rosemary">
                  The two beautiful lakes in Srinagar, Dal and Nigeen,
                  houseboats tour are perfect for people of age and traveller of
                  all types. Therefore, they are deemed ideal for family
                  vacationers, honeymoon couples as well as solo travellers who
                  can take houseboat tour packages to enjoy this exclusive
                  experience. The houseboat holiday experience in Srinagar also
                  includes a shikara for a leisure ride in the lake or to get to
                  the nearest shore. Tourists can also enjoy shopping on
                  houseboats too, several vendors sail around these floating
                  boasts and sell anything from Kashmiri handicraft to barbequed
                  dishes and more kashmiri products.
                </p>

                <p className="About-rosemary">Houseboat Features</p>
                <p className="description-rosemary">
                  Kashmir’s houseboats are made of cedar wood which stays
                  healthy even after staying for years in water.
                  <br /> Inside the houseboats the main feature is the carved
                  wood paneling.
                  <br /> Walnut wood is used for furniture on which intricate
                  carvings are done. <br /> The porch area is one of the major
                  parts of the boat for guests to sit and relish incredible
                  views of Dal Lake.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
