import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionLehLadakh";
import img1 from "../../../../img/leh/hemis-monastery.jpg";
import img2 from "../../../../img/leh/huder-camel-ride.jpg";
import img3 from "../../../../img/leh/pangonglake.jpg";
import LadakhCarousal from "../CarousalLadakh";

export default class Ladakh_Tour_2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <PackageTitleDetails
            title="Nubra Valley with Pangong Lake Tour"
            nights={4}
            day={5}
            destination={["Leh", "Monastery Tour ", "Nubra", "Pangong", "Leh"]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 1 : Arrive Leh - day free to relax and acclimatize"
                  text={
                    <>
                      On your arrival at Leh airport our representative will
                      meet & greet and transfer you to the Hotel.
                      <br />
                      We recommend you completely relax for the rest of the day
                      to enable your body to acclimatize to the rarefied air at
                      the high altitude. Leh is situated at the height of 11563
                      feet.
                      <br />
                      Lunch and dinner is served at the hotel. Overnight stay at
                      the hotel.
                    </>
                  }
                />
              </Row>

              {/* -----------------------------------------------Day `1`----------------------------------------- */}

              <Row>
                <PackageWithImage
                  title="DAY 2 : Leh - Monastery tour"
                  text={
                    <>
                      After breakfast we take you for a full day tour of
                      Monasteries.
                      <br />
                      We first visit Hemis Monastery which is one of the biggest
                      and best known monastery in this region. Then we visit
                      Thiksey Monastery located at a distance of 18 kms from Leh
                      town. Then we visit Shey Palace. Then we visit Rancho
                      School of 3 Idiot’s fame. The we return to hotel en route
                      visiting Sindhu Point.
                      <br />
                      <br />
                      After lunch time free to visit to the Shanti Stupa and Leh
                      Palace and evening visit to the local markets.
                      <br />
                      Dinner and overnight stay at the hotel.
                    </>
                  }
                  img={img1}
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Leh - Khardungla - Nubra - Hunder (Apporx 5 hrs)"
                  text={
                    <>
                      Early morning proceed to Nubra Valley via the Worlds
                      Highest Motorable road (5602 Mts) Khardungla pass.
                      Khardung la Pass (18,380ft above sea level): It is not
                      very often that one gets to drive on a road at an altitude
                      of 18,380 feet. That is where Khardungla Pass, meaning the
                      Pass of Lower Castle, is located on the way from Leh to
                      the Nubra Valley in Ladakh.
                      <br />
                      <br />
                      It is the highest motorable road in the world as signs put
                      up by the Border Roads Organization proudly proclaim. Post
                      the Diskit Monastery, proceed for backward journey to Leh
                      Arrive Nubra Valley and proceed for a visit to the White
                      Sand Dunes at Hunder. You can also enjoy a camel ride here
                      (Camel ride on direct payment basis).
                      <br />
                      <br />
                      Nubra Valley: Popularly called the orchard of Ladakh,
                      Nubra valley offers a graceful view of yellow and pink
                      wild roses in summer and a carpet of wild lavender in the
                      post-monsoon season. You will definitely be overwhelmed to
                      see the Bactrian Camels (Shaggy double hump Camel) around
                      sand dunes. Thereafter, check in at comfortable Camp or
                      Hotel. Evening at leisure. Dinner & overnight stay at the
                      camp or hotel.
                    </>
                  }
                  img={img2}
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 4 : Hunder - Pangong (Apporx 5 hrs) - Pangong - Leh Back (Approx 6 hrs)"
                  text={
                    <>
                      After breakfast at hotel we drive to next destination
                      pangong lake via Shyok and onto Tangtse and Spangmik (
                      Pangong). It’s a short cut and it’s a beautiful route en
                      route visit Diskit monastery and Maitreya Buddha . after
                      drive to Pangong by Shyok river, and climbs up a mountain
                      before finally descending into Shayok village.
                      <br />
                      <br />
                      It took us less than an hour to reach Shayok, and we were
                      delighted at the sight of the rather big village
                      surrounded by barren mountains, the entire setting being
                      very picturesque. Afternoon we reach at Pangong lake
                      enjoy. Around 2 PM drive back to Leh via Changla Pass
                      Evening check in Hotel and Overnight Stay.
                    </>
                  }
                  img={img3} //Image
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 5 : Leh Departure"
                  text={
                    <>
                      After breakfast, check out from the hotel & drop at
                      Airport for return journey with sweet memories of Ladakh
                      Tour
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
