import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import img1 from "../../../../img/amarnathyatra-trek.jpg";
import img2 from "../../../../img/amarnath-yatra-trek4.jpg";

export default class Tour_12 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">
              Amarnath Yatra by Trek via Baltal
            </p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 3 Nights / 4 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Sonamarg
              - Baltal to Trek (12 KM) - Amarnath Ji Darshan - Next Day -
              Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 01 : Srinagar - Sonamarg (3-4 hours (approx.)
                  </p>
                  <p className="PackageDescrip">
                    You will be safely transported to the Sonmarg/Baltal by
                    road. Songmarg is a picturesque hill station in Kashmir
                    where you will enjoy the breathtaking scenery and the fresh
                    air. Literally translates "Meadows of Gold," Sonmarg has
                    pine trees, green patches on mountains, and hoards of
                    adventure sports to try. Do not forget to bring your camera
                    to take photographs of the beautiful spectacles around.
                    <br />
                    <br />
                    Optional: You can choose to stay in Baltal, a camping ground
                    for pilgrimage, and a favorite halt duringAmarnath Cave
                    Yatra. Baltal has some well-arranged tents and camps for the
                    pilgrims.
                    <br />
                    <br />
                    Once you get transported to a hotel/luxury tent in
                    Sonmarg/Balta, complete check-in formalities and enjoy your
                    overnight stay after dinner.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 02 : Baltal - A Day For Amarnath Cave- Baltal to
                    Amarnath Cave Temple: 10 km (approx.) Trekking time: 6-7
                    hours (approx.)
                  </p>
                  <p className="PackageDescrip">
                    Today, you will be waking up early with a delicious
                    breakfast because your 2nd day is reserved for Darshan of
                    Holy Cave Amarnath. We will transfer you to the Amarnath
                    Shrine via Baltal, from where your journey to spirituality
                    will start. Baltal will be your base point where the trek to
                    Amarnath Cave will start. Apart from trekking, pony rides,
                    pithoos, or palki, are available as an easy option to reach
                    the holy cave. After you complete the darshan at the sacred
                    cave, Overnight stay in Camp Near Cave.
                    <br />
                    Distance from Sonmarg to Amarnath Cave Temple: 22 km
                    (approx.)
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 03 : Amarnath Cave back to Srinagar - Distance from
                    Baltal to Srinagar: 96 km (approx.)
                  </p>
                  <p className="PackageDescrip">
                    Early Morning back to Baltal Around 12 PM pickup from Baltal
                    and drive back to Srinagar, It is suggested to enjoy the
                    scenic landscape, ensuring Srinagar and gaze at the
                    picturesque panoramas. When you reach Srinagar, your
                    houseboat/hotel stay is reserved for you! Make sure you
                    perform your check-in formalities. You can enjoy the rest of
                    the day at your own pace and leisure. If you wish you can
                    also enjoy the Shikara Ride on the shimmering Dal Lake..
                    Once dusk strikes, return to your houseboat or hotel in
                    Srinagar for an overnight stay with a delectable dinner.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid className="text-center" />
                </Col>
              </Row>

              {/* -----------------------------------------------Day 3----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 04 : Srinagar Airport for Onward Journey
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out from hotel and transferred to
                    Srinagar bus station Or Airport for your onward journey.
                  </p>
                </Col>
              </Row>

              <hr></hr>
              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">
                    {" "}
                    Check List For Amaranth Yatra Trek By Foot :-
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Hiking/sports shoes(Waterproof)
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - A support stick for trekking
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Drinking Water bottle
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - A few pairs of sweat Shirts/Warm clothes
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Raincoat/ Umbrella ( Hands free is Better)
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Wind-Cheater Jacket ( With Cap)
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Warm socks
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Some First Aid & any regular medicines that you take
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Sunscreen and Sun hat ( Hands free umbrella works as hat
                    as well)
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Some Moisturizer or Cold cream
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - A Flash light (Torch) Mobile torch can also work
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Toilet paper
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Above package includes:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Srinagar Airport Pickup and Drop
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - 1 Night accommodation in Deluxe Hotel in Sonamarg with
                    breakfast & dinner on twin sharing basis.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - 1 Nights accommodation in Srinagar Hotel/ Houseboats with
                    breakfast & dinner on twin sharing basis.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Airport Transfer by car with English speaking driver.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Shikara Tour in Dal Lake.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - All present applicable taxes.{" "}
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Car Including: Fuel, Parking.{" "}
                  </p>
                </Col>
              </Row>
              {/* --------------------------------------------------Exclusion--------------- */}
              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Exclusions:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Any meals other than those specified.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Expenses of personal nature such as tips, telephone calls,
                    laundry, liquor etc.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Any other item not specified.
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Entrance fees during sightseeing
                  </p>
                </Col>
              </Row>
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
