import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function InclusionExclusion() {
  return (
    <>
      <hr></hr>

      <Row>
        <Col lg={9} md={6}>
          <p className="titlePackage">Above package includes:</p>
          <p style={{ margin: "0px", fontSize: "14px" }}>
            - Pickup & Drop Srinagar Airport.
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            - All Stay with 3 Star Hotel.
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            - Deluxe Houseboat in Dal Lake.
          </p>
          <p style={{ margin: "0px", fontSize: "14px" }}>
            - Double Sharing Rooms with Breakfast & Dinner.
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - Veg or Non Veg Food.
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - Welcome drink on arrival in Houseboat.{" "}
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - One hour Shikara Ride on Dal Lake.{" "}
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - All Sightseeing by Personal Car.{" "}
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - All present applicable taxes.{" "}
          </p>
        </Col>
      </Row>

      {/* --------------------------------------------------Exclusion--------------- */}

      <Row>
        <Col lg={9} md={6}>
          <p className="titlePackage">Exclusions:</p>
          <p style={{ margin: "0px", fontSize: "14px" }}>- Meal Plan (Lunch)</p>

          <p style={{ margin: "0px", fontSize: "14px" }}>- Horse Ride</p>

          <p style={{ margin: "0px", fontSize: "14px" }}>- Monument Fee</p>
          <p style={{ margin: "0px", fontSize: "14px" }}>
            - Double Sharing Rooms with Breakfast & Dinner.
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - Personal Expenses like Telephone, Fax, Internet, Laundry etc
          </p>

          <p style={{ margin: "0px", fontSize: "14px" }}>
            {" "}
            - Any Expenses related to portage at hotels, airports, tips, room
            service, insurance, wine, boating charge, guide, entrance fee,
            porters, palanquins, or ponies{" "}
          </p>
        </Col>
      </Row>
    </>
  );
}
