import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionKerala from "../InclusionExclusionKerala";

import img1 from "../../../../img/South/andman.jpg";
import img2 from "../../../../img/South/andaman3.jpg";
import img3 from "../../../../img/South/andama4.jpg";
import CarouselKerala from "../KeralaCarousal";

export default class SouthIndia_Tour_3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <PackageTitleDetails
            title="Andaman Honeymoon Package"
            nights={4}
            day={5}
            destination={[
              "Port Blair",
              "Havelock Island",
              "Neil Island",
              "Port Blair",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 1: Arrival at Port Blair"
                  text={
                    <>
                      When you arrive in Port Blair in the morning / afternoon,
                      according to your flight schedule, our representative will
                      be waiting for you outside the Arrivals Hall with a name.
                      Upon arrival to Honeymoon Package in Andaman, they escort
                      you to the hotel, where you will rest and relax. After
                      lunch at the hotel, your visit to Andaman begins with our
                      tour of the famous Cell Chain.
                    </>
                  }
                  img={img1}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Visit the cell prison and relive the island’s cruel past.
                      You can hire a guide at a nominal cost in prison cells to
                      learn more about the historical value of prison cells.
                      After waiting outside, watch the fascinating sound and
                      light show in the cell prison, where the heroic saga
                      relived the Indian freedom struggle. Upon completion, you
                      will be taken back to your hotel. Today your stay would be
                      in Port Blair.
                    </>
                  }
                />
              </Row>

              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 2: Port Blair – Havelock Island (2 hours by ferry)"
                  text={
                    <>
                      After a sumptuous breakfast, we now leave for a holiDay in
                      the most remarkable destination on the island of Andaman,
                      on the island of Havelock. This destination is classified
                      as the best island in India. Departure from Port Blair on
                      the island of Havelock on a high-speed cruise. Upon
                      arrival in Havelock, they will assist you and drop you off
                      at your hotel. Rest and relax at a beach resort. we only
                      share seaside resorts for your wonderful Honeymoon in
                      Andaman Nicobar.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  text={
                    <>
                      After lunch, we start our excursion to one of the best
                      beaches in Asia, Radhanagar Beach. Discover this white
                      sand beach and enjoy the beauty of this natural wonder. We
                      visited Radhanagar Beach on vacation, waiting for the
                      sunset, which is simply stunning. After the trip, we will
                      take you back to your resort, where you can relax and
                      unwind during your Andaman Nicobar Honeymoon. ToDay his
                      stay would be on the island of Havelock.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 3: Havelock – Neil Island (2 hours by ferry)"
                  text={
                    <>
                      Leave the hotel in Havelock and board a ferry for your
                      Neil Island Honeymoon package. Upon arrival on the island,
                      our representative will take you to your previously booked
                      hotel. Rest and then go to Bharatpur Beach, Sitapur Beach
                      and Laxmanpur Beach. First, we cover Bharatpur beach. The
                      nearest white sand beach to Neil Pier, Bharatpur Beach.
                      The reef here is still untapped and travelers can snorkel,
                      glass bottom boat and jet ski here.
                      <br />
                      <br />
                      Then, visit Sitapur beach, which is a picturesque beach
                      for travelers. Then, we will visit the Natural Rock
                      formation, which will be a natural wonder and will look
                      like a bridge. We visited Laxmanpur beach. Known as the
                      sunset stage on Neil Island, Laxmanpur Beach is a vast
                      open white sand beach. Upon completion, we will return to
                      your resort. Today your stay would be on Neil Island.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 4: Neil Island – Port Blair (2 hours by ferry)"
                  text={
                    <>
                      After breakfast, head to the Neil Island boat pier to
                      embark on the cruise to Port Blair. This cruise will last
                      1 hour. Upon arrival at the port of Port Blair, transfer
                      to the hotel. Later, continue for half a day on the
                      Andaman and Nicobar Islands, visiting the sights of Port
                      Blair, visiting the Anthropological Museum, the Fishing
                      Museum, the Samudrika Museum (Naval Museum of the Navy)
                      and the beach at Corbyns Cove, a picturesque beach ideal
                      for swimming and sunbathing. Today your stay would be in
                      Port Blair.
                    </>
                  }
                  img={img3}
                />
              </Row>
              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 5:Departure from Portblair"
                  text={
                    <>
                      Today is the conclusion of your Andaman Honeymoon. Come
                      home with fond memories of that Andaman Honeymoon Tour
                      Package. On this day, we take you to the airport for your
                      departure from Andaman. We hope you enjoyed your time on
                      these islands.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionKerala />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
