import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import InclusionExclusion from "../../../../components/ExclusionInclusion";
import TourPackages from "../../../Homepage/components/TourPackages";
import img1 from "../../../../img/dallake-winter-view.jpg";
import img2 from "../../../../img/pahalgam-view.jpg";

export default class Tour_3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">
              Kashmir Vacation Tour (Day Trip)
            </p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 4 Nights / 5 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Sonamarg
              - Gulmarg - Pahalgam - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">Day 01: Srinagar arrival</p>
                  <p className="PackageDescrip">
                    Our services will start with your arrival at Srinagar
                    airport, you will be met by our representative and transfer
                    to the Houseboats, check in Houseboats, after freshup visit
                    to Mughal Gardens consists of mainly of three gardens
                    -Chashme shahi garden -Nishat Garden -Shalimar Garden
                    .Shankaracharya Temple Takht-e-Suleiman hill, the
                    Shankaracharya Temple is dedicated to Lord Shiva. It is
                    supposed to be the oldest shrine of the Kashmir valley,
                    Hazratbal Shrine- Situated opposite the Nishat Bagh on the
                    banks of Dal Lake,Parimahal. In the afternoon Shikara ride
                    on The Dal Lake to enjoy the beauty that God has graced this
                    city, after sightseeing check in Houseboats and Overnight
                    stay
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 02: Srinagar- Sonamarg – Srinagar (Approx 2 hrs 30 mint)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast leave for Sonamarg situated at an altitude
                    of 9000 Ft. Sonamarg – 84 Kms is also called as “The meadow
                    of gold” has, as its backdrop, snowy mountains against a
                    cerulean sky. The Sindh River that meanders through the
                    valley abounds with trout and mahaseer. Ponies can be hired
                    for the trip up to Thajiwas glacier, which is a major local
                    attraction during the summer. Evening back to Srinagar and
                    Overnight stay Houseboats.
                  </p>
                </Col>
              </Row>
              {/* -------------------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 03: Srinagar - Gulmarg – Srinagar (Approx 2 hrs)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Houseboats and drive to gulmarg by
                    car called as “Meadow of Flowers”. Gulmarg is 53 Kms from
                    Srinagar at an altitude of 8700-ft. Gulmarg also referred as
                    Meadow of Flowers is a famous Ski Resort & the world’s
                    highest 18 hole Golf Course. The place was prominent during
                    the British time.In Aftetnoon proceed towards the scenic
                    view of Kongdori on a Cable Car Ride. Later, begin a short
                    tour, boarding the Gondola cable car system (the 08 minutes
                    ropeway). Descend back to Gulmarg after an hour and later
                    indulge in some horse-riding. After sightseeing back to
                    Srinagar, and check in Hotel, Overnight stay in Hotel.
                  </p>
                </Col>
              </Row>
              {/* ---------------------------------------------------------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 04: Srinagar – Pahalgam - Srinagar (Approx 2 hrs 30
                    mint)
                  </p>
                  <p className="PackageDescrip">
                    Today you will be transferred by road to Pahalgam. Valley of
                    Shepherds. Enroute you will have an rare opportunity to
                    visit the Saffron fields. Also visit Avantipura Ruins &
                    Anantnag Sulpher Springs enroute, Arrive and proceed to your
                    hotel. Visit to Chandanwadi, enjoy leisurely walks through
                    the pine forest along the River Lidder (at your own cost),
                    The snow Point (14 Km) at your own cost or enjoy pony ride
                    at your own cost . After Sightseeig back to Srinagar and
                    Overnight stay in Hotel
                    <br />
                    (Aru,Chandanwari.Betaab Valley sightseeing will be not
                    included).
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 3----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Day 05: Srinagar Airport Drop</p>
                  <p className="PackageDescrip">
                    After breakfast transfer to Srinagar airport to board flight
                    for onwards destination with sweet memories of the trip.
                  </p>
                </Col>
              </Row>
              {/* ------------------------------------------------------------------------------------ */}

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusion />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
