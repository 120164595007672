import React from "react";
import img1 from "../../../../img/South/carousal/kerala-top1.jpg";
import img2 from "../../../../img/South/carousal/kerala-top2.jpg";
import img3 from "../../../../img/South/carousal/kerala-top3.jpg";
import img4 from "../../../../img/South/carousal/15.jpg";
import Carousel from "react-bootstrap/Carousel";

export default function CarouselKerala() {
  return (
    <>
      <Carousel className="carousel-wrapper">
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img1} alt="First slide" />
          <Carousel.Caption>
            <h3>Backwater Houseboat - Kerala</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img2} alt="First slide" />
          <Carousel.Caption>
            <h3>Munnar Tea Garden</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img3} alt="Second slide" />
          <Carousel.Caption>
            <h3>Kovalam Beach - Kerala</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img4} alt="Second slide" />
          <Carousel.Caption>
            <h3>Kerala Culture Tour</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
