import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./index.css";
import car1 from "../../../..//img/car-top.jpg";
import car2 from "../../../..//img/car-top1.jpg";

export default class CarousalRental extends Component {
  render() {
    return (
      <>
        <Carousel className="carousel-wrapper">
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 img" src={car1} alt="First slide" />
            <Carousel.Caption>
              <h3>Car Rentals in Srinagar</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 img" src={car2} alt="First slide" />
            <Carousel.Caption>
              <h3>Car Rentals in Srinagar</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
}
