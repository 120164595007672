import React from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
export default function PackageWithImage(props) {
  return (
    <>
      <Col lg={8} md={6}>
        <p className="titlePackage">{props.title}</p>
        <p className="PackageDescrip">{props.text}</p>
      </Col>
      <Col lg={4} md={6}>
        <Image src={props.img} alt="" fluid />
      </Col>
    </>
  );
}
