import React, { Component } from "react";
import TailourForm from "../../components/TailourMadeForm";
import ContactUSCarousel from "./components/Carousal";
import MidSection from "./components/MidSection";

export default class AboutUS extends Component {
  render() {
    return (
      <>
        <ContactUSCarousel />
        <MidSection />
        <TailourForm />
      </>
    );
  }
}
