import React, { Component } from "react";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import j_k from "../../../..//img/houseboat-im.jpg";
import leh from "../../../../img/pangong-img.jpg";
import trek from "../../../../img/trek.jpg";
import himachal from "../../../../img/himachal.jpg";
import rajashtan from "../../../../img/rajasthan.jpg";
import south from "../../../../img/southindia.jpg";

export default class TourPackages extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div className="best_tour_wrapper">
              <div className="best_tour_title">
                <hr></hr>
                Best Tour Packages for Kashmir, Ladakh, Himachal, Rajasthan &
                South India
                <hr></hr>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6}>
            <Card className="text-center mb-3" as={Link} to="/jk">
              <Card.Img variant="top" src={j_k} />
              <Card.Body>
                <Card.Title className="tourName">Jammu & Kashmir</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className="text-center mb-3" as={Link} to="/leh_ladakh">
              <Card.Img variant="top" src={leh} />
              <Card.Body>
                <Card.Title className="tourName">
                  Leh Ladakh Holidays
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className="text-center mb-3" as={Link} to="/himachal">
              <Card.Img variant="top" src={himachal} />
              <Card.Body>
                <Card.Title className="tourName">Himachal Holidays</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6}>
            <Card className="text-center mb-3 " as={Link} to="/adventure">
              <Card.Img variant="top" src={trek} />
              <Card.Body>
                <Card.Title className="tourName">Kashmir Trekking</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className="text-center mb-3" as={Link} to="/rajasthan">
              <Card.Img variant="top" src={rajashtan} />
              <Card.Body>
                <Card.Title className="tourName">Rajasthan Holidays</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className="text-center mb-3" as={Link} to="/southIndia">
              <Card.Img variant="top" src={south} />
              <Card.Body>
                <Card.Title className="tourName">
                  South India Holidays
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
