import React from "react";
import img16 from "../../../../img/16.jpg";
import img17 from "../../../../img/Rajasthan/tajmaha11l.jpg";
import img18 from "../../../../img/Rajasthan/udaipur-top.jpg";

import Carousel from "react-bootstrap/Carousel";

export default function CarousalRajasthan() {
  return (
    <>
      <Carousel className="carousel-wrapper">
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img16} alt="Third slide" />
          <Carousel.Caption>
            <h3>Hawa Mahal - Jaipur - Rajasthan</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img17} alt="Third slide" />
          <Carousel.Caption>
            <h3>Taj Mahal - Agra</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img18} alt="Third slide" />
          <Carousel.Caption>
            <h3>Lake Palace - Udaipur</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
