import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageDetails1 from "./PackageDetail";

export default class SrinagarTour_1 extends Component {
  render() {
    return (
      <>
        <NewCarousel />
        <PackageDetails1 />
        <TourPackages />
      </>
    );
  }
}
