import React, { Component } from "react";
import NewCarousel from "../../components/Carousel";
import WelcomeNote from "./components/Welcome";
import TourPackages from "../Homepage/components/TourPackages";
import HouseBoat from "../Homepage/components/houseboat";
import LuxuryStay from "./components/LuxuryStay";
import SpecialTour from "./components/SpecialTourPackages";
import GetQuote from "./components/GetQuote";
import ContactUS from "./components/ContactUS";

export default class HomePage extends Component {
  render() {
    return (
      <div>
        <NewCarousel />
        <WelcomeNote />
        <TourPackages />
        <HouseBoat />
        <LuxuryStay />
        <SpecialTour />
        <GetQuote />
        <ContactUS />
      </div>
    );
  }
}
