import React, { Component } from "react";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";

export default class GetQuote extends Component {
  render() {
    return (
      <>
        <div className="wrapper-quote">
          <Row className="text-center">
            <Col className="wrpper">
              <p className="quote-text">WELCOME TO OUR TRAVEL COMPANY </p>
              <Button className="quote-button">GET A QUOTE</Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
