import React from "react";
import FormBooking from "../../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import "./index.css";
import InclusionExclusion from "../../../../../components/ExclusionInclusion";
import DallakePic from "../../../../../img/Srinagar1/dallake-view.jpg";
import gandolaPic from "../../../../../img/Srinagar1/gulmarg-gondola.jpg";
export default function PackageDetails1() {
  return (
    <>
      <Container>
        <div className="PackageDetailswrapper">
          <p className="Heading-houseboat">Srinagar Trip Tour (Day Trip)</p>
          <p className="DurationOfPackage">
            <span className="darker"> Duration :</span> 3 Nights / 4 Days Trip
          </p>

          <p className="DurationOfPackage">
            {" "}
            <span className="darker"> Destination :</span> Srinagar - Sonamarg -
            Gulmarg - Srinagar
          </p>
          <hr />
        </div>
        <Row>
          <Col lg={9} md={6}>
            {/* -----------------------------------------------Day `1`----------------------------------------- */}
            <Row>
              <Col lg={8} md={6}>
                <p className="titlePackage">Day 01: Srinagar</p>
                <p className="PackageDescrip">
                  On arrival at Srinagar, you will be met by our representative
                  and transfer you to Houseboat. Afternoon free for personal
                  activities. Half day tour of world famous Mughal Gardens
                  visiting the Nishat Bagh (The garden of pleasure) and Shalimar
                  Bagh (Abode of love). In the evening Shikara ride on The Dal
                  Lake.
                  <br />
                  You will get a view of some very interesting places around the
                  Lake. After sightseeing back to Houseboat, Overnight stay in
                  Houseboat.
                </p>
              </Col>
              <Col lg={4} md={6}>
                <Image src={DallakePic} alt="" fluid />
              </Col>
            </Row>
            {/* -----------------------------------------------Day 2----------------------------------------- */}
            <Row>
              <Col lg={12} md={6}>
                <p className="titlePackage">
                  Day 02: Srinagar - Sonamarg - Srinagar ( 76 kms One way)
                </p>
                <p className="PackageDescrip">
                  After breakfast Drive to Sonamarg, and full day excursion of
                  Sonmarg which is one of the most beautiful drive from
                  Srinagar. Sonmarg also called Meadow of Gold is located at a
                  height of 2690 meters. You may take a pony ride (at your own
                  cost) to Thajiwas Glacier where snow remains round the year.
                  Return to the Houseboat/ Hotel by evening for overnight stay.
                </p>
              </Col>
            </Row>
            {/* -----------------------------------------------Day 3----------------------------------------- */}
            <Row>
              <Col lg={8} md={6}>
                <p className="titlePackage">
                  Day 03 : Srinagar - Gulmarg - Srinagar (8700 Feet, 52 kms, 1.5
                  hrs One way)
                </p>
                <p className="PackageDescrip">
                  After Breakfast, drive to Gulmarg called as “Meadow of
                  Flowers” . Gulmarg is 52 Kms from Srinagar at an altitude of
                  8700-ft.
                  <br />
                  Gulmarg also referred as Meadow of Flowers is a famous Ski
                  Resort & the world’s highest 18 hole Golf Course. The place
                  was prominent during the British time. You can enjoy a Pony
                  ride (Optional At your own cost).
                  <br />
                  <br />
                  Experience Gondola ride (up to first level only). Gulmarg has
                  one of the best Ski slopes in the world and highest golf
                  course of the world with 18 holes.
                  <br />
                  After sightseeing back to Srinagar, Check in Hotel and
                  Overnight stay in Hotel
                </p>
              </Col>
              <Col lg={4} md={6}>
                <Image src={gandolaPic} alt="" fluid />
              </Col>
            </Row>
            {/* -----------------------------------------------Day 4----------------------------------------- */}
            <Row>
              <Col lg={12} md={6}>
                <p className="titlePackage">Day 04 : Srinagar Departure</p>
                <p className="PackageDescrip">
                  After Breakfast check out hotel and transfer to Srinagar
                  Airport for Departure with the memories that can’t be
                  forgotten.
                </p>
              </Col>
            </Row>

            {/* --------------------------------------------------inClusion--------------- */}
            <InclusionExclusion />
            {/* --------------------------keep down------------------------------------ */}
          </Col>

          {/* --------------------------------------------------------------------------------------------------------- */}

          {/* Booking Section */}
          <Col lg={3} md={6}>
            <FormBooking />
          </Col>
        </Row>
      </Container>
    </>
  );
}
