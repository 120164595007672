import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionKerala from "../InclusionExclusionKerala";

import img1 from "../../../../img/South/munnar.jpg";
import img2 from "../../../../img/South/periyar-wildlife-sanctuary.jpg";
import img3 from "../../../../img/South/cochin1.jpg";
import CarouselKerala from "../KeralaCarousal";

export default class SouthIndia_Tour_2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <PackageTitleDetails
            title="Best of Kerala Holiday Tour"
            nights={4}
            day={5}
            destination={["Cochin", "Munnar", "Thekkady", "Cochin"]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrival Cochin – Munnar (140km-4hrs)"
                  text={
                    <>
                      Welcome to God’s own Country, upon arrival our
                      representative will receive you in the Cochin
                      International Airport and drive down to Munnar, the place
                      where the unfolded green tea carpet is waiting for you.
                      Munnar is beautiful and a haven of peace and serenity –
                      the idyllic tourist destination. Munnar is the entryway to
                      the Hills of South India. En-route you can stop to watch
                      the beautiful waterfalls of Cheeyappara and Valara and
                      later reach and check-in at the hotel. Today your stay
                      would be in munnar.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 2 : Munnar sightseeing"
                  text={
                    <>
                      Morning explore the scenic hill station. Your tour will
                      include Anamudi, South India’s highest peak, the
                      Eravikulam National Park, the Rajamala Hills, Upper
                      Station along with the Mattupetty Dam & Kundala Dam.
                      Travelers can indulge in activities such as boating and
                      horse riding here. Today your stay would be in Munnar
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Munnar - Thekkady (140km-4hrs)"
                  text={
                    <>
                      After breakfast, a highly picturesque place ideal for
                      unwinding. Upon arrival check in at the hotel. Early
                      evening simmer down and get refreshed enjoying a boat
                      safari in the Periyar Lake that would be an awesome
                      experience. Occasionally winning the glance of some
                      varieties of birds and animals in Periyar National Park.
                      Today your stay would be at Thekkady.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 4 : Thekkady – Cochin (190km-6hrs)"
                  text={
                    <>
                      Wake up the nature, and after breakfast proceed to Cochin.
                      Following your Check in to the hotel, Proceed for
                      sightseeing to include places like Fort Cochin,
                      nevertheless keeping alive its early Jew tradition, the
                      Santa Cruz Basilica, Mattancherry Palace, St. Francis
                      Church and more. The Jew’s street and the Chinese fishing
                      nets in Fort Cochin beach are the must-sees. Today your
                      stay would be in Cochin.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 5 : Cochin Departure"
                  text={
                    <>
                      After breakfast check out from the Hotel and drive to
                      Cochin Airport for the flight back home with sweet
                      memories of your wonderful Holiday spent at the God’s Own
                      Country.
                    </>
                  }
                  img={img3}
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionKerala />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
