import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionLehLadakh";

import img1 from "../../../../img/leh/sangam1.jpg";
import img2 from "../../../../img/leh/khardungla.jpg";
import img3 from "../../../../img/leh/turtuk.jpg";
import img4 from "../../../../img/leh/pangong-img.jpg";
import img5 from "../../../../img/leh/thiksey-monastery.jpg";
import LadakhCarousal from "../CarousalLadakh";

export default class Ladakh_Tour_6 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <PackageTitleDetails
            title="Ladakh Trans Himalayan Tour"
            nights={9}
            day={10}
            destination={[
              "Leh",
              "Magnetic Hill",
              "Sangam",
              "Lamayuru",
              "Leh",
              "Khardungla",
              "Nubra",
              "Hunder",
              "Turtuk",
              "Hunder",
              "Pangong",
              "Hanle",
              "Tsomorori",
              "Leh",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 1 : Arrive Leh - day free to relax and acclimatize"
                  text={
                    <>
                      On your arrival at Leh airport our representative will
                      meet & greet and transfer you to the Hotel.
                      <br />
                      We recommend you completely relax for the rest of the day
                      to enable your body to acclimatize to the rarefied air at
                      the high altitude. Leh is situated at the height of 11563
                      feet.
                      <br />
                      Lunch and dinner is served at the hotel. Overnight stay at
                      the hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 2 : Leh -Shyam Valley & Lamayuru Monastery"
                  text={
                    <>
                      After breakfast drive to Lamayuru enroute Magnetic hill
                      and Gurudwara Pathar Sahib. visit the mesmerizing Moon
                      landscapes and the Lamayuru monastery (one of the oldest
                      Monastery in central Ladakh).
                      <br />
                      After Lamayuru Sightseeing back to leh via Sangam you can
                      enjoy rafting on Indus river from Phey to Nimmo at an
                      additional cost. The Sangam is the place called Nimu where
                      the Zanskar river and Indus rivers merge.
                      <br />
                      <br />
                      Magnetic Hill is the place where you will see your car,
                      with its engine turned off, move upwards on a steep slope.
                      It almost sounds unbelievable.
                    </>
                  }
                  img={img1}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Gurdwara Patthar Sahib located at a distance of 40 kms
                      from Leh, was constructed in 1571, in memory of Guru Nanak
                      to commemorate his visit to this region.
                      <br />
                      <br />
                      Hall of Fame is a must visit for every patriotic Indian.
                      It has been built by Indian army in memory of soldiers who
                      lost their lives in Kargil war. After Sightseeing back to
                      Leh Market and visit to Shanti Stupa after back to Hotel,
                      Dinner and overnight stay at the hotel.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Leh - Khardungla - Nubra - Hunder (Apporx 5 hrs)"
                  text={
                    <>
                      Early morning proceed to Nubra Valley via the Worlds
                      Highest Motorable road (5602 Mts) Khardungla pass.
                      Khardung la Pass (18,380ft above sea level): <br />
                      It is not very often that one gets to drive on a road at
                      an altitude of 18,380 feet. That is where Khardungla Pass,
                      meaning the Pass of Lower Castle, is located on the way
                      from Leh to the Nubra Valley in Ladakh.
                    </>
                  }
                  img={img2}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  text={
                    <>
                      It is the highest motorable road in the world as signs put
                      up by the Border Roads Organization proudly proclaim. Post
                      the Diskit Monastery, proceed for backward journey to Leh
                      Arrive Nubra Valley and proceed for a visit to the White
                      Sand Dunes at Hunder. You can also enjoy a camel ride here
                      (Camel ride on direct payment basis).
                      <br />
                      <br />
                      Nubra Valley: Popularly called the orchard of Ladakh,
                      Nubra valley offers a graceful view of yellow and pink
                      wild roses in summer and a carpet of wild lavender in the
                      post-monsoon season. You will definitely be overwhelmed to
                      see the Bactrian Camels (Shaggy double hump Camel) around
                      sand dunes. Thereafter, check in at comfortable Camp or
                      Hotel. Evening at leisure. Dinner & overnight stay at the
                      camp or hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 4 : Hunder - Turtuk - Hunder (70KM)"
                  text={
                    <>
                      After breakfast at the camp, en route to Turtuk, the
                      northernmost village of India located on the bank of Shyok
                      river. The culture of Turtuk is distinctly Balti
                      (originating from Baltistan). Turtuk is one of the
                      gateways to the Siachen Glacier and is very famous for the
                      availability of different varieties of fruits especially
                      apricots. Free to explore Turtuk village in the afternoon.
                      Drive back to Hunder (Nubra Valley) Overnight stay at
                      Camp.
                    </>
                  }
                  img={img3} //Image
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 5 : Hunder - Pangong (Apporx 5 hrs)"
                  text={
                    <>
                      After breakfast at hotel we drive to next destination
                      pangong lake via Shyok and onto Tang Tse and Spangmik (
                      Pangong).
                      <br />
                      <br />
                      It's a short cut and it's a beautiful route. The road
                      follows the Shyok river, and climbs up a mountain before
                      finally descending into Shayok village. It took us less
                      than an hour to reach Shayok, and we were delighted at the
                      sight of the rather big village surrounded by barren
                      mountains, the entire setting being very picturesque.
                      Afternoon we reach at Pangong lake and enjoy your day at
                      pangong lake. Overnight stay at camp.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 6: Pangong - Hanle"
                  text={
                    <>
                      Post breakfast, bid adieu to Pangong Tso and drive towards
                      Hanle in the Changthang valley. Cross the Rezang-La war
                      memorial in between. Reach Hanle which is one of the most
                      beautiful, enchanting and calm places in India. The close
                      proximity to Indo-Tibetan border makes this village highly
                      sensitive and an Inner line permit is required to visit
                      here. In Hanle visit the lovely Hanle Monastery offering
                      some great aerial views of the whole village that is a
                      home of about 1000 people. Hanle also houses an Indian
                    </>
                  }
                  img={img4} //Image
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Astronomical Observatory, which is the world’s highest
                      observatory in the world at a staggering height of 4500
                      Mtrs. The Hanle Observatory is operated by Indian
                      Institute of Astrophysics, Bengaluru and has the second
                      highest optical telescope in the world, about 2.01 meters
                      (6.5 feet) in diameter. Overnight stay at Hanle.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 7: Hanle - Tso Moriri Lake"
                  text={
                    <>
                      Post breakfast, drive to Tso-Moriri via the remote
                      Changtang region. This is considered to be one of the most
                      beautiful drives in the entire Ladakh region.
                      <br />
                      On the way, visit Nyoma-Mahe bridge. Dinner and overnight
                      at Camp/Hotel in Tsomoriri.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 8: Tso Moriri - Leh"
                  text={
                    <>
                      After breakfast check out from the hotel, soak in the
                      beautiful views of the Tso-Moriri lake. The lake is ringed
                      by hills rising over 6,000 m (20,000 ft). "Changpas", the
                      nomadic migratory shepherds (pastoral community) of yak,
                      sheep, goats, and horses of Tibetan origin and who are
                      engaged in trade and work in caravans in Ladakh region,
                      are the main inhabitants of the area. Changpa herders use
                      the land of this valley as grazing ground and for
                      cultivation. On Route Visit to Hemis Monastery, Thiksay
                      Monastery, 3 Idiot School, after sightseeing back to Leh
                      and Overnight stay.
                    </>
                  }
                  img={img5} //Image
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 9 : Leh"
                  text={
                    <>
                      After Breakfast Full Day Free and Shopping and visit to
                      Local Market, Overnight stay in Hotel
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 8 : Leh Departure"
                  text={
                    <>
                      After breakfast, check out from the hotel & drop at
                      Airport for return journey with sweet memories of Ladakh
                      Tour
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
