import React, { Component } from "react";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/esm/Image";
import imgSelf from "../../../../img/imgself.jpg";

export default class MidSection extends Component {
  render() {
    return (
      <>
        <Container>
          <div className="AboutUs-heading">
            About Us - Travel with Love Kashmir Holidays
          </div>
          <Row>
            <Col lg={6}>
              <Image className="img-about-us" src={imgSelf} alt="" fluid />
            </Col>
            <Col lg={6} className="text-color-font">
              <p className="aboutus-header">About Us</p>
              <p className="space">
                "Travel With Nature" Registered by Department of Tourism Jammu
                and Kashmir Recognised by Jammu & Kashmir Tourism, Under
                registration no: REG/T/147/ADTG, Government of India as Inbound
                Tour Operator & Adventure Tour Operator
              </p>
              <p className="space">
                Travel with Nature a best and leading tour and travel agency
                based in Srinagar & New Delhi, founded in 2014 in Srinagar Jammu
                and Kashmir.
              </p>

              <p className="space">
                We are provide ourselves on 'no hidden extras' to ensure you
                know exactly what you're getting into and to ensure you have
                'peace of mind' when budgeting for your trip. We design your
                quote to include all the costs associated with your tour
                including: Accommodation, transportation (excluding flights),
                meals, tour guide fees and even the little things like permits
                and entrance fees.
              </p>
              <p className="whybook">WHY BOOK WITH US ?</p>
              <ul className="space ul-decor">
                <li>High Operating Standards</li>
                <li>Friendly and Knowledgeable Staff</li>
                <li> Personal travel consultants</li>
                <li>Jammu & Kashmir, Leh Ladakh Holiday</li>
                <li> Tours Luxury Hotels Booking</li>
                <li> Houseboats Booking</li>
                <li> Sri Amarnath Ji Yatra Package</li>
                <li>And Car and Transport Rental in Kashmir and Ladakh</li>
                <li> Tailor made holiday packages</li>
                <li>Private Tours for individuals and groups</li>
                <li> Guarantee Fixed Departures</li>
                <li> Flexibility in organising and bookings </li>
                <li> Recognised by Government of India</li>
                <li> Save time and effort</li>
                <li> Licensed and Recognised Tour Operator</li>
                <li> Adventure & Trekking</li>
              </ul>
              <p className="whybook">Totally Flexible</p>
              <p>
                We also provide custom made itineraries depending on the
                specific needs of the clients. We simply need the month of
                travel, places to visit and the number of persons traveling. Our
                totally flexible itineraries could be followed individually or
                combined to make a full package.
              </p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
