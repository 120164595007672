import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionHimachal";

import img1 from "../../../../img/himachal/bus-manali.jpg";
import img2 from "../../../../img/himachal/solangvalley.jpg";
import img3 from "../../../../img/himachal/hadimba-devi-temple-manali.jpg";
import CarousalHimachal from "../CarousalHimachal";

export default class Himachal_Tour_1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalHimachal />
        <Container>
          <PackageTitleDetails
            title="Delhi Manali by Luxury Bus Tour"
            nights={5}
            day={6}
            destination={[
              "Delhi",
              "Manali by Bus ",
              "Solang Valley",
              "Rohtang Pass",
              " Manikaran",
              "Manali ",
              "Delhi",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 01 : Delhi - Manali (By Volvo Bus) Manali"
                  text={
                    <>
                      Arrive at Delhi. Catch up Volvo bus to start your journey
                      from Delhi boarding point and start your journey
                      <br />
                      Volvo Facilities : A/C Semi Sleeper, LCD T.V, Mineral
                      water, Blanket & Push Back Seats with Thigh Rests. (Luxury
                      Volvo)
                    </>
                  }
                  img={img1}
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 02 : Manali local sightseeing"
                  text={
                    <>
                      Arrival at Manali bus station, meet the driver & transfer
                      to hotel. Check-in at the hotel. Later carry out the local
                      sightseeing tour in Manali to cover a lot of beautiful
                      places like the Manu Temple, Hadimba Devi Temple, the
                      Vashisht Kund in the Vashisht village followed by some
                      shopping and strolling in the evening. Return back to the
                      hotel and wind up the day by an overnight stay.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03 : Manali to Rohtang Pass or Solang Valley"
                  text={
                    <>
                      Early morning breakfast and get ready to hit the best
                      excursions from Manali on a drive. Post reaching the Snow
                      View Point or Rohtang Pass, you will experience the
                      several adventure sports and also the beautiful natural
                      surroundings followed by the Solang Valley that has the
                      ropeway ride awaiting. Post this, you will return to the
                      hotel and stay overnight!
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 04 : Manali - Manikaran - Via Kullu - Manali"
                  text={
                    <>
                      After breakfast, check out of your hotel in Manali and
                      drive to Manikaran, which is situated about 75 kms from
                      Kullu. Manikaran is essentially a sacred pilgrimage place,
                      especially for the Hindus. It is believed that the Lord
                      Manu recreated human life in Manikaran after the severe
                      flood. The place is replete with several temples and a
                      gurudwara. After the sightseeing in Manikaran, drive to
                      Kullu for a half day sightseeing tour. Dinner and
                      overnight stay at Manali.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05: Manali - Delhi (By Volvo Bus)"
                  text={
                    <>
                      Morning free for own activities. About noon, check-out
                      from hotel and transfer to Volvo Stand to board the Volvo
                      back to Delhi. Overnight on board.
                      <br />
                      Note : Volvo will depart from Manali stand at about 4.30
                      PM and reporting time will be 4 PM
                    </>
                  }
                  img={img3}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  title="Day 06 : Delhi"
                  text={
                    <>
                      Arrive Delhi Volvo stand at about 6-7 AM. Trip end here
                      with happy memories.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
