import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionKerala from "../InclusionExclusionKerala";

import img1 from "../../../../img/South/munnar1.jpg";
import img2 from "../../../../img/South/alleyhouseboat.jpg";
import CarouselKerala from "../KeralaCarousal";

export default class SouthIndia_Tour_1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <PackageTitleDetails
            title="Munnar with Alleppey Houseboat"
            nights={3}
            day={4}
            destination={[
              "Cochin",
              "Munnar",
              "Alleppey",
              "Houseboat",
              "Cochin",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrive Cochin to Munnar(140kms-4hrs)"
                  text={
                    <>
                      Our representative will receive you in the Cochin
                      International Airport and begin your munnar honeymoon tour
                      at the most famous Hill station of Kerala, Munnar. On the
                      way visiting Cheyapara and Valara waterfall. On arrival,
                      check at the hotel and unwind for the rest of the day.
                      Today your stay would be in Munnar.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 2 : Munnar sightseeing"
                  text={
                    <>
                      Today start your munnar honeymoon exploring the famous
                      hill stations of Munnar. Your tour will include Anaimudi,
                      South India’s highest peak, the Christ Church, the
                      Evarikulam National Park, the Rajamala Hills, Upper
                      Station along with the Mattupetty Dam & Kundala Dam.
                      Travelers can indulge in activities such as boating and
                      horse riding here. Today your stay would be in Munnar.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Munnar - Alleppey Houseboat (180kms-5hrs)"
                  text={
                    <>
                      Following breakfast, embark on an enchanting overnight
                      backwater cruise in traditionally decorated houseboats.
                      While cruising into these floating luxury villas, let your
                      eyes pay for the rare and the odd – skim past Chinese
                      fishing nets, bowed down paddy areas, neighbourhood
                      inhabitants engaged in making coir goods, rustic homes,
                      ancient temples along with swaying coconut groves. Food
                      will be served onboard. Today your stay would be at
                      Houseboat.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 4 : Cochin Departure(120kms-3hrs)"
                  text={
                    <>
                      Today is the final day of your Munnar Honeymoon Trips.
                      After morning breakfast, move to Cochin International
                      airfield for the flight back home, feeling satisfied with
                      a wonderful vacation you spent at the God’s Own Country.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionKerala />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
