import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageDetail2 from "./PackageDetail";

export default class SrinagarTour_2 extends Component {
  render() {
    return (
      <>
        <NewCarousel />
        <PackageDetail2 />
        <TourPackages />
      </>
    );
  }
}
