import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionKerala from "../InclusionExclusionKerala";

import img1 from "../../../../img/South/munnar-honeymoon.jpg";
import img2 from "../../../../img/South/periyar-wildlife-sanctuary.jpg";
import img3 from "../../../../img/South/kerala-honeymoon.jpg";
import CarouselKerala from "../KeralaCarousal";

export default class SouthIndia_Tour_4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <PackageTitleDetails
            title="Kerala Honeymoon Package"
            nights={6}
            day={7}
            destination={[
              "Cochin",
              "Munnar",
              "Thekkady",
              "Alleppy Houseboat",
              "Kovalam",
              "Trivandrum",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrival Cochin – Munnar (140km-4hrs)"
                  text={
                    <>
                      Welcome to God’s own Country, upon arrival our
                      representative will receive you in the Cochin
                      International Airport and drive down to Munnar, the place
                      where the unfolded green tea carpet is waiting for you.
                      Munnar is beautiful and a haven of peace and serenity –
                      the idyllic tourist destination. Munnar is the entryway to
                      the Hills of South India. En-route you can stop to watch
                      the beautiful waterfalls of Cheeyappara and Valara and
                      later reach and check-in at the hotel. Today your stay
                      would be in munnar.
                    </>
                  }
                  img={img1}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  title="Day 02: Munnar Sightseeing"
                  text={
                    <>
                      Relish your breakfast, visit the Eravikulam National Park
                      where you can see the well famed Nilagiri Thar, Later
                      proceed to Mattupetty & Kundala Dam where you can enjoy
                      boating through the reservoir flanked with tea garden on
                      one side and boundless Grassland on the other. The Echo
                      Point, renowned for its echo phenomenon is a popular
                      stopping point for the tourists, Also visit Shooting Point
                      and Tea Museum. Today your stay would be in Munnar.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03: Thekkady Wildlife Tour (140km-4hrs)"
                  text={
                    <>
                      Take breakfast and after that drive to Thekkady. Thekkady
                      offers diverse attractions wildlife sanctuary, hill
                      station and a spice plantation. Therefore it's was very
                      popular among nature lovers and adventure enthusiasts.
                      Reach and check in at the hotel. Freshen up and unwind
                      with comforts of your room. Later drive to Periyar
                      National Park that's the most attractive feature of
                      Thekkady. Enjoy a wildlife cruise over the Periyar Lake to
                      see wild life animals. You may also enjoy other delightful
                      and thrilling activities like spice plantation tour,
                      bamboo rafting, jungle trekking and elephant rides. Don't
                      forget to make a visit to local spice market for buying
                      handicrafts and some garden fresh spices. Today your stay
                      would be in Thekkady.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 04: Alleppy Houseboat (140km-4hrs)"
                  text={
                    <>
                      After staying with the nature, relish the morning
                      breakfast and proceed to Alleppey. Check in to the
                      houseboat which Is an indigenously developed and designed
                      private vessel with fully furnished spacious bath attached
                      bed rooms, hygiene kitchen, dining area and private front
                      deck to catch the beauty of the back waters. Cruise
                      through the Coconut fringed narrow canals, and the
                      exceptional life of the backwater. Individuals and above
                      all the nature around you'll make long lasting impressions
                      on your thoughts. Today your stay would be in Houseboat.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05: Kovalam Beach Honeymoon (180km-5hrs)"
                  text={
                    <>
                      After relishing traditional breakfast, check out of the
                      houseboat and move to Kovalam- the internationally
                      renowned beach destination. At noon, check in at the
                      resort. Spend the rest of the day at this hands fringed
                      beach. An enormous rocky promontory on the beach makes its
                      waters absolutely safe for swimming and water sports.
                      Today your stay would be in Kovalam.
                    </>
                  }
                  img={img3}
                />
              </Row>
              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 06: Tour of Trivandrum"
                  text={
                    <>
                      After morning breakfast proceed for the sightseeing of
                      Trivandrum. The temple of Anantha, the Sri Padmanabhaswami
                      Temple has become the most religious icon of the city.
                      Other must visit places are Veli Tourist Village, that has
                      a superb waterfront park with a vast collection of aqua
                      entertainment facilities such as fishponds, wading pools
                      and landscaped backyard. Shangumugham Beach where one can
                      observe the amazing sunset. Today your stay would be in
                      Kovalam.
                    </>
                  }
                />
              </Row>
              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 07: Kovalam to Trivandrum Departure (40km-1hrs)"
                  text={
                    <>
                      Enjoy the breakfast, today is the conclusion of your
                      Kerala Honeymoon. Pack your baggage with a promise to
                      re-visit once more to this part of the world. Proceed on
                      time in Trivandrum Airport to board flight home.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionKerala />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
