import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionLehLadakh";

import img1 from "../../../../img/leh/lamayuru1.jpg";
import img2 from "../../../../img/leh/kargil2.jpg";
import img3 from "../../../../img/leh/khardungla.jpg";
import img4 from "../../../../img/leh/diskit-buddha.jpg";
import img5 from "../../../../img/leh/hemis-monastery.jpg";
import LadakhCarousal from "../CarousalLadakh";

export default class Ladakh_Tour_5 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <PackageTitleDetails
            title="Leh Kargil with Nubra Turtuk & Pangong Lake"
            nights={7}
            day={8}
            destination={[
              "Leh",
              "Magnetic Hill",
              "Sangam",
              "Lamayuru",
              "Kargil",
              "Leh",
              "Khardungla",
              "Nubra",
              "Hunder",
              "Turtuk",
              "Hunder",
              "Pangong",
              "Leh",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrive Leh - day free to relax and acclimatize"
                  text={
                    <>
                      On your arrival at Leh airport our representative will
                      meet & greet and transfer you to the Hotel.
                      <br />
                      We recommend you completely relax for the rest of the day
                      to enable your body to acclimatize to the rarefied air at
                      the high altitude. Leh is situated at the height of 11563
                      feet.
                      <br />
                      Lunch and dinner is served at the hotel. Overnight stay at
                      the hotel.
                    </>
                  }
                  img={img1} //
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 2 : Leh - Lamayuru - Kargil"
                  text={
                    <>
                      Today we proceed to Kargil via Fotu La Pass and Namki La
                      pass. Kargil is known as the location of the
                      Indo-Pakistani border war of 1999 En route we visit
                      Patthar Sahib Gurudwara - a beautiful Gurudwara sahib
                      constructed in the memory of Guru Nanak, Magnetic Hill –
                      also known as Gravity Hill where vehicles defy the force
                      of gravity, Sangam of the rivers Indus & Zanskar - two of
                      Himalaya's great rivers; Zanskar and Indus merge to form a
                      spectacular view, Moon land - the land resembles that of
                      moon, Lamayuru Monastery - one of the largest and oldest
                      gompas in Ladakh. In the evening we arrive at Kargil.
                    </>
                  }
                />
              </Row>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}

              <Row>
                <PackageWithImage
                  title="Day 3 : Kargil - Lamayuru- via Alchi Monastery (Indus Zanskar River Conference ) - Magnetic Hill - Leh ( Approx 6 hrs)"
                  text={
                    <>
                      After Breakfast check out hotel and drive to Leh, enroute
                      viewing / visiting the dramatically located Lamayuru
                      monastery and the adjoining "Moonland" hills forming its
                      backdrop. after visiting the 11th century Alchi oldest
                      surviving monastic heritage of Ladakh - a UNESCO listed
                      heritage site. Driving further on along the Indus, visit
                      Sanagm (confluence of Indus & Zanskar Rivers), (River
                      RAFTING POINT - you can rafting here 2 or 3 hrs) after
                      drive to Magnetic Point, Pathar Sahib Gurudwara & Hall of
                      Fame (museum of military heritage).
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Magnetic hill to marvel at the amazing natural wonder.
                      Also pay respects to Pathar Sahib Gurudwara- constructed
                      in memory of Guru Nanak Dev ji- the founder of Sikh
                      religion and the first guru . Continue your road journey.
                      On arrival in Leh, visit the Hall of Fame Museum a war
                      memorial by the Indian Army that was founded in the memory
                      of Indian soldiers who laid lives in Indo-Pak wars.
                      Complete the check-in formalities at your hotel. Overnight
                      stay in Leh.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 4 : Leh - Khardungla - Nubra - Hunder (Apporx 5 hrs)"
                  text={
                    <>
                      Early morning proceed to Nubra Valley via the Worlds
                      Highest Motorable road (5602 Mts) Khardungla pass.
                      Khardung la Pass (18,380ft above sea level): <br />
                      It is not very often that one gets to drive on a road at
                      an altitude of 18,380 feet. That is where Khardungla Pass,
                      meaning the Pass of Lower Castle, is located on the way
                      from Leh to the Nubra Valley in Ladakh.
                    </>
                  }
                  img={img3}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  text={
                    <>
                      It is the highest motorable road in the world as signs put
                      up by the Border Roads Organization proudly proclaim. Post
                      the Diskit Monastery, proceed for backward journey to Leh
                      Arrive Nubra Valley and proceed for a visit to the White
                      Sand Dunes at Hunder. You can also enjoy a camel ride here
                      (Camel ride on direct payment basis).
                      <br />
                      <br />
                      Nubra Valley: Popularly called the orchard of Ladakh,
                      Nubra valley offers a graceful view of yellow and pink
                      wild roses in summer and a carpet of wild lavender in the
                      post-monsoon season. You will definitely be overwhelmed to
                      see the Bactrian Camels (Shaggy double hump Camel) around
                      sand dunes. Thereafter, check in at comfortable Camp or
                      Hotel. Evening at leisure. Dinner & overnight stay at the
                      camp or hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 5 : Hunder - Turtuk - Hunder (Approx 2 - 3 hrs)"
                  text={
                    <>
                      After early Breakfast, move towards Highest Motorable Road
                      of The World, KhardungLa Pass. Have a cup of Tea there,
                      get yourself clicked with the Khardungla Signboard by BRO
                      & proceed towards Turtuk- the Land of Baltis, which was
                      under control of Pakistan before 1971 War between the two
                      nations. You may like to visit 16 century Mosque and
                      Minaret, Royal house of Yabgo dynasty, Ruin palace of Pun
                      Khar, Historic polo ground. Overnight stay at Homestay
                      /Camp.
                    </>
                  }
                  img={img4} //Image
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 6 : Hunder - Nubra Valley to Pangong Lake via Shayok"
                  text={
                    <>
                      After breakfast at hotel we drive to next destination
                      pangong lake via Shyok and onto Tang Tse and Spangmik (
                      Pangong).
                      <br />
                      <br />
                      It's a short cut and it's a beautiful route. The road
                      follows the Shyok river, and climbs up a mountain before
                      finally descending into Shayok village. It took us less
                      than an hour to reach Shayok, and we were delighted at the
                      sight of the rather big village surrounded by barren
                      mountains, the entire setting being very picturesque.
                      Afternoon we reach at Pangong lake and enjoy your day at
                      pangong lake. Overnight stay at camp.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 7 : Pangong - Changla Pass - Hemis/Thiksey - Leh (By Road 165Km/approx 5-6hrs)"
                  text={
                    <>
                      After breakfast, you will leave Pangong/Tangtse and get
                      back on the Leh route across Chang La. The Chang La is a
                      high mountain pass in Ladakh at an elevation of 5,391.3024
                      m or 17,688.000 ft in the Ladakh Ranges between Leh and
                      the Shyok River valley. It is one of the highest
                      motor-able road in the world. Chang La is approached from
                      Leh via Kharu and Sakti village by an asphalt road.
                    </>
                  }
                  img={img5} //Image
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Upon reaching Karu, you will drive across the Indus valley
                      and visit the famous Hemis Monastery, the biggest
                      monastery in Ladakh. Continue, drive to Leh, enroute
                      visiting the picturesque Hemis and Thiksey monasteries.
                      Overnight stay at a hotel.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 8 : Leh Departure"
                  text={
                    <>
                      After breakfast, check out from the hotel & drop at
                      Airport for return journey with sweet memories of Ladakh
                      Tour
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
