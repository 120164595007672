import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionLehLadakh";
import img1 from "../../../../img/leh/shantistupa.jpg";
import img2 from "../../../../img/leh/lamayuru1.jpg";
import img3 from "../../../../img/leh/khardungla.jpg";
import img4 from "../../../../img/leh/diskit-buddha.jpg";
import img5 from "../../../../img/leh/pangong-img.jpg";
import LadakhCarousal from "../CarousalLadakh";

export default class Ladakh_Tour_3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <PackageTitleDetails
            title="Lamayuru with Nubra & Pangong Lake Tour"
            nights={5}
            day={6}
            destination={["Leh", "Monastery Tour", "Nubra", "Pangong", "Leh"]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrive Leh - day free to relax and acclimatize"
                  text={
                    <>
                      On your arrival at Leh airport our representative will
                      meet & greet and transfer you to the Hotel.
                      <br />
                      We recommend you completely relax for the rest of the day
                      to enable your body to acclimatize to the rarefied air at
                      the high altitude. Leh is situated at the height of 11563
                      feet.
                      <br />
                      Lunch and dinner is served at the hotel. Overnight stay at
                      the hotel.
                    </>
                  }
                  img={img1} //
                />
              </Row>

              {/* -----------------------------------------------Day `1`----------------------------------------- */}

              <Row>
                <PackageWithImage
                  title="Day 2 : Leh -Shyam Valley & Lamayuru Monastery"
                  text={
                    <>
                      After breakfast drive to Lamayuru enroute Magnetic hill
                      and Gurudwara Pathar Sahib. visit the mesmerizing Moon
                      landscapes and the Lamayuru monastery (one of the oldest
                      Monastery in central Ladakh).
                      <br />
                      <br />
                      After Lamayuru Sightseeing back to leh via Sangam you can
                      enjoy rafting on Indus river from Phey to Nimmo at an
                      additional cost.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      The Sangam is the place called Nimu where the Zanskar
                      river and Indus rivers merge.
                      <br />
                      Magnetic Hill is the place where you will see your car,
                      with its engine turned off, move upwards on a steep slope.
                      It almost sounds unbelievable.
                      <br />
                      Gurdwara Patthar Sahib located at a distance of 40 kms
                      from Leh, was constructed in 1571, in memory of Guru Nanak
                      to commemorate his visit to this region.
                      <br />
                      Hall of Fame is a must visit for every patriotic Indian.
                      It has been built by Indian army in memory of soldiers who
                      lost their lives in Kargil war.
                      <br />
                      After Sightseeing back to Leh Market and visit to Shanti
                      Stupa after back to Hotel, Dinner and overnight stay at
                      the hotel.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Leh - Khardungla - Nubra - Hunder (Apporx 5 hrs)"
                  text={
                    <>
                      Early morning proceed to Nubra Valley via the Worlds
                      Highest Motorable road (5602 Mts) Khardungla pass.
                      Khardung la Pass (18,380ft above sea level): It is not
                      very often that one gets to drive on a road at an altitude
                      of 18,380 feet. That is where Khardungla Pass, meaning the
                      Pass of Lower Castle, is located on the way from Leh to
                      the Nubra Valley in Ladakh.
                      <br />
                    </>
                  }
                  img={img3}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  text={
                    <>
                      It is the highest motorable road in the world as signs put
                      up by the Border Roads Organization proudly proclaim. Post
                      the Diskit Monastery, proceed for backward journey to Leh
                      Arrive Nubra Valley and proceed for a visit to the White
                      Sand Dunes at Hunder. You can also enjoy a camel ride here
                      (Camel ride on direct payment basis).
                      <br />
                      <br />
                      Nubra Valley: Popularly called the orchard of Ladakh,
                      Nubra valley offers a graceful view of yellow and pink
                      wild roses in summer and a carpet of wild lavender in the
                      post-monsoon season. You will definitely be overwhelmed to
                      see the Bactrian Camels (Shaggy double hump Camel) around
                      sand dunes. Thereafter, check in at comfortable Camp or
                      Hotel. Evening at leisure. Dinner & overnight stay at the
                      camp or hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 4 : Hunder - Pangong (Apporx 5 hrs)"
                  text={
                    <>
                      After breakfast at hotel we drive to next destination
                      pangong lake via Shyok and onto Tangtse and Spangmik (
                      Pangong). It’s a short cut and it’s a beautiful route en
                      route visit Diskit monastery and Maitreya Buddha . after
                      drive to Pangong by Shyok river, and climbs up a mountain
                      before finally descending into Shayok village.
                      <br />
                      <br />
                      It took us less than an hour to reach Shayok, and we were
                      delighted at the sight of the rather big village
                      surrounded by barren mountains, the entire setting being
                      very picturesque. Afternoon we reach at Pangong lake
                      enjoy. Around 2 PM drive back to Leh via Changla Pass
                      Evening check in Hotel and Overnight Stay.
                    </>
                  }
                  img={img4} //Image
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 5 : Pangong - Changla Pass - Hemis/Thiksey - Leh (By Road 165Km/approx 5-6hrs)"
                  text={
                    <>
                      After breakfast, you will leave Pangong/Tangtse and get
                      back on the Leh route across Chang La. The Chang La is a
                      high mountain pass in Ladakh at an elevation of 5,391.3024
                      m or 17,688.000 ft in the Ladakh Ranges between Leh and
                      the Shyok River valley. It is one of the highest
                      motor-able road in the world. Chang La is approached from
                      Leh via Kharu and Sakti village by an asphalt road.
                      <br />
                      <br />
                      Upon reaching Karu, you will drive across the Indus valley
                      and visit the famous Hemis Monastery, the biggest
                      monastery in Ladakh. Continue, drive to Leh, enroute
                      visiting the picturesque Hemis and Thiksey monasteries.
                      Overnight stay at a hotel.
                    </>
                  }
                  img={img5} //Image
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 5 : Leh Departure"
                  text={
                    <>
                      After breakfast, check out from the hotel & drop at
                      Airport for return journey with sweet memories of Ladakh
                      Tour
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
