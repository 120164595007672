import React from "react";
import img1 from "../../img/1-winter.jpg";
import img2 from "../../img/2.jpg";
import img3 from "../../img/3.jpg";
import img4 from "../../img/4.jpg";
import img5 from "../../img/5.jpg";
import img6 from "../../img/6.jpg";
import img6Winter from "../../img/6-winter.jpg";
import img7 from "../../img/7.jpg";
import img8 from "../../img/8.jpg";
import img9 from "../../img/9.jpg";
import img10 from "../../img/10.jpg";
import img11 from "../../img/11.jpg";
import img12 from "../../img/12.jpg";
import img13 from "../../img/13.jpg";
import img13manali from "../../img/13-manali.jpg";
import img15 from "../../img/15.jpg";
import img16 from "../../img/16.jpg";
import img0 from "../../img/dal.jpg";
import Carousel from "react-bootstrap/Carousel";
import "./index.css";

export default function NewCarousel() {
  return (
    <>
      <Carousel className="carousel-wrapper">
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img0} alt="First slide" />
          <Carousel.Caption>
            <h3>Dal Lake Winter View - Srinagar</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img1} alt="First slide" />
          <Carousel.Caption>
            <h3>Dal Lake Winter View - Srinagar</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img2} alt="Second slide" />
          <Carousel.Caption>
            <h3>Tulip Garden - Srinagar</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img3} alt="Third slide" />
          <Carousel.Caption>
            <h3>Gulmarg - Winter Activities</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img4} alt="Third slide" />
          <Carousel.Caption>
            <h3>Khyber Luxury Hotels - Gulmarg</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img5} alt="Third slide" />
          <Carousel.Caption>
            <h3>Pahalgam - Mountains & River View</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img6} alt="Third slide" />
          <Carousel.Caption>
            <h3>Sonamarg - Thajwas Glacier View</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={img6Winter}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Sonamarg - Thajwas Glacier View Winter</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img7} alt="Third slide" />
          <Carousel.Caption>
            <h3>Ladakh - Lamayuru Monastery</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img8} alt="Third slide" />
          <Carousel.Caption>
            <h3>Stanka Monastery - Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img9} alt="Third slide" />
          <Carousel.Caption>
            <h3>Hemish Monastery - Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img10} alt="Third slide" />
          <Carousel.Caption>
            <h3>Pangong Tso Lake - Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img11} alt="Third slide" />
          <Carousel.Caption>
            <h3>Diskit Monastery - Nubra Valley Ladakh</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img12} alt="Third slide" />
          <Carousel.Caption>
            <h3>Nubra - Hunder - Sundune Camel Ride</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img13} alt="Third slide" />
          <Carousel.Caption>
            <h3>Leh to Manali by Road</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={img13manali}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Hot Ballon Manali</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img16} alt="Third slide" />
          <Carousel.Caption>
            <h3>Hawa Mahal - Jaipur - Rajasthan</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img className="d-block w-100 img" src={img15} alt="Third slide" />
          <Carousel.Caption>
            <h3>Kerala - South India</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
