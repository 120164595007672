import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import TourPackages from "../../../Homepage/components/TourPackages";
import FormBooking from "../../../../components/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import InclusionExclusion from "../../../../components/ExclusionInclusion";
import img1 from "../../../../img/Srinagar1/mugalgarden.jpg";
import img2 from "../../../../img/gulmarg-gondla.jpg";
import img3 from "../../../../img/Srinagar1/betaab-valley.jpg";
export default class Tour_4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <NewCarousel />
        <Container>
          <div className="PackageDetailswrapper">
            <p className="Heading-houseboat">
              Gulmarg Pahalgam Tour (Overnight Stay)
            </p>
            <p className="DurationOfPackage">
              <span className="darker"> Duration :</span> 4 Nights / 5 Days
            </p>

            <p className="DurationOfPackage">
              {" "}
              <span className="darker"> Destination :</span> Srinagar - Gulmarg
              - Pahalgam - Srinagar
            </p>
            <hr />
          </div>
          <Row>
            <Col lg={9} md={6}>
              {/* -----------------------------------------------Day `1`----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">Day 01: Srinagar arrival</p>
                  <p className="PackageDescrip">
                    Our services will start with your arrival at Srinagar
                    airport, you will be met by our representative and transfer
                    to the Houseboats, check in Houseboats, after freshup visit
                    to Mughal Gardens consists of mainly of three gardens
                    -Chashme shahi garden -Nishat Garden -Shalimar Garden
                    .Shankaracharya Temple Takht-e-Suleiman hill, the
                    Shankaracharya Temple is dedicated to Lord Shiva. It is
                    supposed to be the oldest shrine of the Kashmir valley,
                    Hazratbal Shrine- Situated opposite the Nishat Bagh on the
                    banks of Dal Lake,Parimahal. In the afternoon Shikara ride
                    on The Dal Lake to enjoy the beauty that God has graced this
                    city, after sightseeing check in Houseboats and Overnight
                    stay
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img1} alt="" fluid />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 02: Srinagar- Gulmarg (Approx 2 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast at the hotel, leave for a day excursion to
                    Gulmarg. The drive past colorful villages and rice fields
                    gives you an insight of the rich cultural past of Kashmir.
                    Gulmarg is one of the most beautiful hill stations in the
                    world and also called Meadows of Flowers.
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="PackageDescrip">
                    It has the world’s highest golf course (world’s highest),
                    and Gulmarg is also famous for winter sports like skiing
                    etc. But the most popular thing to include in the tour
                    package in Kashmir is the Gondola Cable Car Ride to
                    Khilanmarg. The 08 minutes of ropeway will take you past
                    colorful shepherd hutments to the lower ridge of the
                    Apharwat peak. From here, you can click lots of photos of
                    the nearby mountain ranges that stand tall like sentinels
                    guarding the rich natural beauty of Kashmir valley. After
                    sightseeing check in Hotel and Overnight stay in Gulmarg.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img2} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 3----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">
                    Day 03: Gulmarg - Pahalgam (Approx 5 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After breakfast check out Hotel and drive to Pahalgam.
                    Valley of Shepherds. Enroute you will have an rare
                    opportunity to visit the Saffron fields. Also visit
                    Avantipura Ruins & Anantnag Sulpher Springs enroute, Arrive
                    and proceed to your hotel. Visit to Chandanwadi, enjoy
                    leisurely walks through the pine forest along the River
                    Lidder (at your own cost), The snow Point (14 Km) at your
                    own cost or enjoy pony ride at your own cost .<br />
                    <br />
                    After Sightseeig check in Hotel and Overnight stay in Hotel
                    Note : In Pahalgam outside vehicles are not allowed for
                    sightseeing. Only local taxis can roam around within
                    Pahalgam city. All outside vehicles will pick up & drop from
                    Hotel
                  </p>
                </Col>
              </Row>
              {/* -----------------------------------------------Day 2----------------------------------------- */}
              <Row>
                <Col lg={8} md={6}>
                  <p className="titlePackage">
                    Day 04 : Pahalgam - Srinagar (Approx 5 Hours Drive- One Way)
                  </p>
                  <p className="PackageDescrip">
                    After Breakfast check out hotel and drive back to Srinagar,
                    Enroute visit Saffron fields, Awantipur ruins (1100 yrs old
                    temple of Lord Vishnu built by King Awantiverma), see the
                    beautiful countryside, lots of rice fields. Arrive at
                    Srinagar and check in Hotel, and half day srinagar Local
                    Market and sightseeing, and evening drop to the hotel
                    overnight stay.
                  </p>
                </Col>
                <Col lg={4} md={6}>
                  <Image src={img3} alt="" fluid />
                </Col>
              </Row>
              {/* -----------------------------------------------Day 4----------------------------------------- */}
              <Row>
                <Col lg={12} md={6}>
                  <p className="titlePackage">Day 05: Srinagar Airport Drop</p>
                  <p className="PackageDescrip">
                    After breakfast transfer to Srinagar airport to board flight
                    for onwards destination with sweet memories of the trip.
                  </p>
                </Col>
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusion />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
