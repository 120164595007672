import React, { Component } from "react";
import NewCarousal from "../../../components/Carousel";
import TailourForm from "../../../components/TailourMadeForm";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";
import PackageDisplay from "../../../components/Package";
import kerala1 from "../../../img/South/munnar.jpg";
import kerala2 from "../../../img/South/elephant.jpg";
import kerala3 from "../../../img/South/andman.jpg";
import kerala4 from "../../../img/South/kerala-honeymoon.jpg";
import kerala5 from "../../../img/South/sotuh.jpg";
import kerala6 from "../../../img/South/madhurai-temple.jpg";
import CarouselKerala from "./KeralaCarousal";

export default class KeralaHolidays extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <div className="JK_Title">Kerala - South India Holiday Packages</div>
        </Container>
        <Container className="mt-4 text-center">
          <Row>
            <PackageDisplay
              link="/southIndia/Tour_1"
              title="Munnar with Alleppey Houseboat"
              image={kerala1}
              nights={3}
              day={4}
              destination={[
                "Cochin",
                "Munnar",
                "Alleppey",
                "Houseboat",
                "Cochin",
              ]}
            />
            <PackageDisplay
              link="/southIndia/Tour_2"
              title="Best of Kerala Holiday Tour"
              image={kerala2}
              nights={4}
              day={5}
              destination={["Cochin", "Munnar", "Thekkady", "Cochin"]}
            />
            <PackageDisplay
              link="/southIndia/Tour_3"
              title="Andaman Honeymoon Package"
              image={kerala3}
              nights={4}
              day={5}
              destination={[
                "Port Blair",
                "Havelock Island",
                "Neil Island",
                "Port Blair",
              ]}
            />

            <PackageDisplay
              link="/southIndia/Tour_4"
              title="Kerala Honeymoon Package"
              image={kerala4}
              nights={6}
              day={7}
              destination={[
                "Cochin",
                "Munnar",
                "Thekkady",
                "Alleppy Houseboat",
                "Kovalam",
                "Trivandrum",
              ]}
            />
            <PackageDisplay
              link="/southIndia/Tour_5"
              title="South India Hills Station Tour"
              image={kerala5}
              nights={6}
              day={7}
              destination={[
                "Coimbatore",
                "Ooty Hill Sttion",
                "Kodaikanal Hill Station",
                "Munnar Hill Station",
                "Cochin",
              ]}
            />
            <PackageDisplay
              link="/southIndia/Tour_6"
              title="South India Temple Tour"
              image={kerala6}
              nights={11}
              day={12}
              destination={[
                "Chennai",
                "Mahabalipuram",
                "Pondicherry",
                "Chidambaram",
                "Tanjore",
                "Trichy",
                "Madurai",
                "Rameshwaram",
                "Kanyakumari",
                "Trivandrum",
              ]}
            />
          </Row>
          <Container className="mt-5">
            <TailourForm />
          </Container>
        </Container>
      </>
    );
  }
}
