import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionKerala from "../InclusionExclusionKerala";

import img1 from "../../../../img/South/kanchpuram.jpg";
import img2 from "../../../../img/South/mahabalipuram.jpg";
import img3 from "../../../../img/South/meenakshitemple.jpg";
import img4 from "../../../../img/South/madhurai-temple.jpg";
import img5 from "../../../../img/South/rameshwaram.jpg";
import CarouselKerala from "../KeralaCarousal";

export default class SouthIndia_Tour_6 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarouselKerala />
        <Container>
          <PackageTitleDetails
            title="South India Temple Tour"
            nights={11}
            day={12}
            destination={[
              "Chennai",
              "Mahabalipuram",
              "Pondicherry",
              "Chidambaram",
              "Tanjore",
              "Trichy",
              "Madurai",
              "Rameshwaram",
              "Kanyakumari",
              "Trivandrum",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 1: Arrival Chennai"
                  text={
                    <>
                      On arrival at Chennai airport/railway station in the
                      morning. Meet our representative and transfer to the
                      hotel. Later proceed for city tour of Chennai. Chennai,
                      the capital city, popularly known as the'Gateway to the
                      South' represents a culture that differs from that of
                      northern India. The places of interest are Kalakshetra, a
                      school of art and music which encourages exponents of
                      Bharatanatyam, Carnatic music, traditional weaving and
                      designing. Fort St. George built in 1653 during the
                      British reign in India, now houses the Secretariat and the
                      Legislative Assembly of Tamil Nadu. Sri Parthasaratha
                      Temple, built by the Pallavas in the eighth century AD.
                      Kapaleeshwarar Temple, Mylapore also an 8th century
                      Pallava Temple dedicated to Lord Shiva. Later back to
                      hotel. Overnight stay at hotel in Chennai.
                    </>
                  }
                  img={img1}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  title="Day 2: Chennai - Kanchipuram - Mahabalipuram"
                  text={
                    <>
                      After breakfast, drive to Kanchipuram, 70 km west of
                      Mahabalipuram that is a town of thousand temples. Among
                      the seven sacred cities of Hindus, it was the capital of
                      the early Cholas as far back as the 2nd century BC. Beside
                      from temples, the city is also renowned for its silk
                      weavers, who settled here some 400 years back and have
                      given it an enviable reputation. Later on we shall move at
                      Mahabalipuram, driving south of Chennai on the shore which
                      is the old port of Pallava. The pallavas have generated
                      several marvellous monuments with sculptural buildings,
                      temples, Monolithic Rathas and Temples. Overnight stay at
                      hotel in Mahabalipuram.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3: Mahabalipuram Sightseeing"
                  text={
                    <>
                      After relishing morning breakfast, Proceed for sightseeing
                      of Mahabalipuram. Krishna Mandapam has a bas relief,
                      notable for its realistic representation. The world's
                      largest bass relief measuring 27m 9m contains figures of
                      gods, guys beasts, birds and a representation of the whole
                      creation. Shore Temple, one of the oldest in south dating
                      back to eighth century AD is a fantastic example of the
                      first phase of structural temples built in the Dravidian
                      style. The five rathe are a set of five monolithic
                      temples, each of which is created in various fashion.
                      Later Check in at the hotel. Overnight stay at hotel at
                      Mahabalipuram.
                    </>
                  }
                  img={img2}
                />
              </Row>

              {/* -------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 4: Mahabalipuram – Pondicherry (100km-3hrs)"
                  text={
                    <>
                      After breakfast, transfer to Pondicherry hotel. After
                      lunch city tour of Pondicherry. Even though the French
                      influence is progressively fading, Pondicherry retains
                      some Gallic accents. Pondicherry's main sightseeing places
                      are the Rai Nivas, the Sacred Heart Church and the Sri
                      Aurobindo Ashram. This ashram was founded in 1926 by Sri
                      Aurobindo after whose death the spiritual authority passed
                      to one of his disciples, a woman known as the Mother. The
                      Mother was also instrumental in founding the city of Dawn
                      Auroville, 10 km from Pondicherry main city. Its original
                      charter aimed to create a town of the future where
                      individuals from India and overseas could live and work
                      together in a spiritual environment. Evening back to your
                      hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 5: Pondicherry - Chidambaram (64 Km) - Kumbakonam (78 Km)"
                  text={
                    <>
                      Overnight at hotel. Morning after breakfast, transfer to
                      Kumbakonam, en route visit Chidambaram, the seat of the
                      Cosmic Dancer, dedicated to Lord Nataraja. Spread over a
                      place of 40 acres with gopuram on every side, the temple
                      is distinguished by the 5 sabhas or counts. The towering
                      gopurams, the tallest one on the north would be 42.4 m
                      have been carved on it with 108 dances presents of
                      Bharatanatyam. Overnight stay at hotel at Kumbakonam
                    </>
                  }
                  img={img3}
                />
              </Row>
              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 6: Kumbakonam - Tanjore (40km-1hr)"
                  text={
                    <>
                      Check out after an early breakfast and drive to Tanjore
                      and check in at the hotel that is chosen for your stay.
                      After resting for some time, head out for sightseeing
                      areas such as Art Gallery, Brihadeeswara Temple, Saraswati
                      Mahal Library and The Palace. Return to the hotel for your
                      overnight stay at Tanjore.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 7: Tanjore – Trichy (60km-1.5hrs)"
                  text={
                    <>
                      After breakfast in the hotel in Tanjore. Head out for a
                      complete day excursion to Trichy. In Trichy, you can visit
                      the attractions such as Thiruvanaikaval Temple, Rock Fort
                      Temple, Elakurichi and Valaloor. Following the visit,
                      return to Tanjore. Overnight stay at at Hotel in Tanjore.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 8: Trichy - Madurai (165km-4hrs)"
                  text={
                    <>
                      After breakfast, checkout from the hotel from Tanjore
                      hotel and drive down to Madurai. On arrival, check at the
                      hotel that is chosen. At the late mid-day, you can tour
                      around Madurai's attractions such as Thirumalai Nayak
                      Palace, Sri Meenakshi Sundareswarar Temple,
                      Thirupparankundram Temple, Alagar Koil and Gandhi Museum.
                      Following the tour is finished, come back at the hotel for
                      your overnight stay at Madurai.
                    </>
                  }
                  img={img4}
                />
              </Row>

              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 9: Madurai – Rameswaram (170km-4hrs)"
                  text={
                    <>
                      After an early breakfast, check out from your hotel in
                      madurai and drive to Rameswaram, upon arrival, check at
                      the hotel and rest. Afterwards, you can go for a tour in
                      areas such as Ramanathaswamy temple, Agnitheertham,
                      Tamjharoke Temple, Ramjharoka Temple, Dhanushkodi, and
                      Adam's Bridge. Return to the hotel after sightseeing for
                      an overnight stay at Rameswaram.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 10: Rameswaram – Kanyakumari (320km-7hrs)"
                  text={
                    <>
                      Just after relising breakfast proceed to Kanyakumari.
                      Kanyakumari is situated in the southern point Of the
                      Indian subcontinent and has significant as the place where
                      the spiritual leader Swami Vivekananda spent in meditation
                      upon a rock off of the coast. On arrival at Kanyakumari,
                      Checkin to the hotel. Later proceed for sightseeing of
                      Kanyakumari covering Devi Kanyakumari Temple, Suchithram
                      Temple, Vivekananda Memorial, Gandhidham and Trivenisagam
                      are a Few of the sightseeing attractions in Kanyakumari.
                      Enjoy the sunset and proceed back to hotel in Kanyakumari.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 11: Kanyakumari - Trivandrum (100km-3hrs)"
                  text={
                    <>
                      View early morning sunrise, After morning delicious
                      breakfast, Proceed to Trivandrum. Enroute visit
                      Padmanbhapuram Palace. Reach Trivandrum hotel. Later set
                      out on a sightseeing tour to Trivandrum. In todays
                      itinerary you will visit places like Padmanabhaswami
                      Temple, famous for its towering gopuras, temple arts and
                      paintings. The main pavilion of the temple stands imposing
                      with 400 wonderfully granite carved columns. Your
                      sightseeing tour will also include Napier Museum,
                      Zoological Park. After sunset view at Shunmugam beach
                      return back to your Hotel for your overnight stay.
                    </>
                  }
                  img={img5}
                />
              </Row>

              {/* ----------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 12: Trivandrum Departure"
                  text={
                    <>
                      After morning breakfast, move to Trivandrum International
                      airport for the flight back home with sweet memories
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionKerala />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
