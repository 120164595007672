import React from "react";

export default function PackageTitleDetails(props) {
  return (
    <>
      <div className="PackageDetailswrapper">
        <p className="Heading-houseboat">{props.title}</p>
        <p className="DurationOfPackage">
          <span className="darker"> Duration :</span> {props.nights} Nights /{" "}
          {props.day} Days
        </p>

        <p className="DurationOfPackage">
          {" "}
          <span className="darker"> Destination :</span>{" "}
          {props.destination.map((el) => " - " + el)}
        </p>
        <hr />
      </div>
    </>
  );
}
