import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import HouseBoat1 from "../../../../img/houseboat2a.jpg";
import HouseBoat2 from "../../../../img/houseboat2.jpg";
import HouseBoat3 from "../../../../img/houseboat1.jpg";
import HouseBoat4 from "../../../../img/houseboat3.jpg";

export default class CarousalHouseBoat extends Component {
  render() {
    return (
      <>
        <Carousel className="carousel-wrapper">
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img"
              src={HouseBoat1}
              alt="HouseBoat"
            />
            <Carousel.Caption>
              <h3>Rosemarry Houseboat Room</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img"
              src={HouseBoat2}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Shikara Ride Dal Lake</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img "
              src={HouseBoat3}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3>Vegtable Market in Dal Lake</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img"
              src={HouseBoat4}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3>Dal Lake Houseboat - Srinagar</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
}
