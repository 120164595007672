import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import TourPackages from "../Homepage/components/TourPackages";
import CarousalAdventure from "./Components/Carousel";
import MidSectionAdventure from "./Components/MidSection";
function GenericButton() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CarousalAdventure />
      <MidSectionAdventure />
      <TourPackages />
    </>
  );
}
export default GenericButton;
