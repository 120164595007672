import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./index.css";
import Image from "react-bootstrap/Image";
import img from "../../../../img/imgself.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default class WelcomeNote extends Component {
  render() {
    return (
      <Container>
        <h1 className="title">Welcome To Love Kashmir Holidays</h1>
        <Row className="contain-wrapper">
          <Col lg={6} md={12}>
            <Image src={img} fluid rounded />
          </Col>

          <Col lg={6} md={12}>
            <div className="second-heading">
              <div className="heading">OUR SERVICES</div>
              <div className="other-text">
                <p className="second-heading-text">
                  Travel With Nature”, the best travel agents in Kashmir, is
                  taking care of your travel needs under one roof making the
                  tour of our guests more secure, attractive, affordable and
                  fascinating.
                </p>
                <div className="red-color">
                  We have a wide collection of Kashmir Ladakh Tour Packages
                </div>
                <div className="tick">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg icon" />
                  Jammu with Vaishno Devi Tour
                </div>
                <div className="tick">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg icon" />
                  Kashmir Holiday Tours
                </div>
                <div className="tick">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg icon" />
                  Leh Ladakh Tours
                </div>
                <div className="red-color">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg red-color" />
                  Luxury Hotels Booking
                </div>

                <div className="tick">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg icon" />
                  Housebaots Booking
                </div>
                <div className="red-color">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg red-color" />
                  Sri Amarnath Ji Yatra Package
                </div>
                <div className="tick">
                  <FontAwesomeIcon icon={faCheck} className="fa-lg icon" />
                  And Car and Transport Rental in Kashmir and Ladakh
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
