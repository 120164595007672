import React, { Component } from "react";
import TailourForm from "../../../components/TailourMadeForm";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";
import PackageDisplay from "../../../components/Package";
import Rajasthan1 from "../../../img/Rajasthan/tajmahal.jpg";
import Rajasthan2 from "../../../img/Rajasthan/pushkar.jpg";
import Rajasthan3 from "../../../img/Rajasthan/ranthambore-tiger.jpg";
import Rajasthan4 from "../../../img/Rajasthan/dallake-view.jpg";
import Rajasthan5 from "../../../img/Rajasthan/jaipur-elephant-ride.jpg";
import Rajasthan6 from "../../../img/Rajasthan/udaipur.jpg";
import CarousalRajasthan from "./CarousalRajasthan";

export default class RajasthanHolidays extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalRajasthan />
        <Container>
          <div className="JK_Title">Rajasthan & Kashmir Holiday Packages</div>
        </Container>
        <Container className="mt-4 text-center">
          <Row>
            <PackageDisplay
              link="/rajasthan/Tour_1"
              title="Golden Triangle Tour"
              image={Rajasthan1}
              nights={5}
              day={6}
              destination={["Delhi", "Agra", "Jaipur", "Delhi"]}
            />
            <PackageDisplay
              link="/rajasthan/Tour_2"
              title="Golden Triangle with Pushkar Tour"
              image={Rajasthan2}
              nights={6}
              day={7}
              destination={["Delhi", "Agra", "Jaipur", "Pushkar", "Delhi"]}
            />
            <PackageDisplay
              link="/rajasthan/Tour_3"
              title="Golden Triangle with Ranthambore & Pushkar Tour"
              image={Rajasthan3}
              nights={8}
              day={9}
              destination={[
                "Delhi",
                "Agra",
                "Ranthambore",
                "Jaipur",
                "Pushkar",
                "Delhi",
              ]}
            />

            <PackageDisplay
              link="/rajasthan/Tour_4"
              title="Golden Triangle with Kashmir Tour"
              image={Rajasthan4}
              nights={11}
              day={12}
              destination={[
                "Delhi",
                "Agra",
                "Jaipur",
                "Delhi",
                "Srinagar (by Flight)",
                "Sonamarg",
                "Gulmarg",
                "Pahalgam",
                "Srinagar",
                "Delhi",
              ]}
            />
            <PackageDisplay
              link="/rajasthan/Tour_5"
              title="Rajasthan Heritage Tour"
              image={Rajasthan5}
              nights={13}
              day={14}
              destination={[
                "Delhi",
                "Agra",
                "Jaipur",
                "Pushkar",
                "Udaipur",
                "Jodhpur",
                "Jaisalmer",
                "Bikaner",
                "Mandawa",
                "Delhi",
              ]}
            />
            <PackageDisplay
              link="/rajasthan/Tour_6"
              title="Royal Rajputana Rajasthan Tour"
              image={Rajasthan6}
              nights={15}
              day={16}
              destination={[
                "Delhi",
                "Mandawa",
                "Bikaner",
                "Jaisalmer",
                "Jodhpur",
                "Mount Abu",
                "Ranakpur",
                "Udaipur",
                "Jaipur",
                " Fatehpur Sikri",
                "Agra",
                "Delhi",
              ]}
            />
          </Row>
          <Container className="mt-5">
            <TailourForm />
          </Container>
        </Container>
      </>
    );
  }
}
