import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionLehLadakh from "../InclusionExclusionLehLadakh";

import img1 from "../../../../img/leh/leh-city.jpg";
import img2 from "../../../../img/leh/shantistupa.jpg";
import img3 from "../../../../img/leh/huder-camel-ride.jpg";
import img4 from "../../../../img/leh/pangong-img.jpg";
import img5 from "../../../../img/leh/sangam1.jpg";
import LadakhCarousal from "../CarousalLadakh";

export default class Ladakh_Tour_6 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <LadakhCarousal />
        <Container>
          <PackageTitleDetails
            title="Enchanting Ladakh Himalayan Tour"
            nights={8}
            day={9}
            destination={[
              "Leh",
              "Monastery",
              "Khardungla",
              "Nubra",
              "Hunder",
              "Turtuk",
              "Hunder",
              "Pangong",
              "Tsomorori",
              "Leh",
              "Syam Valley",
              "Leh",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 1 : Arrive Leh - day free to relax and acclimatize"
                  text={
                    <>
                      On your arrival at Leh airport our representative will
                      meet & greet and transfer you to the Hotel.
                      <br />
                      We recommend you completely relax for the rest of the day
                      to enable your body to acclimatize to the rarefied air at
                      the high altitude. Leh is situated at the height of 11563
                      feet.
                      <br />
                      Lunch and dinner is served at the hotel. Overnight stay at
                      the hotel.
                    </>
                  }
                  img={img1} //
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 2 : Leh - Monastery tour"
                  text={
                    <>
                      After breakfast we take you for a full day tour of
                      Monasteries. We first visit Hemis Monastery which is one
                      of the biggest and best known monastery in this region.
                      Then we visit Thiksey Monastery located at a distance of
                      18 kms from Leh town. Then we visit Shey Palace. Then we
                      visit Rancho School of 3 Idiot’s fame. The we return to
                      hotel en route visiting Sindhu Point.
                      <br />
                      <br />
                      After lunch time free to visit to the Shanti Stupa and Leh
                      Palace and evening visit to the local markets.
                      <br />
                      Dinner and overnight stay at the hotel.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 3 : Leh - Khardungla - Nubra - Hunder (Apporx 5 hrs)"
                  text={
                    <>
                      Early morning proceed to Nubra Valley via the Worlds
                      Highest Motorable road (5602 Mts) Khardungla pass.
                      Khardung la Pass (18,380ft above sea level): <br />
                      It is not very often that one gets to drive on a road at
                      an altitude of 18,380 feet. That is where Khardungla Pass,
                      meaning the Pass of Lower Castle, is located on the way
                      from Leh to the Nubra Valley in Ladakh.
                    </>
                  }
                  img={img2}
                />
              </Row>

              <Row>
                <PackageOnlyText
                  text={
                    <>
                      It is the highest motorable road in the world as signs put
                      up by the Border Roads Organization proudly proclaim. Post
                      the Diskit Monastery, proceed for backward journey to Leh
                      Arrive Nubra Valley and proceed for a visit to the White
                      Sand Dunes at Hunder. You can also enjoy a camel ride here
                      (Camel ride on direct payment basis).
                      <br />
                      <br />
                      Nubra Valley: Popularly called the orchard of Ladakh,
                      Nubra valley offers a graceful view of yellow and pink
                      wild roses in summer and a carpet of wild lavender in the
                      post-monsoon season. You will definitely be overwhelmed to
                      see the Bactrian Camels (Shaggy double hump Camel) around
                      sand dunes. Thereafter, check in at comfortable Camp or
                      Hotel. Evening at leisure. Dinner & overnight stay at the
                      camp or hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 4 : Hunder - Turtuk - Hunder (70KM)"
                  text={
                    <>
                      After breakfast at the camp, en route to Turtuk, the
                      northernmost village of India located on the bank of Shyok
                      river. The culture of Turtuk is distinctly Balti
                      (originating from Baltistan). Turtuk is one of the
                      gateways to the Siachen Glacier and is very famous for the
                      availability of different varieties of fruits especially
                      apricots. Free to explore Turtuk village in the afternoon.
                      Drive back to Hunder (Nubra Valley) Overnight stay at
                      Camp.
                    </>
                  }
                  img={img3} //Image
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 5 : Hunder - Pangong (Apporx 5 hrs)"
                  text={
                    <>
                      After breakfast at hotel we drive to next destination
                      pangong lake via Shyok and onto Tang Tse and Spangmik (
                      Pangong).
                      <br />
                      <br />
                      It's a short cut and it's a beautiful route. The road
                      follows the Shyok river, and climbs up a mountain before
                      finally descending into Shayok village. It took us less
                      than an hour to reach Shayok, and we were delighted at the
                      sight of the rather big village surrounded by barren
                      mountains, the entire setting being very picturesque.
                      Afternoon we reach at Pangong lake and enjoy your day at
                      pangong lake. Overnight stay at camp.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 6 : Pangong to Tsomoriri Lake 240 Kms / (Approx 7-8 hrs)"
                  text={
                    <>
                      Morning after breakfast drive to Tsomoriri lake (14000 ft
                      abov sea level) by passing Chumathang (Hot Spring) Along
                      The Indus river. Tsomoriri Arr. 1600 Hrs. Afterwards take
                      a walk around the Lake to enjoy the scenic beauty. O/N
                      Korzok Village.
                    </>
                  }
                  img={img4} //Image
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 7 : Tsomoriri Lake - Leh (Approx 6-7 hrs)"
                  text={
                    <>
                      After breakfast drive back to Leh visiting Puga Sulphur
                      Mine, Tsokar Lake and through Taklang La Pass (17585 ft)
                      second highest motor able road in the world. and visit to
                      Hemis Monastery ,Thiksay Monastery , Shey Monastery , 3
                      Idiot School after back to leh city and visit to
                      shantistupa and leh palace. Evening free to explore the
                      local markets of Leh. Dinner and overnight at Hotel in
                      Leh.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 8 : Leh -Shyam Valley"
                  text={
                    <>
                      After breakfast drive to Shyam Valley Sightseeing First
                      Visit to Hall fame, Hall of Fame is a must visit for every
                      patriotic Indian. It has been built by Indian army in
                      memory of soldiers who lost their lives in Kargil war.
                      Gurdwara Patthar Sahib located at a distance of 40 kms
                      from Leh, was constructed in 1571, in memory of Guru Nanak
                      to commemorate his visit to this region.
                    </>
                  }
                  img={img5} //Image
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Magnetic Hill is the place where you will see your car,
                      with its engine turned off, move upwards on a steep slope.
                      It almost sounds unbelievable. The Sangam is the place
                      called Nimu where the Zanskar river and Indus rivers
                      merge.
                      <br />
                      After Sightseeing back to Leh Market and visit to Shanti
                      Stupa after back to Hotel, Dinner and overnight stay at
                      the hotel.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 8 : Leh Departure"
                  text={
                    <>
                      After breakfast, check out from the hotel & drop at
                      Airport for return journey with sweet memories of Ladakh
                      Tour
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionLehLadakh />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
