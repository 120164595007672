import React from "react";

import img13manali from "../../../../img/himachal/13-manali1.jpg";
import img13manali2 from "../../../../img/himachal/13-manali2.jpg";
import img13manali3 from "../../../../img/himachal/13-manali3.jpg";
import img13manali4 from "../../../../img/himachal/13-manali4.jpg";
import Carousel from "react-bootstrap/Carousel";

export default function CarousalHimachal() {
  return (
    <>
      <Carousel className="carousel-wrapper">
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={img13manali}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Manali - Himachal Himalayan Tour</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={img13manali2}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Himachal Himalayan Tour</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={img13manali4}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Himachal Himalayan Tour</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 img"
            src={img13manali3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Himachal Himalayan Tour</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
