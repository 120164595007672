import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./index.css";
import contactUsImage from "../../../../img/contactus.jpg";

export default class CarousalContactUs extends Component {
  render() {
    return (
      <>
        <Carousel className="carousel-wrapper">
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img"
              src={contactUsImage}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Love Kashmir Holidays Contact Us</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img"
              src={contactUsImage}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Love Kashmir Holidays Contact Us</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
}
