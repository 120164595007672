import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import img1 from "../../../../img/himachal/hadimba-devi-temple-manali.jpg";
import img2 from "../../../../img/himachal/ladakh-bike-tour.jpg";
import img3 from "../../../../img/himachal/jispacamp.jpg";
import img4 from "../../../../img/himachal/shantistupa.jpg";
import img5 from "../../../../img/leh/diskit-buddha.jpg";
import img6 from "../../../../img/leh/pangong-img.jpg";
import img7 from "../../../../img/himachal/bikeride.jpg";
import CarousalHimachal from "../CarousalHimachal";

export default class Himachal_Tour_4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalHimachal />
        <Container>
          <PackageTitleDetails
            title="Manali to Ladakh Bike Trip"
            nights={10}
            day={11}
            destination={[
              "Manali",
              "Jispa",
              "Sarchu",
              "Leh",
              "Nubra",
              "Pangong",
              "Leh",
              "Sarchu",
              "Manali",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 01: Arrival in Manali(2050m)"
                  text={
                    <>
                      Altitude Gain - 1729m
                      <br />
                      Highlights - Bike Allocation, Manali Exploration.
                      <br />
                      <br />
                      <br />
                      Arrive in Manali in the morning and meet the Adventure
                      Nation team at the pre-booked hotel. After resting for
                      some time, be available to attend the orientation session
                      conducted by our on-ground team. Later in the afternoon,
                      the bikes will be allotted so you may take it for a spin
                      and check if it’s aligned to your riding style. The
                      popular places to explore in Manali are Hadimba Temple,
                      Vashisht Village, Mall road, Old Manali lane, etc. You
                      have the liberty to visit places of your own choice.
                      Later, have dinner at the hotel and stay overnight.
                    </>
                  }
                  img={img1}
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 02: Manali to Jispa(3200m) | 140km"
                  text={
                    <>
                      Grade/Duration - Moderate/Approx. 8 Hours
                      <br />
                      Altitude Gain - 1150m
                      <br />
                      Highlights - Solang Valley, Rohtang La(Pass)
                      <br />
                      <br />
                      Today, we are going to start our Ladakh expedition by
                      riding towards Jispa on the much-celebrated Manali-Leh
                      Highway. Leaving early is pivotal as we need to cross the
                      mighty Rohtang Pass (3978m) early in the day before it
                      gets too crowded. After crossing Rohtang, the landscape
                      changes drastically as we enter the Lahaul and Spiti
                      District. The lush green grasslands disappear and the
                      barren slopes of the cold dessert take over. We continue
                      the ride towards Jispa crossing the quaint mountain towns
                      of Sissu and Keylong. Arrive at the destination early in
                      the evening and have an adventurous overnight stay in
                      tents.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03: Jispa to Sarchu(4290m) | 90km"
                  text={
                    <>
                      Grade/Duration - Difficult/Approx. 6 Hours
                      <br />
                      Altitude Gain -1090m
                      <br />
                      Highlights - Deepak Tal, Baralacha La, Riding along Tsarap
                      River
                      <br />
                      <br />
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Keep yourself completely hydrated by drinking a tonne of
                      water as we are going to spend the night at above 4000mt
                      altitude. Today’s ride is one of the shortest rides of the
                      expedition but the adrenaline level remains high as the
                      terrain is full of adventure. After crossing Darcha, keep
                      riding on the Manali-Leh Highway towards Deepak Tal. The
                      road from here starts to ascend steeply towards the
                      treacherous Baralacha Pass(4890m), negotiating Zing Zing
                      Bar and crossing the stunning emerald lake, known as Suraj
                      Tal. Just after crossing Baralacha, halt for some time and
                      have some well-deserved lunch near Bharatpur. We continue
                      our ride to Sarchu on the same road, although the terrain
                      gets changed quite a bit along the way and Tsarap River is
                      visible on the left side carving and raging through the
                      mountains. Arrive in Sarchu later in the day and be
                      prepared for a rather uncomfortable overnight stay in a
                      tent.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 04: Sarchu to Leh(3500m) | 250km"
                  text={
                    <>
                      Grade/Duration - Difficult/Approx. 8 Hours
                      <br />
                      Altitude Gain -790m
                      <br />
                      Highlights - Gata loops, Nakee La, Lachung La, Morey
                      Plains, Tanglang La, Riding along Indus River
                      <br />
                      <br />
                    </>
                  }
                  img={img3}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Start riding after an early breakfast as today’s going to
                      be a long but exciting day of riding. Water-Crossings are
                      a common sight on the road while riding to Leh and the
                      ideal time to cross them is early in the day. Just after
                      leaving Sarchu, we start to ascend towards Nakee
                      La(4769m), crossing the zig-zag Gata Loops which is a
                      series of 21 hairpin bends within a span of just 7km. The
                      descent from Nakee La is barely even finished and the
                      ascent towards another high pass, Lachung La(5059m)
                      begins. The descent from Lachung La brings us to the town
                      of Pang(4600m) where we halt for Lunch.
                      <br />
                      The landscape starts to change after crossing Pang and the
                      plateau of Morey Plains starts and stretches for 45km.
                      Closer to the end of the plains, we start climbing the
                      Tanglang La(5328m), the highest motorable pass on the
                      Manali-Leh Highway. After crossing the 3rd high pass of
                      the day, continue riding towards Leh along the mighty
                      Indus River on the left side. Reach Leh in the evening and
                      relax in the hotel.
                    </>
                  }
                />
              </Row>
              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05: Rest Day in Leh"
                  text={
                    <>
                      Replenish your energies with a healthy breakfast and enjoy
                      a relaxing day in Leh. The first half of the day is saved
                      for a regular check-up of the bikes and the rest of the
                      day can be spent visiting a few sightseeing places in and
                      around the city. Stop by the Leh Palace and enjoy the
                      splendid panoramic views of the Stok Kangri mountain range
                      from the terrace. You may also choose to visit the Shanti
                      Stupa and the local market to indulge in some shopping and
                      try out the Ladakhi cuisine at the local eateries. Spend
                      the night at the hotel.
                    </>
                  }
                  img={img4}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 06: Leh to Nubra (3048m) | 160km"
                  text={
                    <>
                      Grade/Duration - Difficult/Approx. 7 Hours
                      <br />
                      Altitude Loss - 452m
                      <br />
                      Highlights - Khardung La, Diskit Monastery
                      <br />
                      <br />
                      After a rather relaxed day in Leh, we continue our
                      expedition and start riding towards Nubra Valley through
                      what’s considered the highest motorable road in the world
                      i.e. Khardung La(5359m). The road towards the pass is well
                      paved and tarred till the checkpoint near South Pullu and
                      after crossing the checkpoint, the road starts to get
                      slushy with loose rocks on one side. Get yourself clicked
                      after reaching Khardung La and hit the road quickly as it
                      is not safe to spend much time on top of the pass due to
                      the high altitude and thin air. We stop for a quick lunch
                      after climbing down from the pass and then continue our
                      journey. After riding for another couple of hours, we make
                      a brief stop to visit the 32m high Maitreya Buddha statue
                      in Diskit Monastery. Our arrival in the valley will be
                      welcomed by the white sand dunes and the sightings of the
                      double-hump Bactrian camels grazing. Stay overnight at the
                      hotel/campsite.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 07: Nubra to Pangong(4250m) | 260km"
                  text={
                    <>
                      Grade/Duration - Difficult/Approx. 8 Hours <br />
                      Altitude Gain -1202m <br />
                      Highlights - Riding along Shyok River, Water Crossings{" "}
                      <br /> <br />
                    </>
                  }
                  img={img5}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 06: Leh to Nubra (3048m) | 160km"
                  text={
                    <>
                      After having breakfast, we start riding towards the most
                      photographed destination in Ladakh, Pangong Lake. Despite
                      the fact that we are not going to cross any high pass
                      today, the ride should be quite interesting as we ride on
                      the much less traveled road via Shyok. Most of our ride is
                      going to be along the beautiful Shyok River and through
                      the villages like Agham, Shyok, Durbuk, and Tangste. The
                      road remains slushy along with some exciting water
                      crossings and rocky terrain so being cautious is
                      important.
                      <br />
                      After crossing Durbuk, the last stretch of the ride is
                      going to be along the lake. From the very first glimpse,
                      the lake leaves an everlasting impression on the visitors.
                      Get yourself photographed and just admire the wonder that
                      is this shimmering blue lake. Overnight stay in tents.
                    </>
                  }
                />
              </Row>

              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 08: Pangong to Leh(3500m) | 150km"
                  text={
                    <>
                      Grade/Duration - Moderate/Approx. 5 Hours
                      <br />
                      Altitude Loss - 750m
                      <br />
                      Highlights - Chang La, Druk White Lotus School
                      <br /> <br />
                    </>
                  }
                  img={img6}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 06: Leh to Nubra (3048m) | 160km"
                  text={
                    <>
                      Wake up to an enchanting sunrise over Pangong Tso and
                      recommence your Ladakh expedition. Today we are going to
                      ride the same route that we rode yesterday and after
                      crossing Durbuk, we will start climbing the third highest
                      motorable pass in the world, Chang La(5360m). After
                      crossing Chang La, we halt for Lunch. After eating, we
                      start our journey to Lah via Karu from where we start
                      riding on the Manali-Leh Highway. Just before reaching
                      Leh, we are going to pass through the Thiksey monastery
                      and the popular Druk White Lotus School. There will be a
                      short halt to explore the monastery and take some
                      pictures. After riding for approximately an hour, we will
                      arrive in Leh for the second time during the expedition.
                      Stay Overnight in a hotel.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 09: Leh to Sarchu(4290m) | 250km"
                  text={
                    <>
                      Grade/Duration - Moderate/Approx. 6 Hours
                      <br />
                      Altitude Gain - 790m
                      <br />
                      Highlights - Gata loops, Nakee La, Lachung La, Morey
                      Plains, Tanglang La, Riding along Indus River
                      <br />
                      <br />
                      After witnessing all the wonderful things that Ladakh had
                      to offer, it’s time to head back. We are again going to
                      take the Manali-Leh Highway and ride through all the high
                      passes to make our way back to Sarchu. Reach Sarchu early
                      in the evening and relax at the campsite. Sleeping at high
                      altitude is going to be less of a problem this time as you
                      are better acclimatized to the weather and conditions.
                      Overnight stay in a tent.
                    </>
                  }
                />
              </Row>
              {/* -------------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 10: Sarchu to Manali(2050m) | 230km"
                  text={
                    <>
                      Grade/Duration - Moderate/Approx. 10 Hours <br />
                      Altitude Loss - 2240m <br />
                      Highlights - Baralacha La, Suraj Tal, Keylong, Rohtang La
                      <br /> <br />
                    </>
                  }
                  img={img7}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      Wake up early in the morning and get ready for what’s
                      going to be the last day of riding on this expedition. We
                      are going to continue our journey on the Manali-Leh
                      Highway and reach Manali by late evening after crossing
                      places such as Baralacha la, Keylong, and Rohtang La.
                      After arriving in Manali, check in to the hotel and return
                      the bike. Later go out with your recently made friends and
                      explore Manali on your own. Stay overnight.
                    </>
                  }
                />
              </Row>
              {/* -------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 11: Departure from Manali"
                  text={
                    <>
                      Wake up nice and easy and have breakfast at the hotel.
                      Today, you have half a day to explore Manali and later
                      catch the overnight bus to Delhi
                    </>
                  }
                />
              </Row>
              {/* --------------------------------------------------inClusion--------------- */}
              <hr></hr>

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Above package includes:</p>
                  <p className="titlePackage">Section 1:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Accommodation in Hotels / Camps / Guest Houses on triple
                    sharing from Day 01 to Day 10 (Double sharing for couples)
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Meals - Breakfast and Dinner - From Dinner on Day 01 to
                    Breakfast on Day 11
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Services of the experienced trip leader and motorbike
                    Mechanic
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Back up the vehicle for a group of more than 10 motorbikes
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Inner Line permits worth Rs. 800/-{" "}
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Safety Gear - Elbow Guards and Knee Pads
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Facility to carry luggage in a vehicle restricted to 1
                    Rucksack / Duffle bag of 60 Litres per motorbike -{" "}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Section 2:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Everything that is included in section 01
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Royal Enfield 350cc motorbike
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Helmet for both rider and pillion
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Motorbike Fuel for the complete trip as per the itinerary
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Extra fuel for back up{" "}
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Section 03: Package with MUV
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Everything that is included in Section 01 Transport in a
                    MUV like Tempo Traveller / Toyota Innova / Mahindra Xylo /
                    Mahindra Scorpio from Day 02 to Day 10 as per the group size
                  </p>
                </Col>
              </Row>
              {/* --------------------------------------------------Exclusion--------------- */}

              <Row>
                <Col lg={9} md={6}>
                  <p className="titlePackage">Exclusions:</p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>- 5% GST</p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Insurance; We recommend Adventure Travel Insurance.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    - Personal expenses like Telephone, Laundry, Tips and Table
                    Drinks etc.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Any Airfare / Rail fare other than what is mentioned in
                    “Inclusions”
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Airport, Railway station or Bus stop pick up or drop
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - The cost of any spare part will be used due to the
                    accidental damage incurred when the motorbike is in the
                    rider’s possession
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - The cost incurred to transfer or tow the motorbike in case
                    it is dropped on the way.
                  </p>

                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Vehicle servicing or maintenance cost
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Any lunch and other meals not mentioned in package
                    inclusions
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Security deposit per motorbike of Rs. 10,000/-. As per the
                    deposit policy (This is refundable at the end of the trip if
                    there is no damage or spare parts used.)
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Parking and monument entry fees during sightseeing.
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Any cost incurred due to extension, change of itinerary
                    due to natural calamities, road, blocks, vehicle breakdown,
                    union issues and factors beyond our control
                  </p>
                  <p style={{ margin: "0px", fontSize: "14px" }}>
                    {" "}
                    - Additional accommodation/food cost incurred due to any
                    delay
                  </p>
                </Col>
              </Row>
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
