import React, { Component } from "react";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionRajasthan from "../InclsionExclusionRajasthan";

import img1 from "../../../../img/Rajasthan/indiagate.jpg";
import img2 from "../../../../img/Rajasthan/tajmahal.jpg";
import img3 from "../../../../img/Rajasthan/jaipur-elephant-ride.jpg";
import CarousalRajasthan from "../CarousalRajasthan";

export default class Rajasthan_Tour_1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalRajasthan />
        <Container>
          <PackageTitleDetails
            title="Golden Triangle Tour"
            nights={5}
            day={6}
            destination={["Delhi", "Agra", "Jaipur", "Delhi"]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 01 : Arrive Delhi"
                  text={
                    <>
                      On arrival at Delhi airport or railway station, our
                      representative will meet you to pick you up and transfer
                      to your hotel. Rest of the day is at your leisure. Stay
                      overnight at the hotel in Delhi
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 02 : Delhi City Tour"
                  text={
                    <>
                      After breakfast first you will visit old Delhi. The Raj
                      Ghat, Red fort & Jama Masjid are important places to
                      visit, you may also visit Chandni Chowk, spice market etc.
                      Later on proceed to visit the New Delhi. Visit Qutub
                      Minar,a UNESCO World Heritage Site. Qutub Minar is an
                      excellent example of Afghan Architecture constructed with
                      marble and red sandstone. And then stop at UNESCO World
                      Heritage-listed Humayuns Tomb, After imposing India Gate
                      memorial built in the year 1931 to commemorate the Indian
                      soldiers. and the Rastrapathi Bhawan, Laxmi Narayan
                      Temple, Lotus Temple and, newly built Akshardham temple
                      are of much significance. By evening return to your hotel
                      for overnight stay.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 03 : Delhi to Agra"
                  text={
                    <>
                      This morning after breakfast you will drive to Mughal city
                      Agra and check into your hotel. Enroot you may also visit
                      Mathura-the birth place of Lord Krishana. This afternoon
                      visit the Agra fort, built by the great Emperor Akbar, and
                      the famous Taj Mahal-the symbol of love, built by Emperor
                      Shah Jahan in memory of his wife Mumtaz Mahal. You may
                      also visit Dayal Bagh. By evening return to your hotel for
                      overnight stay.
                    </>
                  }
                  img={img2}
                />
              </Row>
              <Row>
                <PackageOnlyText
                  title="Day 04 : Agra to Jaipur"
                  text={
                    <>
                      This morning after breakfast you will drive to the pink
                      city of Jaipur, enroute you will visit the Fatehpur Sikri,
                      the deserted red sandstone city built by the Mughal
                      Emperor Akbar. On arrival check into your hotel and rest
                      of the day is at leisure. Stay overnight at hotel in
                      Jaipur.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 05 : Jaipur City Tour"
                  text={
                    <>
                      This morning after breakfast visit the Amber fort, built
                      in 16th century, enjoy an elephant ride at Amber. Drive
                      past the Hawa Mahal-a Palace of winds. In the afternoon
                      you will visit the city palace and museum, walk to
                      adjacent Jantar Mantar an astronomical observatory. Rest
                      of the evening is at leisure. Stay overnight at hotel in
                      Jaipur.
                    </>
                  }
                  img={img3}
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 06 : Jaipur to Delhi"
                  text={
                    <>
                      Today you will be transferred back to Delhi
                      airport/railway station for your onward journey
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionRajasthan />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
