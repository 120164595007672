import React, { Component } from "react";
import TourPackages from "../Homepage/components/TourPackages";
import BottomSectionHouseBoat from "./components/BottomSection";
import CarousalHouseBoat from "./components/Carousal";
import MidSectionHouseBoat from "./components/MidSection";

export default class HouseBoat extends Component {
  render() {
    return (
      <>
        <CarousalHouseBoat />
        <MidSectionHouseBoat />
        <BottomSectionHouseBoat />
        <TourPackages />
      </>
    );
  }
}
