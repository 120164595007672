import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import Image from "react-bootstrap/Image";

import lgoo from "../../img/travel-logo.jpg";
export default class HajUmrahTicketBooking extends Component {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="haj-umrah-wrapper">
                <h4 className="haj-umrah-wrapper-heading">
                  Your journey of self-purification begins here.
                </h4>
                <p className="haj-umrah-wrapper-data">
                  Our legacy of helping Muslims in performing their obligatory
                  religious duty Hajj, and non-obligatory religious duty Umrah,
                  goes back to over two years, Since our inception, every year
                  we have enabled thousands of pilgrims to successfully fulfil
                  their promise to Allah. Our expert team of experienced
                  professionals in India and Saudi Arabia are committed towards
                  ensuring that every aspect of this holy pilgrimage is executed
                  flawlessly, and the pilgrim is in a relaxed state of mind
                  during the sacred journey.
                </p>
                <p className="haj-umrah-wrapper-data">
                  At <b> Love Kashmir Holidays Tours & Travels</b>, we pride
                  ourselves in providing excellent service and focusing only on
                  one goal: your absolute comfort. We are recognized by the
                  Government of India and the Government of Saudi Arabia for our
                  commitment of delivering an exceptional experience for
                  pilgrims who perform Hajj as well as Umrah with us.{" "}
                </p>{" "}
                <p className="haj-umrah-wrapper-data">
                  Based upon the tour package that you select, we provide you
                  with luxurious accommodation, which is closest to the places
                  of worship during your period of stay in Makkah, Madinah and
                  Mina and Arafat. This makes it easier for you to perform your
                  religious duty in comfort. We are renowned for serving
                  delicious food during Hajj, where we offer you a variety of
                  dishes so that you are fully nourished and discharge your
                  religious obligation smoothly. For transporting you between
                  different points during the pilgrimage, we use comfortable
                  buses with wide seats.{" "}
                </p>
                <p className="haj-umrah-wrapper-data">
                  For those pilgrims who want to perform their Hajj and Umrah in
                  absolute comfort at a price that justifies the premium
                  facilities we offer,
                  <b> Love Kashmir Holidays Tours & Travels </b>is their first
                  choice. We look forward to providing exceptionally comfortable
                  service to Allah's guests in the Holy Land.
                </p>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="wrapper-green">
                <p className="heading-contact-us">LOVE KASHMIR HOLIDAYS</p>
                <p className="heading-contact-us-sub">
                  (Recognized by Jammu and Kashmir, Department of Tourism)
                </p>
                <div className="addres-container">
                  <p className="office-address">Head Office Address:</p>
                  <p className="text-contact ">
                    Friends Colony, Taibal, Shalimar, Srinagar, Kashmir -
                    190006, J & K (India)
                  </p>
                  <p className="text-contact ">
                    <span className="office-address">Tel:</span>{" "}
                    <a style={{ color: "red" }} href="tel:6005146451">
                      6005146451
                    </a>{" "}
                    <a style={{ color: "red" }} href="tel:+6597610109">
                      +6597610109
                    </a>{" "}
                    <a style={{ color: "red" }} href="tel:+91 1943575947">
                      +91 1943575947
                    </a>
                  </p>
                  <p className="text-contact ">
                    <span className="office-address">Email:</span>{" "}
                    <a
                      style={{ color: "red" }}
                      href="mailto:javidbhat.kashmir@gmail.com"
                    >
                      javidbhat.kashmir@gmail.com
                    </a>
                    <br />
                    <a
                      style={{ color: "red" }}
                      href="mailto:javidbhat@lovekashmirholidays.com"
                    >
                      javidbhat@lovekashmirholidays.com
                    </a>
                  </p>
                  <p className="text-contact ">
                    <span className="office-address">Website:</span>{" "}
                    <a
                      style={{ color: "red" }}
                      href="https://www.lovekashmirholidays.com"
                    >
                      https://www.lovekashmirholidays.com
                    </a>
                  </p>
                  <p className="text-contact">
                    <span className="office-address">Working Hours:</span>{" "}
                    9am-9pm
                  </p>
                  <Image src={lgoo} alt="reg" fluid />
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3301.7053529906166!2d74.87153429167492!3d34.15387962793295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5317e671fe717d3d!2sTour%20And%20Travels!5e0!3m2!1sen!2sus!4v1658149491803!5m2!1sen!2sus"
                style={{ width: "100%", height: "100%" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
