import React, { Component } from "react";
import NewCarousel from "../../../../components/Carousel";
import FormBooking from "../../../../components/Form";
import TourPackages from "../../../Homepage/components/TourPackages";
import PackageTitleDetails from "../../../../components/PackageTitleDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import PackageWithImage from "../../../../components/PackageWithImage";
import PackageOnlyText from "../../../../components/PackageOnlyText";
import InclusionExclusionRajasthan from "../InclsionExclusionRajasthan";

import img1 from "../../../../img/Rajasthan/qutubminar.jpg";
import img2 from "../../../../img/Rajasthan/bikaner.jpg";
import img3 from "../../../../img/Rajasthan/jaislamer-fort.jpg";
import img4 from "../../../../img/Rajasthan/udaipur.jpg";
import img5 from "../../../../img/Rajasthan/fatehpursikri.jpg";
import CarousalRajasthan from "../CarousalRajasthan";

export default class Rajasthan_Tour_6 extends Component {
  componentDidMount() {
    window.scrollTo(0, 600);
  }
  render() {
    return (
      <>
        <CarousalRajasthan />
        <Container>
          <PackageTitleDetails
            title="Royal Rajputana Rajasthan Tour"
            nights={15}
            day={16}
            destination={[
              "Delhi",
              "Mandawa",
              "Bikaner",
              "Jaisalmer",
              "Jodhpur",
              "Mount Abu",
              "Ranakpur",
              "Udaipur",
              "Jaipur",
              "Fatehpur Sikri",
              "Agra",
              "Delhi",
            ]}
          />
          <Row>
            <Col lg={9} md={6}>
              {/* ---------------------------------------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="
                  Day 01 : Arrive Delhi"
                  text={
                    <>
                      On arrival at Delhi airport or railway station, our
                      representative will meet you to pick you up and transfer
                      to your hotel. Rest of the day is at your leisure. Stay
                      overnight at the hotel in Delhi
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 02 : Delhi City Tour"
                  text={
                    <>
                      After breakfast proceed for sightseeing tour of Old & New
                      Delhi: <br />
                      <strong> OLD DELHI </strong> - A sight-seeing tour of Old
                      Delhi would entail visiting the Raj Ghat - the memorial
                      site where Mahatma Gandhi was cremated; Jama Masjid - the
                      largest mosque in India.
                    </>
                  }
                  img={img1}
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  text={
                    <>
                      <strong> NEW DELHI </strong> - An extensive sight-seeing
                      tour of New Delhi would include a visit to the Humayun's
                      Tomb, the Qutub Minar, a drive along the ceremonial avenue
                      - Rajpath, past the imposing India Gate, Parliament House,
                      the President's Residence and would end with a drive
                      through the Diplomatic Enclave. Night stay in Delhi.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 3: Delhi - Mandawa: 270 km 5 Hrs."
                  text={
                    <>
                      Morning after breakfast, drive to Mandawa, check-in hotel
                      and evening walk in the old town and local market. After
                      back to hotel and overnight at hotel.
                    </>
                  }
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageWithImage
                  title="Day 4: Mandawa - Bikaner: 180 km & 3:30 hrs
                  "
                  text={
                    <>
                      Arrival transfer to hotel and rest, afternoon Bikaner
                      visit, Junagarh fort, Lallgarh palace and fort museum, the
                      Magnificent palace is fabricated by red sandstone and
                      marble is embellished with mirror work, exquisite carving
                      and paintings, definitely a worth visiting side. After
                      drive to Deshnok visit Karni Mata RAT temple after back to
                      Bikaner. In the evening you are free to the visit old city
                      and bazar and shopping area. After back o hotel and
                      overnight at hotel Bikaner.
                    </>
                  }
                  img={img2}
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 5: Bikaner - Jaisamler: 330 km & 5:30 hrs."
                  text={
                    <>
                      Morning after breakfast, drive to Jaisalmer, rich hotel
                      check-in room are rest,, evening drive to sunset point for
                      sunset after back to hotel and overnight at hotel.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 6: Jaisalmer sightseeing:"
                  text={
                    <>
                      Morning after breakfast, visit Golden fort (Sonar Quila)
                      and Jain temple inside the fort. Visit fort museum
                      showcasing a rich collection of robes and artefacts from
                      the royal family of Jaisalmer. Visit Patwoan ki haveli,
                      old city bazar area and visit Gadisar lake and many more.
                      Afternoon drive to dessert sand done for sunset and Camel
                      ride. After back to Jaisalmer and overnight at hotel
                      Jaisalmer.
                      <br />
                      <br />
                      AFTERNOON DRIVE TO KHURI DESERT SAND DONE VILLAGE: - Camel
                      safari, sunset, Indian traditional dance program and
                      dinner at khuri village after back to Jaisalmer and
                      overnight at hotel.
                    </>
                  }
                />
              </Row>
              <Row>
                <PackageWithImage
                  title="Day 7: Jaisalmer - Jodhpur: 286 km & 5:30 hrs."
                  text={
                    <>
                      Morning after breakfast, drive to Jodhpur, on the way
                      visit Ossian Schain Mata Temple (Durga-Temple) and also
                      visit Mandore garden. After drive to hotel and overnight
                      at hotel Jodhpur.
                    </>
                  }
                  img={img3}
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 8: Jodhpur sightseeing:"
                  text={
                    <>
                      Morning after breakfast, visit Mehrangarh fort, Jaswant
                      Thada and Mondore garden and Umaid bhawan palace and
                      museum. Also visit blue city Sardar market and walk at
                      clock tower market and shopping area. After back to hotel
                      and overnight at hotel Jodhpur.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 09: Jodhpur - Mount Abu sightseeing:"
                  text={
                    <>
                      After Breakfast check out hotel and drive to Mountabu and
                      visit Nakki lake, sunset point, Honeymoon point,
                      aghor-devi temple and old city. Evening visit sunset point
                      for view of sunset and overnight at hotel Mount-Abu.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------------------------ */}
              <Row>
                <PackageOnlyText
                  title="Day 10: Mount-Abu - Ranakpur - Udaipur: 230 km & 7 hrs."
                  text={
                    <>
                      Morning after breakfast, drive to Udaipur on the way visit
                      Ranakpur jain temple of 12 Century. The cluster of temple,
                      made of intricately sculpted white marble, built out of
                      marbles about 1444 carved pillars each carved differently
                      from other. After drive to Udaipur, check-in hotel and
                      overnight at hotel Udaipur.
                    </>
                  }
                />
              </Row>
              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 11: Udaipur sightseeing:"
                  text={
                    <>
                      Morning after breakfast take sightseeing of Udaipur visit
                      city palace complex, significant landmark of the town, the
                      palace is splendid white marble architectural historical
                      palace of Rajpuot Rulers, in the palace vicinity you will
                      see notable palace like Durbar Hall, Palace museum,
                      Crystle Gallery and Jagdish temple, followed by Sehelion
                      Ki Beri, a beautiful recreation garden for queen and
                      princes of the rulers. Bharatiya Lok Kala Mandal, an ethic
                      palace of see the traditional and culture of Rajasthan.
                      Afternoon boat rides at lake pichola and Evening visit
                      Enlingji & Nagda temple, after back to hotel and overnight
                      at hotel Udaipur.
                    </>
                  }
                  img={img4}
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 12: Udaipur - Jaipur: 400 km & 8:30 hrs."
                  text={
                    <>
                      Morning after breakfast, drove to Jaipur, on arrival
                      transfer to hotel check-in room and overnight at hotel
                      Jaipur.
                    </>
                  }
                />
              </Row>

              {/* ------------------------------------------ */}
              <Row>
                <PackageWithImage
                  title="Day 14: Jaipur - Fatehpur Sikri Agra: 250 km & 5:30 hrs"
                  text={
                    <>
                      Morning after breakfast, drive to Agra on the way visit
                      Abhaneri stepwell and also visit Fatehpur-Sikri fort and
                      palace. After continue to Agra and check-in hotel and
                      overnight at hotel Agra.
                    </>
                  }
                  img={img5}
                />
              </Row>
              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 15: Agra sightseeing;"
                  text={
                    <>
                      Morning after breakfast, visit world most and beautiful
                      monuments: Taj Mahal. The Taj Mahal, the architectural
                      modern day, the most beautiful monument among the 7th
                      wonder of the world, this historical masterpiece is
                      illustration of the fusion of diverse architectural
                      styles. Taj Mahal is crowned of all the loveliest
                      buildings in the world.
                      <br />
                      <br />A symbol of eternal love. Afterwards visit Agra
                      fort, was building in 1576 AD, by great Mughal Emperor
                      Akbar. After visit Itamed-ud-Daula (Mini Taj) buily by
                      Empress Noorjehan daughter of emperor Shajahan, after
                      visit Sikandra memorial of Mughal emperor Akbar and also
                      visit Mehtabagh garden for sunset from other side of
                      Yamuna river. After back to hotel and overnight at hotel
                      Agra.
                    </>
                  }
                />
              </Row>

              {/* ----------------------------------------------------------------- */}
              <Row>
                <PackageOnlyText
                  title="Day 16 : Agra - Delhi: 230 km & 3:30 hrs."
                  text={
                    <>
                      Morning after breakfast, drive to Delhi, arrival transfer
                      to International airport for your home flight.
                    </>
                  }
                />
              </Row>

              {/* --------------------------------------------------inClusion--------------- */}
              <InclusionExclusionRajasthan />
              {/* --------------------------keep down------------------------------------ */}
            </Col>

            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* Booking Section */}
            <Col lg={3} md={6}>
              <FormBooking />
            </Col>
          </Row>
        </Container>
        <TourPackages />
      </>
    );
  }
}
